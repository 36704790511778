import React from 'react';
import { Label } from './style';

const StatusLabel = ({ color, children, style, ...rest }) => {
  return (
    <Label className={color} {...rest}>
      {children}
    </Label>
  );
};

export default StatusLabel;
