import React from 'react';
import {
  Typography,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../Spinner';
import { DialogTitle } from './style';

const ConfirmDialog = ({
  open,
  setOpen,
  title,
  message,
  additionalMessage,
  onSubmit,
  onClose,
  loading,
  confirmText = 'DA',
  denyText = 'NE',
  ...rest
}) => {
  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  const handleSubmit = async () => {
    await onSubmit();
    handleClose();
  };

  return (
    <>
      <Dialog
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            handleClose();
          }
        }}
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...rest}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Box
            display="flex"
            alignItems="center"
            flexDirection="columns"
            alignContent="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">{title}</Typography>
            <Tooltip title="Odustani">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box pt={5} pb={5}>
            <Typography
              //   style={{ fontWeight: 'bold' }}
              variant="body1"
              gutterBottom
            >
              {message}
            </Typography>
            {additionalMessage && (
              <Typography variant="body2" gutterBottom>
                {additionalMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            {denyText}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            variant="contained"
            color="primary"
            startIcon={loading && <Spinner size={16} />}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
