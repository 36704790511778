import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const UnsavedChangesDialog = ({
  methods,
  attributes,
  loading,
  ignoreIds = [],
  ignoreText = ['Sačuvaj', 'Poništi'],
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [lastClickedElement, setLastClickedElement] = useState(null);

  const recursiveCheckForClasses = (element, classNames) => {
    if (element.nodeName === 'BODY' || element.nodeName === 'HTML') return false;

    const classList = typeof element.className === 'string' ? element.className : '';
    if (classNames.some((className) => classList.includes(className))) return true;

    return element.parentNode ? recursiveCheckForClasses(element.parentNode, classNames) : false;
  };

  const handleUnsavedChangesModalAccept = () => {
    setShowUnsavedChangesModal(false);

    if (lastClickedElement) {
      let element = lastClickedElement.target;

      while (element && element !== document.body) {
        if (
          element.tagName === 'A' ||
          element.tagName === 'BUTTON' ||
          typeof element.onclick === 'function'
        ) {
          element.click();
          break;
        }
        element = element.parentElement;
      }
    }
  };

  useEffect(() => {
    if (!showUnsavedChangesModal && !loading) {
      const checkClick = (event) => {
        const isAllowedToPass = recursiveCheckForClasses(event.target, [
          'date-picker-btn',
          'MuiPickersCalendar',
          'MuiDialog',
          'MuiMenuItem',
        ]);

        const isSaveButton = ignoreText.some((text) => event.target.innerText === text);

        const isIgnoredId = ignoreIds.some((id) => event.target.id === id);

        if (isAllowedToPass || isSaveButton || isIgnoredId) return;

        const isEventClickable =
          event.target.nodeName === 'A' ||
          event.target.nodeName === 'BUTTON' ||
          event.target.offsetParent?.nodeName === 'A' ||
          event.target.offsetParent?.nodeName === 'BUTTON' ||
          (typeof event.target.parentNode.className === 'string'
            ? event.target.parentNode.className
            : ''
          ).includes('MuiButton') ||
          (typeof event.target.className === 'string' ? event.target.className : '').includes(
            'MuiButtonBase',
          );

        if (isEventClickable) {
          setLastClickedElement(event);
          const hasUnsavedChanges = Object.entries(methods.formState.dirtyFields).reduce(
            (acc, [key]) => {
              const objId = key.split('-')[1];
              const attributeObject = attributes.find((obj) => obj.id.toString() === objId);
              if (attributeObject) acc += 1;
              return acc;
            },
            0,
          );
          if (hasUnsavedChanges) {
            setShowUnsavedChangesModal(true);
          }
        }
      };

      document.addEventListener('mousedown', checkClick);

      return () => {
        document.removeEventListener('mousedown', checkClick);
      };
    } else {
      return () => {};
    }
  }, [showUnsavedChangesModal, JSON.stringify(methods.formState.dirtyFields)]);

  return (
    <>
      <Dialog
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        aria-labelledby="show-unsaved-changes-modal"
      >
        <DialogTitle id="show-unsaved-changes-modal">Nespremljene izmjene</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Da li ste sigurni da želite poništiti sve nespremljene izmjene?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedChangesModal(false)} color="primary">
            Odustani
          </Button>
          <Button onClick={() => handleUnsavedChangesModalAccept()} color="primary" autoFocus>
            Potvrdi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UnsavedChangesDialog;
