import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import UpdateIcon from '@material-ui/icons/Update';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useTheme from '../../../hooks/useTheme';
import { ErrorMessage } from './style';

const GroupWorkersTable = ({
  workers,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedWorker,
  setEditModalVisible,
  setDeleteModalVisible,
}) => {
  const theme = useTheme();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema nazivu radnika"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('workerFullName', sorters)}
                direction={getSortDirection('workerFullName', sorters)}
                onClick={() => handleSortChange('workerFullName', sorters, setSorters)}
              >
                Radnik
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema važi" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('valid', sorters)}
                direction={getSortDirection('valid', sorters)}
                onClick={() => handleSortChange('valid', sorters, setSorters)}
              >
                Važi
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !workers ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : workers?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan radnik grupe</Typography>
            </TableCell>
          </TableRow>
        ) : (
          workers?.map((worker) => (
            <TableRow key={worker.id}>
              <TableCell width={50}> {worker.id}</TableCell>
              <TableCell width={50}> {worker.workerFullName}</TableCell>
              <TableCell width={50}>
                {dateFormatterDate(worker.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(worker.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={50}> {worker.createdBy}</TableCell>
              <TableCell width={50}>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title={worker.valid === 'D' ? 'Da' : worker.valid === 'N' ? 'Ne' : 'U toku'}
                >
                  <SvgIcon style={{ verticalAlign: 'middle' }}>
                    {worker.valid === 'D' ? (
                      <DoneIcon style={{ color: theme.palette.success.main }} />
                    ) : worker.valid === 'N' ? (
                      <CloseIcon style={{ color: theme.palette.error.main }} />
                    ) : (
                      <UpdateIcon style={{ color: theme.palette.warning.main }} />
                    )}
                  </SvgIcon>
                </Tooltip>
              </TableCell>
              <TableCell width={100} align="right">
                <>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi radnika grupe">
                    <IconButton
                      onClick={() => {
                        setSelectedWorker(worker);
                        setEditModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Izbriši radnika grupe">
                    <IconButton
                      onClick={() => {
                        setSelectedWorker(worker);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteOutlined />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default GroupWorkersTable;
