import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Typography, Box } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import useAPISearch from '../hooks/useAPISearch';
import Spinner from '../Spinner';
import { AutocompleteLabel } from './style';
import TagChip from './TagChip';

const FormAutocomplete = ({
  label,
  routeName,
  showSide,
  showAbove,
  optionsLabel,
  defaultValue,
  placeholder,
  control,
  required = false,
  loading = false,
  initialData,
  defaultConditions,
  name,
  error,
  register,
  axios,
  setObject,
  acDisabled,
  multiple,
  minCharacters = 2,
  inputProps,
  variant = "standard",
  ...props
}) => {
  const [filters, setFilters] = useState(
    initialData && initialData.nodes?.length === 1 && { id: initialData.nodes[0].id }, // pretrazuje po id-u (provjeriti)
  );
  const [input, setInput] = useState('');
  const [active, setActive] = useState(false);
  const [rerenderComponent, setRerenderComponent] = useState(false);

  useEffect(() => {
    if (initialData && initialData.totalCount) {
      setRerenderComponent(true);
    }
  }, [initialData]);

  const [receivedData = { nodes: [], totalCount: 0 }, rdError, rdLoading] = useAPISearch(() => {
    return input.length >= minCharacters && axios && active
      ? axios
        .get(routeName, {
          params: {
            limit: 10,
            conditions: { ...defaultConditions, ...filters },
          },
        })
        .then(({ data }) => data)
      : Promise.resolve(() => initialData || { nodes: [], totalCount: 0 });
  }, [filters, input]);

  const handleOnInputChange = ({ target: { value } }) => {
    if (active) {
      if (value) setInput(value);
      setFilters({
        [optionsLabel]: value?.length === 0 ? undefined : value,
      });
    }
  };

  return (
    <Controller
      onChange={([, obj]) => obj}
      key={rerenderComponent && initialData && initialData.nodes}
      render={(renderProps) => (
        <Autocomplete
          disabled={acDisabled || loading}
          {...renderProps}
          id={`ac_${name}-id`}
          multiple={multiple}
          {...(multiple && { size: 'small' })}
          {...(multiple && { limitTags: 3 })}
          onClose={() => setActive(false)}
          onOpen={() => setActive(true)}
          loading={rdLoading}
          options={receivedData.nodes}
          onInputChange={(e) => e && handleOnInputChange(e)}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <TagChip {...getTagProps({ index })} label={option.codeName || option.name} />
            ));
          }}
          getOptionSelected={
            (option) => receivedData.nodes.indexOf(option) === 0
            // zaobilazi jer vraca warning u suprotnom radi asinhronog poziva, radi bez problema i sa tim warningom
            // option[optionsLabel] === value[optionsLabel]
          }
          getOptionLabel={(option) => {
            if (setObject) setObject(option);
            return option[optionsLabel] || '';
          }}
          loadingText="Učitavanje"
          noOptionsText={
            rdError
              ? 'Došlo je do greške'
              : input.length >= minCharacters
                ? 'Nema tražene vrijednosti'
                : `Unesite ${minCharacters} ili više karaktera`
          }
          renderOption={(option) => (
            <Box
              display="flex"
              alignItems={showSide ? 'center' : 'initial'}
              flexDirection={showSide ? 'row' : 'column'}
            >
              {showSide && <AutocompleteLabel>{option[showSide]}</AutocompleteLabel>}
              {showAbove && (
                <Typography display="block" variant="caption" gutterBottom>
                  ({option[showAbove]})
                </Typography>
              )}
              <Typography>{option[optionsLabel]}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              fullWidth
              label={label}
              error={error || rdError?.response?.data?.details?.reason}
              variant={variant}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                ...inputProps,
                endAdornment: (
                  <>
                    {loading || rdLoading ? <Spinner size={15} /> : params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(_, data) => renderProps.onChange(data)}
        />
      )
      }
      name={name}
      rules={{ required }}
      control={control}
      defaultValue={initialData && initialData.nodes?.length === 1 ? initialData.nodes[0] : []}
    />
  );
};

export default FormAutocomplete;
