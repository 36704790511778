export const handleSortChange = (value, sorters, setSorters) => {
  const sortValue = sorters.find((s) => s.split('"')[1] === value);
  if (sortValue) {
    const newSorters = sorters.filter((s) => s !== sortValue);
    if (sortValue.includes('ASC'))
      setSorters([...newSorters, `"${value}" DESC`]);
    else setSorters([...newSorters]);
  } else setSorters((prevState) => [...prevState, `"${value}" ASC`]);
};

export const getSortDirection = (value, sorters) => {
  return sorters
    .find((s) => s.split('"')[1] === value)
    ?.split(' ')[1]
    ?.toLowerCase();
};
