import { useEffect, useState } from 'react';

const useAPISearch = (f, deps, timeoutValue = 500) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let subscribed = true;
    setLoading(true);
    setError();
    const timeout = setTimeout(
      () =>
        f().then(
          (r) => {
            if (subscribed) {
              setLoading(false);
              setData(r);
            }
          },
          (e) => {
            if (subscribed) {
              setLoading(false);
              setError(e?.response ? e.response : e);
            }
          },
        ),

      timeoutValue,
    );

    return function cleanup() {
      subscribed = false;
      clearTimeout(timeout);
    };
  }, [...deps] || []);

  return [data, error, loading];
};

export default useAPISearch;
