import * as yup from 'yup';

export const documentConsentSchema = yup.object().shape({
  worker: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
        .object()
        .shape({
          id: yup.number().positive(),
        })
        .typeError('Polje "Radnik" je obavezno polje.')
      : yup
        .number()
        .positive()
        .transform((v, o) => (o === '' ? null : v))
        .typeError('Polje "Radnik" je obavezno polje.')

  }),
  organisation: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
        .object()
        .shape({
          id: yup.number().positive(),
        })
        .nullable()
      : yup
        .number()
        .positive()
        .transform((v, o) => (o === '' ? null : v))
        .nullable()

  }),
  comment: yup
    .string()
    .max(4000, 'Polje "Komentar" mora imati manje od 4000 karaktera.'),
  consentComment: yup
    .string()
    .max(4000, 'Polje "Komentar" mora imati manje od 4000 karaktera.'),

  // consent: yup
  //   .string()
  //   .oneOf(['D', 'N', 'I', ''], 'Morate odabrati vrijednosti DA, NE ili U TOKU.'),

});

export const documentConsentUpdateSchema = yup.object().shape({
  consentComment: yup
    .string()
    .max(4000, 'Polje "Komentar" mora imati manje od 4000 karaktera.'),

  consent: yup
    .string()
    .oneOf(['D', 'N', 'I', ''], 'Morate odabrati vrijednosti DA, NE ili U TOKU.'),

});

