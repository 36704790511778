import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
  DeleteModal,
  Spinner,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import axios from '../../utils/axios';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';
import FolderModal from './FolderModal';

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    name: 'Naziv',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
    description: 'Opis',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  return filters[chip];
};

const Folders = () => {
  const theme = useTheme();
  const history = useHistory();
  const { id: parentId } = useParams();
  const [folderName, setFolderName] = useState('Svi');
  const [page, setPage] = useState(0);
  const [triggerRerender, setRerender] = useState(false);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [folderModalVisible, setFolderModalVisible] = useState(false);
  const [folderHierarchyModalVisible, setFolderHierarchyModalVisible] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState();
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [folders = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`folders`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, parent: parentId },
            orderBy: [...sorters, '"id" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender, parentId],
  );

  const [folderHierarchy = [], hierarchyError, hierarchyLoading] = useAPI(
    () =>
      parentId
        ? axios.get(`folders/${parentId}/hierarchy`).then(({ data }) => {
            if (data?.length) {
              setFolderName(data[0].name);
            }
            return data;
          })
        : Promise.resolve(() => {
            setFolderName('');
            return [];
          }),
    [(page, rowsPerPage, filters, sorters, triggerRerender, parentId)],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <Modal
        parent={parentId}
        folder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        resetSelectedFolder={() => setSelectedFolder(undefined)}
        visible={folderModalVisible}
        setVisible={setFolderModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <FolderModal
        folder={parentId}
        folderName={folderName || 'Svi'}
        visible={folderHierarchyModalVisible}
        setVisible={setFolderHierarchyModalVisible}
      />
      <DeleteModal
        axios={axios}
        dataLength={folders.totalCount}
        page={page}
        setPage={setPage}
        path="folders"
        theme={theme}
        visible={deleteModalVisible}
        setRerender={setRerender}
        title={`Obriši folder ${selectedFolder?.id}`}
        promptText={`Da li ste sigurni da želite obrisati folder "${selectedFolder?.name}"?`}
        buttonText="Obriši folder"
        message="Uspješno izbrisan folder"
        setVisible={setDeleteModalVisible}
        resetObject={() => setSelectedFolder(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        objectId={selectedFolder?.id}
      />
      <PageHeader
        title="Folderi"
        showBackIcon={!!parentId || undefined}
        extra={
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AccountTreeIcon />}
                onClick={() => setFolderHierarchyModalVisible(true)}
              >
                <Box fontSize="small">Pregled hijerahije</Box>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setFolderModalVisible(true)}
              >
                <Box fontSize="small">Novi folder</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs maxItems={4} style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          {!folderHierarchy.length ? (
            <Typography variant="body1" color="textPrimary">
              Šifarnik: Folderi
            </Typography>
          ) : (
            <Link variant="body1" color="inherit" to="/folders" component={RouterLink}>
              Šifarnik: Folderi
            </Link>
          )}
          {hierarchyLoading || !folderHierarchy || hierarchyError ? (
            <Spinner size={14} />
          ) : (
            folderHierarchy.reverse().map((node, index) => {
              const lastElement = index === folderHierarchy.length - 1;
              return lastElement ? (
                <Typography key={node.id} variant="body1" color="textPrimary">
                  {node.name}
                </Typography>
              ) : (
                <Link
                  key={node.id}
                  variant="body1"
                  color="inherit"
                  to={`/folders/${node.id}`}
                  component={RouterLink}
                >
                  {node.name}
                </Link>
              );
            })
          )}
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="name"
                placeholder="Brza pretraga po nazivu"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            folders={folders.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={folders.totalCount}
            sorters={sorters}
            setSorters={setSorters}
            history={history}
            selectedFolder={selectedFolder}
            setDeleteModalVisible={setDeleteModalVisible}
            setFolderModalVisible={setFolderModalVisible}
            setSelectedFolder={setSelectedFolder}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Folders;
