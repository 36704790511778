import React from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PageContentWrapper as MuiPageContentWrapper } from '@shared/components';


export const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.background.primary,
  },
}))(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(4),
    fontFamily: ['sans-serif', 'Roboto'].join(','),
    '&:hover': {
      color: theme.palette.background.primary,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.background.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.background.primary,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const PageContentWrapper = styled(MuiPageContentWrapper)`
  padding: 80px 0 0 0;
`;
