import React, { useState, useEffect } from 'react';
import { TextField, FormHelperText, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateInput } from '../DatePickers';
import FormAutocomplete from '../FormAutocomplete';
import FormSelect from '../FormSelect';
import useAPI from '../hooks/useAPI';

const useStyles = makeStyles(() => ({
  select: {
    borderBottom: '2px solid orange',
    height: '27px',
  },
}));

const editStyleProps = {
  style: {
    borderBottom: '2px solid orange',
  },
};

const InputManifold = ({
  attributeObject,
  register,
  placeholder,
  control,
  errors,
  name,
  id,
  label,
  axios,
  dirtyFields,
}) => {
  const [isEdited, setIsEdited] = useState(false);
  const classes = useStyles();
  const [attributeValues, fsError, fsLoading] = useAPI(() => {
    return attributeObject?.attributeType === 4 || attributeObject?.attributeType === 3
      ? axios
          .get(`attribute-options`, {
            params: {
              ...(attributeObject?.attributeType === 3 && { limit: 1 }),
              conditions: {
                id: attributeObject?.id,
                ID:
                  (attributeObject?.attributeType === 3 || attributeObject?.initValue) &&
                  attributeObject?.value,
              },
            },
          })
          .then(({ data }) => data)
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, []);

  const getFormSelectInit = () => {
    if (
      attributeObject?.attributeType !== 4 ||
      !attributeValues?.nodes.length ||
      !attributeObject?.initValue
    )
      return null;

    const defaultValue = attributeValues?.nodes.find(
      (attribute) => attribute.ID === parseInt(attributeObject?.initValue || '', 10),
    );

    return JSON.stringify({
      NAZIV: defaultValue.NAZIV,
      ID: defaultValue.ID,
    });
  };

  useEffect(() => {
    if (name in dirtyFields) setIsEdited(dirtyFields[name]);
    else setIsEdited(dirtyFields[name]);
  }, [dirtyFields[name], name, dirtyFields]);

  switch (attributeObject?.attributeType) {
    case 2:
    case 5:
      return (
        <DateInput
          placeholder={
            placeholder ||
            `Unesite datum ${attributeObject?.attributeType === 5 ? 'i vrijeme' : ''} atributa`
          }
          className="date-input"
          name={name}
          label={label}
          errors={!!errors[name]}
          object={attributeObject}
          // defaultName="valueDisplayed"
          customDefault={'valueDisplayed' in attributeObject && attributeObject.valueDisplayed}
          customDefaultFormat={attributeObject?.attributeType === 2 && 'DD.MM.YYYY'}
          customHintMessage={attributeObject?.hint}
          defaultValue={attributeObject?.initValue === 'sysdate' && new Date()}
          setError={() => {}}
          format={attributeObject?.attributeType === 2 ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'}
          control={control}
          variant="standard"
          {...(isEdited && {
            inputProps: {
              ...editStyleProps,
            },
          })}
        />
      );
    case 3:
      return (
        <FormAutocomplete
          error={!!errors[name] || fsError}
          loading={fsLoading}
          fullWidth
          label={label}
          name={name}
          placeholder={placeholder || 'Unesite dva ili više karaktera'}
          routeName="attribute-options"
          defaultConditions={{ id: attributeObject?.id }}
          optionsLabel="NAZIV"
          initialData={(attributeObject?.value || attributeObject?.initValue) && attributeValues}
          variant="standard"
          control={control}
          axios={axios}
          register={register}
          minCharacters={3}
          {...(isEdited && {
            inputProps: {
              ...editStyleProps,
            },
          })}
        />
      );
    case 4:
      return attributeValues?.nodes.length ? (
        <>
          <FormSelect
            error={!!errors[name] || !!fsError}
            fullWidth
            name={name}
            label={label}
            id={id}
            defaultValue={
              attributeObject?.value
                ? JSON.stringify({
                    NAZIV: attributeObject?.valueDisplayed,
                    ID: parseInt(attributeObject?.value || '', 10),
                  })
                : getFormSelectInit()
            }
            variant="standard"
            placeholder={placeholder || 'Izaberite vrijednost atributa'}
            control={control}
            loading={fsLoading}
            {...(isEdited &&
              attributeValues.nodes && {
                classes: classes.select,
              })}
          >
            {attributeValues?.nodes?.map((attributeValue) => (
              <MenuItem
                key={attributeValue?.ID}
                value={JSON.stringify({ NAZIV: attributeValue?.NAZIV, ID: attributeValue?.ID })}
              >
                {attributeValue?.NAZIV}
              </MenuItem>
            ))}
          </FormSelect>
          {fsError && (
            <FormHelperText error>
              {fsError?.data?.details?.reason || 'Došlo je do greške'}
            </FormHelperText>
          )}
        </>
      ) : (
        <>
          <TextField fullWidth name={name} id={id} label={label} disabled />
          {fsError && (
            <FormHelperText error>
              {fsError?.data?.details?.reason || 'Došlo je do greške'}
            </FormHelperText>
          )}
        </>
      );
    default:
      return (
        <TextField
          error={!!errors[name]}
          fullWidth
          name={name}
          id={id}
          label={label}
          variant="standard"
          size="small"
          defaultValue={attributeObject?.valueDisplayed || attributeObject?.initValue}
          inputRef={register}
          placeholder={placeholder || 'Unesite vrijednost atributa'}
          {...(isEdited && {
            inputProps: {
              ...editStyleProps,
            },
          })}
        />
      );
  }
};

const AttributeField = ({
  // isUpdating,
  attributeObject,
  placeholder,
  errors,
  register,
  control,
  // defaultValue,
  name,
  label,
  id,
  axios,
  dirtyFields,
}) => {
  // const [onUpdate, setOnUpdate] = React.useState(isUpdating || false);

  return (
    <>
      {/* {onUpdate ? (
        <>
          <TextField
            fullWidth
            defaultValue={defaultValue}
            name={name}
            id={id}
            label={label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setOnUpdate(false)}
                  >
                    <SvgIcon fontSize='small'>
                      <EditIcon color="primary" />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled
          />
          <FormHelperText>
            Kliknite na olovku da promijenite polje &quot;Vrijednost&quot;
          </FormHelperText>
        </>
      ) : (
        <> */}
      <InputManifold
        attributeObject={attributeObject}
        control={control}
        placeholder={placeholder}
        register={register}
        errors={errors}
        name={name}
        label={label}
        id={id}
        axios={axios}
        dirtyFields={dirtyFields}
      />
      {errors[name] && <FormHelperText error>{errors[name].message}</FormHelperText>}
    </>
    // )}
    // </>
  );
};

export default AttributeField;
