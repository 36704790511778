import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import Keycloak from 'keycloak-js';
import { Reset } from 'styled-reset';
import { ThemeProvider } from 'styled-components';
import theme from '@shared/theme';
import Provider from 'context/Provider';
import MainView from 'MainView';

//-----------------------------------------------
const App = ({ keycloak }) => {
  return (
    <Provider>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <Reset />
            <Router>
              <MainView keycloak={keycloak} />
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

const initOptions = {
  url: process.env.REACT_APP_AUTH_URL,
  realm: process.env.REACT_APP_AUTH_REALM,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  onLoad: 'login-required',
};

const keycloak = Keycloak(initOptions);

keycloak
  .init({
    onLoad: initOptions.onLoad,
    checkLoginIframe: false,
  })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      // eslint-disable-next-line
      console.info('Authenticated');
    }

    // React Render
    // eslint-disable-next-line no-undef
    ReactDOM.render(<App keycloak={keycloak} />, document.getElementById('root'));

    localStorage.setItem('token', `Bearer ${keycloak.token}`);
    localStorage.setItem('react-refresh-token', keycloak.refreshToken);
  })
  .catch((err) => {
    // eslint-disable-next-line
    console.error(err);
    // eslint-disable-next-line
    console.error('Authenticated Failed');
  });

keycloak.onTokenExpired = () => {
  keycloak
    .updateToken(180)
    .then((refreshed) => {
      if (refreshed) {
        localStorage.setItem('token', `Bearer ${keycloak.token}`);
        localStorage.setItem('react-refresh-token', keycloak.refreshToken);
        // eslint-disable-next-line
        console.debug(`Token refreshed ${refreshed}`);
      } else {
        // eslint-disable-next-line
        console.warn(
          `Token not refreshed, valid for
                  ${Math.round(
                    keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000,
                  )}
                  seconds`,
        );
      }
    })
    .catch(async (err) => {
      // eslint-disable-next-line
      console.error('Failed to refresh token');
      // eslint-disable-next-line
      console.error(err);
      await keycloak.logout({ redirectUri: `${window.location.origin}` });
    });
};

keycloak.onAuthRefreshError = async () => {
  await keycloak.logout({ redirectUri: `${window.location.origin}` });
};
