import React, { useRef, useState } from 'react';
import { Box, ButtonBase, Hidden, MenuItem, Typography } from '@material-ui/core';
import { Avatar, getInitials } from '@shared/components';
import useAuthContext from '../../../hooks/useAuthContext';
import { Menu } from './style';

const Account = () => {
  const { user, signOut } = useAuthContext();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Hidden smDown>
          <Avatar alt="User" name={user.name}>
            {getInitials(user.name)}
          </Avatar>
          <Box mr={1}>
            <Typography variant="h6" color="inherit">
              {user.name}
            </Typography>
          </Box>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={signOut}>Odjavi se</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
