import styled, { keyframes } from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  // max-height: 1200px;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background: rgba(221, 221, 221, 0.61);
`;

export const Wrapper = styled.div`
  width: 70%;
  height: 75%;
  transform: scale(0.95);
`;

export const Content = styled.div`
  height: 100%;
  // margin: 0;
  max-height: 1200px;
  box-shadow: 0px 0px 8px 1px #ccc;
  background: #fafafa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: #e7ebf2;
  font-size: 14.5rem;
  letter-spacing: 0.1em;
  margin: 0.1em;
  text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

  @media (max-width: 30rem) {
    font-size: 8.5rem;
  }
`;

export const Subtitle = styled.h2`
  color: #e7ebf2;
  font-size: 1.7rem;
  margin-bottom: 0.4em;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  // opacity: 0.6;
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.action.active};
  font-size: 1.15rem;
  margin-top: 0;
  opacity: 0.7;
`;

const rotate = keyframes`
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
`;

export const RotateImage = styled.img`
  height: 9.5rem;
  -webkit-animation-name: ${rotate};
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;

  animation-name: ${rotate};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;

  &:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  margin-right: 1.2rem;
`;

export const Button = styled(MuiButton)`
  padding: 1rem;
  font-size: 0.95rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  // opacity: 0.95;
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
`;
