import React from 'react';
import { Grid, Box, Typography, Card, CardContent } from '@material-ui/core';
import { ViewModal, FolderView } from '@shared/components';
import axios from '../../../utils/axios';

import useTheme from '../../../hooks/useTheme';

const FolderModal = ({ visible, setVisible, folder, folderName }) => {
  const theme = useTheme();

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <ViewModal
      viewTitle="Pregled hijerahije foldera"
      visible={visible}
      handleClose={handleClose}
      modalWidth="35%"
      theme={theme}
    >
      <Box p={5} style={{ backgroundColor: theme.palette.background.dark }}>
        <Card elevation={4} style={{ height: 'auto' }}>
          <CardContent>
            <Grid
              container
              spacing={6}
              direction="column"
              justifyContent="center"
              style={{
                padding: `${theme.spacing(8)}px ${theme.spacing(10)}px ${theme.spacing(
                  8,
                )}px ${theme.spacing(10)}px`,
                minHeight: 200,
              }}
              // alignItems="stretch"
            >
              <Typography variant="h4" style={{ paddingBottom: theme.spacing(6) }}>
                Trenutni folder:
                <Box component="span" style={{ fontStyle: 'italic' }}>
                  {` ${folderName}` || 'Svi'}
                </Box>
              </Typography>
              <Box ml={2}>
                <FolderView
                  selected={folder}
                  defaultSelected={folder}
                  setSelected={() => {}}
                  expanded
                  axios={axios}
                  theme={theme}
                />
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </ViewModal>
  );
};

export default FolderModal;
