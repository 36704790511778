import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  CardHeader,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { InputModal, FilterChip } from '@shared/components';
import { handleFilterChange, handleChipDelete } from '../../../utils/filterHelpers';
import useTheme from '../../../hooks/useTheme';
import { ContentBox } from './style';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga dokumenata po metapodacima"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="40%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={12}>
            <Box>
              <Card style={{ height: '100%' }} elevation={3}>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle2' }}
                  title="Metapodaci o dokumentu"
                  action={
                    <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                      <IconButton
                        onClick={() => {
                          setFilterValues({});
                        }}
                      >
                        <SvgIcon fontSize="small">
                          <ClearAllIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <ContentBox elevation={5}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="ID"
                        name="id"
                        type="number"
                        placeholder="Unesite ID za pretragu"
                        value={filterValues.id || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            id: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Dokument"
                        name="document"
                        type="number"
                        placeholder="Unesite dokument za pretragu"
                        value={filterValues.document || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            document: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Tip dokumenta"
                        name="documentTypeName"
                        placeholder="Unesite tip dokumenta za pretragu"
                        value={filterValues.documentTypeName || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            documentTypeName: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Status dokumenta"
                        name="documentStatusName"
                        placeholder="Unesite tip dokumenta za pretragu"
                        value={filterValues.documentStatusName || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            documentStatusName: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Korisnik kreirao"
                        name="createdBy"
                        placeholder="Unesite korisnika za pretragu"
                        value={filterValues.createdBy || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            createdBy: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Atribut (metapodataka)"
                        name="attributeName"
                        placeholder="Unesite atribut za pretragu"
                        value={filterValues.attributeName || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            attributeName: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Datum kreiranja"
                        name="dateCreated"
                        type="date"
                        fullWidth
                        placeholder="Unesite datum kreiranja za pretragu"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={filterValues.dateCreated || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            dateCreated: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Vrijednost"
                        name="valueDisplayed"
                        placeholder="Unesite vrijednost za pretragu"
                        value={filterValues.valueDisplayed || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            valueDisplayed: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </ContentBox>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default Filter;
