import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import useAPI from '../hooks/useAPI';
import Spinner from '../Spinner';


const FormSelectDependent = ({
  name,
  label,
  control,
  defaultValue,
  defaultLabel,
  placeholder,
  conditionField,
  value,
  route,
  required = false,
  disabled,
  error,
  displayName = 'id',
  valueName = 'id',
  sorters = [],
  axios,
  ...props
}) => {
  const defaultData = { nodes: [], totalCount: 0 };
  const labelId = `${name}-label`;
  const [focused, setFocused] = useState(false);
  const [dataNodes = { nodes: [], totalCount: 0 }, errorData, loadingData] = useAPI(
    () =>
      value
        ? axios
            .get(route, {
              params: {
                conditions: { [conditionField]: value },
                orderBy: sorters,
              },
            })
            .then(({ data }) => data)
        : Promise.resolve(() => defaultData),
    [conditionField, value],
  );

  return (
    <FormControl
      error={error || !!errorData}
      {...props}
      disabled={disabled}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={{ required }}
        as={
          <Select
            placeholder={placeholder}
            labelId={labelId}
            label={label}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            endAdornment={
              (loadingData && <Spinner style={{ marginRight: '56px' }} size={15} />) ||
              (!required && !disabled && control.getValues(name) !== '' && focused && (
                <InputAdornment variant="outlined" style={{ marginRight: '28px' }} position='end'>
                  <IconButton size="small" edge="end" onClick={() => control.setValue(name, '')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ))
            }
          >
            {!dataNodes?.nodes || !dataNodes?.nodes?.length ? (
              <MenuItem value={defaultValue}>{defaultLabel}</MenuItem>
            ) : (
              dataNodes?.nodes?.map((row) => (
                <MenuItem key={row.id} value={row[valueName]}>
                  {row[displayName]}
                </MenuItem>
              ))
            )}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};
export default FormSelectDependent;
