import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button, Tooltip } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
// import { useHistory } from 'react-router-dom';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  DeleteModal,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';

import axios from '../../../../utils/axios';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import useGlobalAlertContext from '../../../../hooks/useGlobalAlertContext';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import useAuthContext from '../../../../hooks/useAuthContext';
import useTheme from '../../../../hooks/useTheme';

// import DocumentModal from '../Documents/Modal';
import GroupModal from './GroupModal';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';

const fields = [
  {
    label: 'Datum',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik',
    value: 'createdBy',
  },
  {
    label: 'Radnik',
    value: 'workerFullName',
  },
  {
    label: 'Organizacija',
    value: 'organisationName',
  },
  {
    label: 'Saglasan',
    value: 'consent',
  },
  {
    label: 'Komentar',
    value: 'comment',
  },
  {
    label: 'Komentar saglasnost',
    value: 'consentComment',
  },
];

const getLabelText = (value) => {
  const type = {
    dateCreated: 'Datum',
    organisation: 'Organizacija',
    createdBy: 'Korisnik',
    organisationName: 'Organizacija',
    workerFullName: 'Radnik',
    consent: 'Saglasan',
    comment: 'Komentar',
    consentComment: 'Komentar saglasnost',
  };
  return type[value];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  if (chip === 'consent')
    return filters[chip] === 'D' ? 'DA' : filters[chip] === 'N' ? 'NE' : 'U TOKU';
  return filters[chip];
};

const DocumentConsents = ({
  documentId,
  parentData: document,
  setDocument,
  documentLoading = false,
  setDocumentRerender,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [triggerRerender, setRerender] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);
  const { user } = useAuthContext();
  const [selectedConsent, setSelectedConsent] = useState(undefined);
  const [userIsResponsiblePerson, setUserIsResponsiblePerson] = useState(false);

  useEffect(() => {
    if (user?.username?.toLowerCase() === document?.responsiblePersonUsername?.toLowerCase()) {
      setUserIsResponsiblePerson(true);
    } else {
      setUserIsResponsiblePerson(false);
    }
  }, [document, user]);

  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [consents = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`document-consents`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, documentId },
            orderBy: [...sorters, '"id" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
      />
      <Modal
        document={document}
        documentLoading={documentLoading}
        user={user}
        userIsResponsiblePerson={userIsResponsiblePerson}
        selectedConsent={selectedConsent}
        setSelectedConsent={setSelectedConsent}
        visible={modalVisible}
        setVisible={setModalVisible}
        setRerender={setRerender}
        resetConsent={() => setSelectedConsent(null)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setDocumentRerender={setDocumentRerender}
      />
      <GroupModal
        document={document}
        documentLoading={documentLoading}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setDocument={setDocument}
        visible={groupModalVisible}
        setVisible={setGroupModalVisible}
      />
      {userIsResponsiblePerson ? (
        <DeleteModal
          axios={axios}
          dataLength={consents.totalCount}
          page={page}
          setPage={setPage}
          path="document-consents"
          visible={deleteModalVisible}
          setRerender={setRerender}
          title={`Obriši saglasnost na dokumentu ${selectedConsent?.id}`}
          promptText={`Da li ste sigurni da želite obrisati saglasnost od "${selectedConsent?.workerFullName}"?`}
          buttonText="Obriši saglasnost"
          message="Uspješno izbrisana saglasnost"
          setVisible={setDeleteModalVisible}
          resetObject={() => setSelectedConsent(undefined)}
          sendSuccessAlertData={sendSuccessAlertData}
          sendErrorAlertData={sendErrorAlertData}
          objectId={selectedConsent?.id}
          theme={theme}
        />
      ) : null}
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="workerFullName"
                placeholder="Brza pretraga po radniku"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={consents.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" asc']}
                    filters={{ ...filters, documentId }}
                    routeName="document-consents"
                    variant="outlined"
                    fileName={`saglasnosti-na-dokumentu-${documentId}`}
                  />
                </Grid>
                {userIsResponsiblePerson ? (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={() => setModalVisible(true)}
                      >
                        <Box fontSize="small">Nova saglasnost na dokumentu</Box>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        enterDelay={300}
                        placement="top-end"
                        arrow
                        title={
                          document?.groupAccordanceId !== null
                            ? 'Grupa saglasnosti je već dodana'
                            : ''
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GroupAddIcon />}
                            onClick={() => setGroupModalVisible(true)}
                            disabled={document?.groupAccordanceId !== null}
                          >
                            <Box fontSize="small">Dodaj radnike grupe saglasnosti</Box>
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            consents={consents.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={consents.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            setSelectedConsent={setSelectedConsent}
            setEditModalVisible={setModalVisible}
            setDeleteModalVisible={setDeleteModalVisible}
            setRerender={setRerender}
            userIsResponsiblePerson={userIsResponsiblePerson}
            user={user}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentConsents;
