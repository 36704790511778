import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { InputModal, FormSelect } from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const DocumentTypeModal = ({
  documentType,
  setDocumentType,
  visible,
  setVisible,
  sendSuccessAlertData,
  resetDocumentType,
  setRerender,
  sendErrorAlertData,
  detailed = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm();

  const handleClose = () => {
    setVisible(false);
    if (!detailed) resetDocumentType();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = documentType
        ? await axios.put(`document-types/${documentType.id}`, {
            documentType: values,
          })
        : await axios.post(`document-types`, {
            documentType: values,
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${documentType ? 'izmijenjen' : 'dodan'} tip dokumenta`);
      if (setDocumentType) setDocumentType((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${documentType ? 'uređivanja' : 'unosa'} `
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={documentType}
      createTitle="Kreiraj tip dokumenta"
      updateTitle={`Uredi tip dokumenta ${documentType?.code}`}
      createButtonText="Kreiraj tip dokumenta"
      updateButtonText="Uredi tip dokumenta"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid container style={{ minHeight: 310 }} spacing={6} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <TextField
                      error={!!errors.code}
                      fullWidth
                      label="Šifra*"
                      name="code"
                      id="code"
                      defaultValue={documentType?.code}
                      placeholder="Unesite šifru tipa dokumenta"
                      inputRef={register}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.name}
                      fullWidth
                      label="Naziv*"
                      name="name"
                      id="name"
                      defaultValue={documentType?.name}
                      placeholder="Unesite naziv "
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      id="documentSaveId"
                      name="documentSaveId"
                      label="Default opcija snimanja"
                      required
                      error={!!errors.documentSaveId}
                      defaultValue={documentType?.documentSaveId}
                      control={control}
                      fullWidth
                    >
                      <MenuItem value={1}>1 - Trenutna stranica</MenuItem>
                      <MenuItem value={2}>2 - Sve stranice - sve skupa</MenuItem>
                      <MenuItem value={3}>3 - Sve stranice - svaka pojedinačno</MenuItem>
                      <MenuItem value={4}>4 - Raspon stranica - sve skupa</MenuItem>
                      <MenuItem value={5}>5 - Raspon stranica - svaka pojedinačno</MenuItem>
                    </FormSelect>
                    {errors.documentSaveId && (
                      <FormHelperText error>{errors.documentSaveId.message}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default DocumentTypeModal;
