import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
  Collapse,
  Divider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import mime from 'mime-types';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  TableSkeleton,
  dateFormatterTime,
  dateFormatterDate,
  Spinner,
  StatusLabel,
  LabeledLinearProgress,
} from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

import { ErrorMessage, ExpandableRowCell, ExpandedRow, Wrapper } from './style';

const displayDuration = (daysCount) => {
  if (daysCount == null || daysCount < 0) return '--';
  const { months, days, hours, minutes } = splitTime(daysCount);
  const monthStr = months > 0 ? `${months} mj` : '';
  const dayStr = months < 1 && days < 1 ? '' : `${days} d`;
  const minutesStr = months < 1 && days < 1 ? `${minutes} min` : '';
  return `${monthStr} ${dayStr} ${hours} h ${minutesStr}`;
};

const splitTime = (numberOfDays) => {
  const days = Math.floor(numberOfDays);
  const months = Math.floor(days / 30);
  const remDays = days % 30;
  const remainder = numberOfDays - days;
  const hours = Math.floor(remainder * 24);
  const minutes = Math.floor(60 * (remainder * 24 - hours));
  return { months, days: remDays, hours, minutes };
};

const getStatusLabel = (status, statusName) => {
  if (status !== 0 && !status) return '';
  const map = {
    0: {
      color: 'info',
    },
    1: {
      color: 'neutral',
    },
    2: {
      color: 'secondary',
    },
    3: {
      color: 'primary',
    },
    4: {
      color: 'success',
    },
    5: {
      color: 'error',
    },
    6: {
      color: 'warning',
    },
    7: {
      color: 'info',
    },
  };

  const { color } = map[status] || 'neutral';

  return <StatusLabel color={color}>{statusName}</StatusLabel>;
};

const tableProperties = {
  headColumn: '6%',
  tailColumn: '14%',
  columns: [
    {
      value: 'id',
      name: 'ID',
      sortName: 'ID-u',
      width: '8%',
    },
    {
      value: 'dateCreated',
      name: 'Datum kreiranja',
      sortName: 'datumu kreiranja',
      width: '11%',
    },
    {
      value: 'responsiblePersonName',
      name: 'Odgovorna osoba',
      sortName: 'odgovornoj osobi',
      width: '10%',
    },
    {
      value: 'accordancePercentage',
      name: `Trenutni / Potrebni procenat saglasnosti`,
      sortName: 'procentu saglasnosti',
      width: '14%',
    },
    {
      value: 'accordancesHtml',
      name: `Saglasnosti na dokument`,
      sortName: 'saglasnostima na dokumentu',
      width: '12%',
    },
    {
      value: 'statusName',
      name: `Status`,
      sortName: 'statusu',
      width: '13%',
    },
    {
      value: 'currentStatusDurationDays',
      name: 'Trajanje trenutnog statusa',
      sortName: 'trajanju trenutnog statusa',
      width: '12%',
    },
  ],
};
// @ TODO - kolona odgovorna osoba!!!
const ExpandableRow = ({
  document,
  setSelectedDocument,
  setWorkerOrgModalVisible,
  selectedDocument,
  downloadLoading,
  getDocumentFile,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  return (
    <>
      <TableRow key={document.id} style={{ borderBottom: 0 }}>
        <ExpandableRowCell>
          <IconButton size="small" onClick={() => setOpen((prevState) => !prevState)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ExpandableRowCell>
        <ExpandableRowCell>{document.id}</ExpandableRowCell>
        <ExpandableRowCell>
          {dateFormatterDate(document.dateCreated)}
          <Typography variant="h6" color="textSecondary">
            {dateFormatterTime(document?.dateCreated)}
          </Typography>
        </ExpandableRowCell>
        <ExpandableRowCell>{document.responsiblePersonName}</ExpandableRowCell>
        <ExpandableRowCell>
          <Tooltip
            enterDelay={300}
            placement="top-start"
            arrow
            title={`Trenutno: ${document.currentPercentage}% Potrebno: ${document.accordancePercentage}% Max: 100%`}
          >
            <span>
              <LabeledLinearProgress
                value={document.currentPercentage}
                buffer={document.accordancePercentage}
                theme={theme}
                small
              />
            </span>
          </Tooltip>
        </ExpandableRowCell>
        <ExpandableRowCell>
          <Wrapper dangerouslySetInnerHTML={{ __html: document?.accordancesHtml || '' }} />
        </ExpandableRowCell>
        <ExpandableRowCell>
          {getStatusLabel(document.status, document.statusName)}
        </ExpandableRowCell>
        <ExpandableRowCell>
          <Typography variant="h6" color="textSecondary">
            {displayDuration(document.currentStatusDurationDays)}
          </Typography>
        </ExpandableRowCell>

        <ExpandableRowCell align="right">
          <>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Radnici/organizacije">
              <IconButton
                onClick={() => {
                  setSelectedDocument(document);
                  setWorkerOrgModalVisible(true);
                }}
              >
                <SvgIcon fontSize="small">
                  <AssignmentIndOutlinedIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip
              enterDelay={300}
              placement="top-end"
              arrow
              title={
                downloadLoading && document?.id === selectedDocument?.id ? '' : 'Prikaži dokument'
              }
            >
              {downloadLoading && document?.id === selectedDocument?.id ? (
                <span>
                  <IconButton size="small" disabled>
                    <Box pr={3}>
                      <Spinner color="primary" size={20} />
                    </Box>
                  </IconButton>
                </span>
              ) : (
                <span>
                  <IconButton
                    disabled={downloadLoading}
                    onClick={() => {
                      setSelectedDocument(document);
                      getDocumentFile(document.id, document.documentExtension);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <InsertDriveFileOutlinedIcon />
                    </SvgIcon>
                  </IconButton>
                </span>
              )}
            </Tooltip>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Detaljni pregled dokumenta">
              <span>
                <IconButton
                  onClick={() =>
                    history.push({
                      pathname: `/overview/${document.id}`,
                    })
                  }
                >
                  <SvgIcon fontSize="small">
                    <ArrowForwardIcon />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
          </>
        </ExpandableRowCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="100%" style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider light />
            <Box>
              <ExpandedRow>
                <Table size="small" aria-label="expanded">
                  <TableHead>
                    <TableRow>
                      <ExpandableRowCell width={tableProperties.headColumn} />
                      <ExpandableRowCell width="19%">Tip dokumenta</ExpandableRowCell>
                      <ExpandableRowCell width="10%">Broj stranica</ExpandableRowCell>
                      <ExpandableRowCell width="14%">Ekstenzija</ExpandableRowCell>
                      <ExpandableRowCell width="12%">Veličina fajla (bajt)</ExpandableRowCell>
                      <ExpandableRowCell width="25%">Broj protokola</ExpandableRowCell>
                      <ExpandableRowCell width={tableProperties.tailColumn} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* VRIJEDNOSTI - Ovdje se dodaju vrijednosti. */}
                      <ExpandableRowCell />
                      <ExpandableRowCell>{document.documentTypeName || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{document.documentNoPages || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{document.documentExtension || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{document.documentFilesize || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{document.protocolNumber}</ExpandableRowCell>
                      <ExpandableRowCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </ExpandedRow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DocumentsAccordanceTable = ({
  documents,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedDocument,
  selectedDocument,
  setWorkerOrgModalVisible,
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendErrorAlertData } = useGlobalAlertContext();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(undefined);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={tableProperties.headColumn} />
          {tableProperties.columns.map((column) => (
            <TableCell key={column.value} align={column.align} width={column.width}>
              {column.value === 'accordancesHtml' ? (
                column.name
              ) : (
                <Tooltip enterDelay={300} title={`Sortiraj prema ${column.sortName}`}>
                  <TableSortLabel
                    active={!!getSortDirection(column.value, sorters)}
                    direction={getSortDirection(column.value, sorters)}
                    onClick={() => handleSortChange(column.value, sorters, setSorters)}
                  >
                    {column.name}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          ))}
          <TableCell width={tableProperties.tailColumn} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documents ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documents?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan dokument</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documents?.map((document) => (
            <ExpandableRow
              key={document.id}
              document={document}
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
              downloadLoading={downloadLoading}
              getDocumentFile={getDocumentFile}
              setWorkerOrgModalVisible={setWorkerOrgModalVisible}
            />
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentsAccordanceTable;
