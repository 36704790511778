import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  SvgIcon,
  IconButton,
  TablePagination,
  Box,
  Typography,
  Collapse,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TableSkeleton } from '@shared/components';
import { ErrorMessage, ExpandableRowCell, ExpandedRow } from './style';
import useTheme from '../../../hooks/useTheme';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';

const mainTableColumns = [
  {
    name: 'Redni broj',
    value: 'orderNumber',
    align: 'left',
    width: '100',
    sortPlaceholder: 'rednom broju',
  },
  {
    name: 'Labela',
    value: 'label',
    align: 'left',
    width: '150',
    sortPlaceholder: 'labeli',
  },
  {
    name: 'Šifra atributa',
    value: 'attributeCode',
    align: 'left',
    width: '100',
    sortPlaceholder: 'šifri atributa',
  },
  {
    name: 'Obavezan',
    value: 'required',
    align: 'left',
    width: '70',
    sortPlaceholder: 'Obavezan',
  },
  {
    name: 'Spasi',
    value: 'save',
    align: 'left',
    width: '70',
    sortPlaceholder: 'Spasi',
  },
  {
    name: 'Važi',
    value: 'valid',
    align: 'left',
    width: '70',
    sortPlaceholder: 'Važi',
  },
  {
    name: 'Prikaži na skeniranju',
    value: 'showOnScan',
    align: 'left',
    width: '70',
    sortPlaceholder: 'prikaži na skeniranju',
  },
  {
    name: 'Prikaži u porukama',
    value: 'showInMessages',
    align: 'left',
    width: '70',
    sortPlaceholder: 'prikaži u porukama',
  },
];

const secondaryTableColumns = [
  { name: 'Grupa atributa', value: 'attributeGroupName', align: 'left', width: '70' },
  { name: 'SQL upit za podatke', value: 'sqlCodeForData', align: 'left', width: '275' },
  { name: 'Inicijalna vrijednost', value: 'initialValue', align: 'left', width: '180' },
  { name: 'Uputstvo (hint)', value: 'instructions', align: 'left', width: '100' },
];

const ExpandableRow = ({ docType, setSelectedUnit, setDeleteModalVisible, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <>
      <TableRow key={docType.id} style={{ borderBottom: 0 }}>
        <ExpandableRowCell width={45}>
          <IconButton size="small" onClick={() => setOpen((prevState) => !prevState)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ExpandableRowCell>
        <ExpandableRowCell width={100}>{docType.orderNumber}</ExpandableRowCell>
        <ExpandableRowCell width={150}>{docType.label}</ExpandableRowCell>
        <ExpandableRowCell width={100}>{docType.attributeCode}</ExpandableRowCell>
        <ExpandableRowCell width={70} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.required === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={70} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.save === 1 ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={70} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.valid === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={70} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.showOnScan === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={70} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.showInMessages === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell align="right" width={120}>
          <>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi tip dokumenta">
              <IconButton
                onClick={() => {
                  setSelectedUnit(docType);
                  setModalVisible(true);
                }}
              >
                <SvgIcon fontSize="small">
                  <EditOutlinedIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni tip dokumenta">
              <IconButton
                onClick={() => {
                  setSelectedUnit(docType);
                  setDeleteModalVisible(true);
                }}
              >
                <SvgIcon fontSize="small">
                  <DeleteOutlinedIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </>
        </ExpandableRowCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <ExpandedRow>
              <Box display="flex">
                <Box flex={50} />
                {secondaryTableColumns.map((column) => (
                  <Box key={docType.code + column.value} flex={column.width}>
                    <Typography variant="h6" style={{ fontWeight: 600 }} display="inline">
                      {column.name}: <br />
                    </Typography>
                    <Typography variant="h6" display="inline">
                      {docType[column.value] || ''}
                    </Typography>
                  </Box>
                ))}
                <Box flex={50} />
              </Box>
            </ExpandedRow>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DetailsTable = ({
  documentTypeDetails,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedUnit,
  setDeleteModalVisible,
  setModalVisible,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          {mainTableColumns.map((column) => (
            <TableCell key={column.value} align={column.align} width={column.width}>
              <Tooltip
                enterDelay={300}
                title={`Sortiraj prema ${column.sortPlaceholder}`}
                placement="bottom-start"
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={120} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documentTypeDetails ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documentTypeDetails?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan tip dokumenta sa datim atributom</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documentTypeDetails?.map((docType) => (
            <ExpandableRow
              key={docType.id}
              docType={docType}
              setSelectedUnit={setSelectedUnit}
              setDeleteModalVisible={setDeleteModalVisible}
              setModalVisible={setModalVisible}
            />
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DetailsTable;
