import React from 'react';
import { useHistory } from 'react-router';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  IconButton,
  TablePagination,
  Box,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableSkeleton } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const getSaveMethodLabel = (methodCode) => {
  const map = {
    1: '1 - Trenutna stranica',
    2: '2 - Sve stranice - sve skupa',
    3: '3 - Sve stranice - svaka pojedinačno',
    4: '4 - Raspon stranica - sve skupa',
    5: '5 - Raspon strnaica - svaka pojedinačno',
  };
  return map[methodCode];
};

const DocumentTypesTable = ({
  documentTypes,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedUnit,
  setDeleteModalVisible,
  setDocumentTypeModalVisible,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  const history = useHistory();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema id-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema šifri" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('code', sorters)}
                direction={getSortDirection('code', sorters)}
                onClick={() => handleSortChange('code', sorters, setSorters)}
              >
                Šifra
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema opciji snimanja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentSaveId', sorters)}
                direction={getSortDirection('documentSaveId', sorters)}
                onClick={() => handleSortChange('documentSaveId', sorters, setSorters)}
              >
                Default opcija snimanja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documentTypes ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documentTypes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan tip dokumenta</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documentTypes?.map((documentType) => (
            <TableRow key={documentType.id}>
              <TableCell width={150}> {documentType.id}</TableCell>
              <TableCell width={150}> {documentType.code}</TableCell>
              <TableCell width={150}> {documentType.name}</TableCell>
              <TableCell width={150}> {getSaveMethodLabel(documentType.documentSaveId)}</TableCell>
              <TableCell align="right">
                <>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi tip dokumenta">
                    <IconButton
                      onClick={() => {
                        setSelectedUnit(documentType);
                        setDocumentTypeModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni tip dokumenta">
                    <IconButton
                      onClick={() => {
                        setSelectedUnit(documentType);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Detaljni pregled tipa dokumenta"
                  >
                    <IconButton
                      onClick={() =>
                        history.push({
                          pathname: `document-types/${documentType?.id}`,
                          state: { documentType },
                        })
                      }
                    >
                      <SvgIcon fontSize="small">
                        <ArrowForwardIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentTypesTable;
