import React, { createContext, useState } from 'react';
import { clearStorageData } from '@shared/components';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [user, setUser] = useState({ name: '', username: '', roles: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [keycloak, setKeycloak] = useState(null);

  const resetData = () => {
    setUser({ name: '', username: '', roles: [] });
    setLoading(false);
    setError(null);
    setKeycloak(null);
  };

  const userHasAnyRoles = (roles) =>
    roles.filter((role) => user.roles.includes(role)).length !== 0;

  const signOut = async () => {
    clearStorageData();
    if (keycloak)
      await keycloak.logout({ redirectUri: `${window.location.origin}` });
    window.location.reload();
    resetData();
  };

  const authContext = {
    user,
    setUser,
    loading,
    setLoading,
    error,
    setError,
    keycloak,
    setKeycloak,
    signOut,
    userHasAnyRoles
  };

  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export default Context;
