import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  PageContentWrapper,
  dateFormatter,
  FolderView,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import useAuthContext from '../../hooks/useAuthContext';
import axios from '../../utils/axios';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';
import FolderModal from './FolderModal';
import StatusModal from './StatusModal';

const fields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Tip dokumenta',
    value: 'documentTypeName',
  },
  {
    label: 'Broj stranica',
    value: 'documentNoPages',
  },
  {
    label: 'Ekstenzija',
    value: 'documentExtension',
  },
  {
    label: 'Veličina fajla (bajt)',
    value: 'documentFilesize',
  },
  {
    label: 'Status',
    value: 'statusName',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    documentTypeName: 'Tip dokumenta',
    documentNoPages: 'Broj stranica',
    documentExtension: 'Ekstenzija',
    documentFilesize: 'Veličina fajla (bajt)',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
    statusName: 'Status',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  return filters[chip];
};

const Documents = () => {
  const theme = useTheme();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [triggerRerender, setRerender] = useState(false);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  // const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [selectedFolderName, setSelectedFolderName] = useState();
  const [folderModalVisible, setFolderModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const { user } = useAuthContext();

  const [documents = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`documents`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: {
              ...filters,
              screen: 'EKRAN3',
              folder: selectedFolder > 0 ? selectedFolder : undefined,
            },
            orderBy: [...sorters, '"id" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender, selectedFolder],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <Modal
        selectedFolder={selectedFolder || '0'}
        selectedFolderName={selectedFolderName || 'Svi'}
        document={selectedDocument}
        resetDocument={() => setSelectedDocument(undefined)}
        visible={documentModalVisible}
        setVisible={setDocumentModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        disabled={
          selectedDocument &&
          (selectedDocument?.status !== 0 || selectedDocument?.createdBy !== user?.username)
        }
      />
      <FolderModal
        document={selectedDocument}
        setDocument={setSelectedDocument}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        visible={folderModalVisible}
        setVisible={setFolderModalVisible}
        resetDocument={() => setSelectedDocument(undefined)}
      />
      {/* <DeleteModal
        axios={axios}
        dataLength={documents.totalCount}
        page={page}
        setPage={setPage}
        path="documents"
        theme={theme}
        visible={deleteModalVisible}
        setRerender={setRerender}
        title={`Obriši dokument ${selectedDocument?.id}`}
        promptText={`Da li ste sigurni da želite obrisati dokument "${selectedDocument?.id}"?`}
        buttonText="Obriši dokument"
        message="Uspješno izbrisan dokument"
        setVisible={setDeleteModalVisible}
        resetObject={() => setSelectedDocument(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        objectId={selectedDocument?.id}
      /> */}
      <StatusModal
        document={selectedDocument}
        setDocument={setSelectedDocument}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        visible={statusModalVisible}
        setVisible={setStatusModalVisible}
      />
      <PageHeader
        title="Dokumenti"
        extra={
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setDocumentModalVisible(true)}
              >
                <Box fontSize="small">Novi dokument</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Dokumenti
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="id"
                placeholder="Brza pretraga po ID-u"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={documents.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" DESC']}
                    filters={{ ...filters, screen: 'EKRAN3' }}
                    variant="contained"
                    routeName="documents"
                    fileName="dokumenti"
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Box display="flex">
            <Box>
              <Box m={5} minWidth={120}>
                <FolderView
                  selected={selectedFolder}
                  setSelected={setSelectedFolder}
                  setSelectedName={setSelectedFolderName}
                  theme={theme}
                  axios={axios}
                />
              </Box>
            </Box>
            <Box flex={1}>
              <Table
                loading={loading}
                error={error}
                documents={documents.nodes}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalCount={documents.totalCount}
                sorters={sorters}
                setSorters={setSorters}
                history={history}
                setDocumentModalVisible={setDocumentModalVisible}
                selectedDocument={selectedDocument}
                setStatusModalVisible={setStatusModalVisible}
                setFolderModalVisible={setFolderModalVisible}
                // setDeleteModalVisible={setDeleteModalVisible}
                setSelectedDocument={setSelectedDocument}
              />
            </Box>
          </Box>
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Documents;
