import React, { useState } from 'react';
import { Breadcrumbs, Typography, Link, AppBar, Grid, Button, Box } from '@material-ui/core';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import mime from 'mime-types';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { TabPanel, Spinner, useAPI, VerticalBoxContainer } from '@shared/components';
import PageHeader from '../../components/PageHeader';
import axios from '../../utils/axios';
import { a11yProps } from '../../utils/tabFormatter';
import useTheme from '../../hooks/useTheme';
import useAuthContext from '../../hooks/useAuthContext';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import DocumentModal from '../DocumentsAccordance/Modal';
import StatusModal from '../DocumentsAccordance/StatusModal';
import DocumentHistory from './Tabs/History';
import Attributes from './Tabs/Attributes';
import Comments from './Tabs/Comments';
import DocumentAttachments from './Tabs/DocumentAttachments';
import DocumentConsents from './Tabs/DocumentConsents';
import { AntTabs, AntTab, PageContentWrapper } from './style';

const AccordanceDetails = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const [documentAcc, setDocument] = useState({});
  const [triggerRerender, setRerender] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);

  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const { user } = useAuthContext();

  const [, , loading] = useAPI(
    () =>
      axios
        .get(`documents/${id}`, {
          params: {
            screen: 'EKRAN2',
          },
        })
        .then(({ data }) => {
          setDocument(data);
        })
        .catch((error) => {
          sendErrorAlertData(
            `${
              error?.response?.data?.details?.reason ||
              error?.response?.data?.error?.message ||
              `Došlo je do greške prilikom preuzimanja informacija o dokumentu`
            }`,
          );
          history.replace('/accordance');
        }),
    [id, triggerRerender],
  );

  const getDocumentFile = async (documentId, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${documentId}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data?.details?.reason ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      name: 'Atributi',
      component: <Attributes documentId={id} documentLoading={loading} />,
    },
    {
      name: 'Saglasnosti',
      component: (
        <DocumentConsents
          documentId={id}
          parentData={documentAcc}
          setDocument={setDocument}
          documentLoading={loading}
          setDocumentRerender={setRerender}
        />
      ),
    },
    {
      name: 'Prilozi',
      component: <DocumentAttachments documentId={id} />,
    },
    {
      name: 'Komentari',
      component: <Comments documentId={id} />,
    },
    {
      name: 'Historija',
      component: <DocumentHistory documentId={id} />,
    },
  ];

  return (
    <VerticalBoxContainer>
      <DocumentModal
        document={documentAcc}
        setDocument={setDocument}
        resetDocument={() => {}}
        visible={documentModalVisible}
        setVisible={setDocumentModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={() => {}}
        documentLoading={loading}
      />
      <StatusModal
        document={documentAcc}
        setDocument={setDocument}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={() => history.goBack()}
        visible={statusModalVisible}
        setVisible={setStatusModalVisible}
        documentLoading={loading}
        detailScreen
      />
      <PageHeader
        showBackIcon
        title={`Detalji: ${tabs[currentTab].name} dokumenta ${id}`}
        extra={
          <>
            <Grid container spacing={4}>
              {user.username === documentAcc.responsiblePersonUsername && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={loading ? <Spinner size={15} /> : <SendOutlinedIcon />}
                    disabled={loading}
                    onClick={() => setStatusModalVisible(true)}
                  >
                    <Box fontSize="small">Promjena statusa</Box>
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    downloadLoading ? <Spinner size={15} /> : <InsertDriveFileOutlinedIcon />
                  }
                  disabled={downloadLoading}
                  onClick={() => {
                    getDocumentFile(documentAcc.id, documentAcc.documentExtension);
                  }}
                >
                  <Box fontSize="small">Prikaži dokument</Box>
                </Button>
              </Grid>
              {user.username === documentAcc.responsiblePersonUsername && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading ? <Spinner size={15} /> : <EditOutlinedIcon />}
                    disabled={loading}
                    onClick={() => setDocumentModalVisible(true)}
                  >
                    <Box fontSize="small">Uredi dokument</Box>
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        }
        history={history}
      />
      <PageContentWrapper style={{ padding: `80px 0 0 0` }}>
        <AppBar
          position="static"
          style={{ background: `${theme.palette.background.default}` }}
          elevation={0}
        >
          <AntTabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <AntTab key={tab.name} label={tab.name} {...a11yProps(index)} />
            ))}
          </AntTabs>
        </AppBar>
        <Breadcrumbs
          style={{
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
          }}
        >
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/accordance" component={RouterLink}>
            Usaglašavanje dokumenata
          </Link>
          <Typography variant="body1" color="textPrimary">
            {tabs[currentTab].extendedName ? tabs[currentTab].extendedName : tabs[currentTab].name}
          </Typography>
        </Breadcrumbs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.name}
            value={currentTab}
            index={index}
            dir={theme.direction}
            theme={theme}
          >
            {tab.component}
          </TabPanel>
        ))}
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default AccordanceDetails;
