import React from 'react';
import {
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const AttributeGroupsTable = ({
  attributeGroups,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedAttributeGroup,
  setModalVisible,
  setDeleteModalVisible,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={80}>
            <Tooltip enterDelay={300} title="Sortiraj prema rednom broju" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('ordNumber', sorters)}
                direction={getSortDirection('ordNumber', sorters)}
                onClick={() => handleSortChange('ordNumber', sorters, setSorters)}
              >
                Redni broj
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={80}>
            <Tooltip enterDelay={300} title="Sortiraj prema šifri" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('code', sorters)}
                direction={getSortDirection('code', sorters)}
                onClick={() => handleSortChange('code', sorters, setSorters)}
              >
                Šifra
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={200}>
            <Tooltip enterDelay={300} title="Sortiraj prema opisu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('description', sorters)}
                direction={getSortDirection('description', sorters)}
                onClick={() => handleSortChange('description', sorters, setSorters)}
              >
                Opis
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !attributeGroups ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : attributeGroups?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti grupa atributa</Typography>
            </TableCell>
          </TableRow>
        ) : (
          attributeGroups?.map((attributeGroup) => (
            <TableRow key={attributeGroup.id}>
              <TableCell width={80}> {attributeGroup.ordNumber}</TableCell>
              <TableCell width={80}> {attributeGroup.code}</TableCell>
              <TableCell width={140}> {attributeGroup.name}</TableCell>
              <TableCell width={200}> {attributeGroup.description}</TableCell>
              <TableCell width={50} align="right">
                <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi grupu atributa">
                  <IconButton
                    onClick={() => {
                      setSelectedAttributeGroup(attributeGroup);
                      setModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <EditOutlinedIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip enterDelay={300} placement="top-end" arrow title="Izbriši grupu atributa">
                  <IconButton
                    onClick={() => {
                      setSelectedAttributeGroup(attributeGroup);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <DeleteOutlined />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AttributeGroupsTable;
