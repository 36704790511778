import { makeStyles } from '@material-ui/core/styles';

export const useSwitchStyles = makeStyles({
  switchBase: {
    '&$checked': {
      color: (props) => props.checkedColor || props.theme.palette.success.main,
    },
    '&:not($checked)': {
      color: (props) => props.uncheckedColor || props.theme.palette.error.main,
    },
    '&$checked + $track': {
      backgroundColor: (props) =>
        props.checkedColor || props.theme.palette.secondary.main,
    },
  },
  checked: () => {},
  track: {
    backgroundColor: (props) =>
      props.uncheckedColor || props.theme.palette.error.main,
  },
});

export const useFormControlLabelStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: (props) =>
      props.backgroundColor || props.theme.palette.background.dark,
  },
});
