import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Bell as BellIcon, Info as InfoIcon, Mail as MailIcon } from 'react-feather';
import { Avatar, Popover, ListItem, Badge, ErrorMessage } from './style';
import useNotificationContext from '../../../hooks/useNotificationContext';

const iconsMap = {
  info: InfoIcon,
  action: MailIcon,
};

function Notifications() {
  const { error, setRerender, numberOfDocumentMessages, numberOfInfoMessages } =
    useNotificationContext();

  const notifications = [
    {
      id: 1,
      title: 'Usaglašavanje',
      description: `Broj novih poruka: ${numberOfDocumentMessages}`,
      notificationNumber: numberOfDocumentMessages,
      type: 'action',
      path: '/messages-documents',
    },
    {
      id: 2,
      title: 'Informacije',
      description: `Broj novih poruka: ${numberOfInfoMessages}`,
      type: 'info',
      notificationNumber: numberOfInfoMessages,
      path: 'messages-informations',
    },
  ];
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setRerender((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
    setRerender((prevState) => !prevState);
  };

  return (
    <>
      <Tooltip title="Poruke">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge
            overlap="rectangular"
            badgeContent={numberOfDocumentMessages + numberOfInfoMessages}
            color="secondary"
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        {error ? (
          <Box
            height={150}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
          >
            <ErrorOutlineRoundedIcon />
            <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
          </Box>
        ) : // :loading ? (
        //   <Box
        //     height={150}
        //     display="flex"
        //     justifyContent="center"
        //     alignItems="center"
        //     minWidth="100%"
        //   >
        //     <Spinner size={20} />
        //   </Box>

        // )
        notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              Nema novih poruka
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];
                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to={notification.path}
                    onClick={handleClose}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
