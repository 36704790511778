
// default range is green-blue (cold shades)
const scaleValueToRange = (
  x, 
  newMin = 80, 
  newMax = 250, 
  min = 0, 
  max = 360) => {
    return ((x-min)/(max-min))*(newMax - newMin) + newMin
  }

  const roundToNearest = (x, y = 5) => (Math.round(x/y))*y

// to appear "stronger", increase s (saturation) parameter
export default (str, s = 38, l = 75) => {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let h = Math.abs(hash % 360);
    h = roundToNearest(scaleValueToRange(h), 10);
    return `hsl(${h}, ${s}%, ${l}%)`;
  }