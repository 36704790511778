import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const AutocompleteLabel = styled.span`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  font-size: ${({ theme }) => theme.spacing(4)}px;
`;

export const chipStyle = makeStyles(() => ({
  root: {
    borderRadius: 0,
  },
}));
