import React, { useState } from 'react';
import { exportJSONToCSVFile, Spinner, dateFormatter } from '@shared/components';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import useGlobalAlertContext from 'hooks/useGlobalAlertContext';
import axios from 'utils/axios';

// Niz koji ima polja label i value
//
// fields = [
//   {
//     label: 'Datum dospijeća',
//     type: 'date',
//     value: 'maturityDate'
//   },
//   {
//     label: 'Godina',
//     value: 'year'
//   },
//   {
//     label: 'Broj',
//     value: 'numberId'
//   },
//   {
//     label: 'Vrsta',
//     value: 'kufTypeName'
//   },
//   {
//     label: 'Status',
//     value: 'kufStatusName'
//   },
//   {
//     label: 'Valuta',
//     value: 'currencyName'
//   },
// ]

// <Grid item>
//  <ExcelExportButton
//    fields={fields}
//    sorters={sorters}
//    filters={filters}
//    routeName="admin-invoices"
//    fileName="admin-fakture"
//  />;
// </Grid>

const ExcelExportButton = ({
  totalCount = 0,
  limit = 1000,
  filters,
  sorters,
  routeName,
  fileName,
  fields = [],
  variant = 'contained',
  sortById = false,
  successAlertMessage,
  errorAlertMessage,
  screenName,
  ...rest
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const getLimit = totalCount > limit;

  const exportToCSV = async () => {
    setDownloadLoading(true);
    const conditions = screenName
      ? { ...filters, screen: screenName }
      : { ...filters };
    try {
      const { data } = await axios.get(routeName, {
        params: {
          conditions,
          orderBy: sortById ? [...sorters, 'ID DESC'] : sorters,
        },
      });
      exportJSONToCSVFile(data?.nodes, fileName || routeName, {
        fields: fields.map((field) => ({
          label: field.label,
          value: (row) =>
            field.type === 'date'
              ? dateFormatter(row[field.value])
              : field.type === 'dateTime'
              ? dateFormatter(row[field.value], true)
              : row[field.value],
        })),
      });
      sendSuccessAlertData(
        successAlertMessage || 'Uspješno kreirana datoteka za preuzimanje',
      );
    } catch (err) {
      sendErrorAlertData(
        errorAlertMessage ||
          'Došlo je do greške prilikom kreiranja datoteke za preuzimanje',
      );
    }
    setDownloadLoading(false);
  };

  return (
    <Tooltip
      enterDelay={300}
      placement="top-end"
      arrow
      title={
        <>
          <Typography gutterBottom color="initial">
            Upozorenje
          </Typography>
          <Typography display="block" variant="caption" color="initial">
            Pretraživanjem pronadeno {totalCount} redaka, maksimalan broj
            dozvoljenih redaka je {limit}, ograničite broj redaka kriterijem
            pretraživanja.
          </Typography>
        </>
      }
      disableHoverListener={!getLimit}
      disableFocusListener={!getLimit}
      disableTouchListener
    >
      <span>
        <Button
          variant={variant}
          color="primary"
          startIcon={
            downloadLoading ? (
              <Spinner color="secondary" size={14} />
            ) : (
              <CloudDownloadOutlinedIcon fontSize="small" />
            )
          }
          disabled={downloadLoading || getLimit}
          onClick={exportToCSV}
          {...rest}
        >
          <Box fontSize="small">Export</Box>
        </Button>
      </span>
    </Tooltip>
  );
};

export default ExcelExportButton;
