import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  IconButton,
  SvgIcon,
  TextField,
  CardHeader,
  Divider,
  Tooltip,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { FilterChip, InputModal } from '@shared/components';
import useTheme from '../../../../../hooks/useTheme';
import {
  handleFilterChange,
  handleChipDelete,
} from '../../../../../utils/filterHelpers';
import getChipValue from '../../../../../utils/getChipValue';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  setFilters,
  setPage,
  getLabelText,
}) => {
  const [filterValues, setFilterValues] = useState({});
  const theme = useTheme();

  useEffect(() => setFilterValues(currentValues), [currentValues]);

  const handleClose = () => setVisible(false);
  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  return (
    <InputModal
      theme={theme}
      filterTitle="Pretraga priloga dokumenta"
      visible={visible}
      onSubmit={onSubmit}
      handleClose={handleClose}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box pb={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(
                    chip,
                    filterValues,
                  )}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Card style={{ minWidth: '700px' }}>
          <CardHeader
            title="Podaci o tipu dokumenta"
            titleTypographyProps={{ variant: 'subtitle2' }}
            action={
              <Tooltip
                enterDelay={300}
                placement="top-end"
                arrow
                title="Ukloni filtere"
              >
                <IconButton
                  onClick={() => {
                    setFilterValues({});
                  }}
                >
                  <SvgIcon fontSize="small">
                    <ClearAllIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            }
          />
          <Divider />
          <Box
            padding={`${theme.spacing(5)}px`}
            width="100%"
            boxSizing="border-box"
          >
            <Grid
              spacing={6}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={filterValues.userCreated || ''}
                  size="small"
                  placeholder="Unesite korisnika za pretragu"
                  label="Kreirao"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      userCreated: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={filterValues.description || ''}
                  size="small"
                  placeholder="Unesite opis za pretragu"
                  label="Opis"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      description: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Datum kreiranja"
                  name="timeCreated"
                  type="date"
                  fullWidth
                  value={filterValues.timeCreated || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      timeCreated: value?.length === 0 ? undefined : value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </InputModal>
  );
};

export default Filter;
