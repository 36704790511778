import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  PageContentWrapper,
  DeleteModal,
} from '@shared/components';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import useGlobalAlertContext from '../../../../hooks/useGlobalAlertContext';
import useTheme from '../../../../hooks/useTheme';
import axios from '../../../../utils/axios';
import getChipValue from '../../../../utils/getChipValue';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import Modal from './Modal';
import List from './Table';
import Filter from './Filter';

const tableColumns = [
  {
    name: 'Redni broj',
    value: 'ordNum',
    align: 'left',
    width: 150,
    required: true,
    placeholder: 'rednom broju',
  },
  {
    name: 'Tip dokumenta',
    value: 'documentTypeName',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'tipu dokumenta',
  },
  {
    name: 'Šifra tipa dokumenta',
    value: 'pCode',
    align: 'left',
    required: true,
    width: 100,
    placeholder: 'šifri tipa dokumenta',
  },
  {
    name: '',
    value: '',
    align: 'left',
    width: 50,
  },
];

const exportFields = [
  {
    label: 'Redni broj',
    value: 'ordNum',
  },
  {
    label: 'Tip dokumenta',
    value: 'documentTypeName',
  },
  {
    label: 'Šifra tipa dokumenta',
    value: 'pCode',
  },
];

const getLabelText = (value) => {
  const map = {
    ordNum: 'Redni broj',
    documentTypeName: 'Tip dokumenta',
    pCode: 'Šifra tipa dokumenta'
  };
  return map[value];
};

const DocumentTypes = ({ username }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(undefined);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const [triggerRerender, setRerender] = useState(false);

  const [documentTypes = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`scan-users/document-types`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: {...filters, username },
            orderBy: [...sorters, '"id" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
      />
      <Modal
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedDocumentType={selectedDocumentType}
        setSelectedDocumentType={setSelectedDocumentType}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        resetSelectedDocument={() => {
          setSelectedDocumentType(undefined);
        }}
        setRerender={setRerender}
        username={username}
      />
      <DeleteModal
        dataLength={documentTypes.totalCount}
        page={page}
        setPage={setPage}
        path="scan-users/document-types"
        visible={deleteModalVisible}
        setRerender={setRerender}
        title={`Obriši tip dokumenta: ${selectedDocumentType?.pCode}`}
        promptText={`Da li ste sigurni da želite obrisati tip dokumenta ${selectedDocumentType?.documentTypeName}?`}
        buttonText="Obriši tip dokumenta"
        message="Uspješno obrisan tip dokumenta"
        setVisible={setDeleteModalVisible}
        resetObject={() => setSelectedDocumentType(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        objectId={selectedDocumentType?.id}
        axios={axios}
        theme={theme}
      />
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="documentTypeName"
                placeholder="Brza pretraga po tipu dokumenta"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={documentTypes.totalCount}
                    sortById={false}
                    variant="outlined"
                    fields={exportFields}
                    sorters={[...sorters, '"id" asc']}
                    filters={{...filters, username }}
                    routeName="scan-users/document-types"
                    fileName={`${username}-tipovi-dokumenata`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setModalVisible(true)}
                  >
                    <Box fontSize="small">Novi tip dokumenta</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}

          <List
            loading={loading}
            error={error}
            documentTypes={documentTypes.nodes}
            tableColumns={tableColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documentTypes.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            setDeleteModalVisible={setDeleteModalVisible}
            setSelectedDocumentType={setSelectedDocumentType}
            setModalVisible={setModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentTypes;
