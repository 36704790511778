import React from 'react';
import {
  IconButton,
  SvgIcon,
  Grid,
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '../Modal';
import Spinner from '../Spinner';

const InputModal = ({
  object,
  createTitle,
  updateTitle,
  filterTitle,
  filterSubmitText = 'Primijeni filtere',
  createButtonText,
  updateButtonText,
  visible,
  onSubmit,
  handleSubmit,
  handleClose,
  modalWidth,
  loading,
  children,
  filter,
  theme = {},
  disabledEdit = false,
  disabledEditTooltip = 'Nemate mogućnost uređivanja',
  disabledEditTitle = 'Nemate mogućnost uređivanja',
  footerInfo,
}) => {
  const FilterTitle = () => (
    <Box display="flex" alignItems="center">
      <SvgIcon style={{ color: '#fff', marginRight: `${theme.spacing(3)}px` }}>
        <SearchIcon />
      </SvgIcon>
      <Typography variant="subtitle1">{filterTitle}</Typography>
    </Box>
  );

  const ModalButton = ({ startIcon, text, onClick, disabled }) => (
    <Tooltip enterDelay={300} title={disabled ? disabledEditTooltip : ''} arrow>
      <span>
        <Button
          variant="contained"
          color="primary"
          startIcon={startIcon}
          onClick={onClick}
          type="submit"
          disabled={disabled || false}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );

  const InputFooter = ({ children: button }) => (
    <Box
      style={{
        backgroundColor: filter ? theme.palette.background.default : theme.palette.background.dark,
      }}
    >
      <Divider />
      <Box padding={`${theme.spacing(5)}px`}>
        <Grid container spacing={5} justifyContent="flex-end">
          <Grid item style={{ flex: '1' }}>
            {footerInfo || ''}
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleClose} size="large">
              Odustani
            </Button>
          </Grid>
          <Grid item>{button}</Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={visible}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          handleClose();
        }
      }}
      showHeader
      cardProps={modalWidth && { style: { width: modalWidth } }}
      cardHeaderProps={{
        title: filter ? <FilterTitle /> : object ? updateTitle : createTitle,
        action: (
          <IconButton onClick={handleClose}>
            <SvgIcon fontSize="small" style={{ color: '#fff' }}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        ),
      }}
    >
      {filter ? (
        <>
          <Box maxHeight="85vh" overflow="auto">
            {children}
          </Box>
          <InputFooter>
            <ModalButton
              startIcon={loading ? <Spinner size={16} /> : <DoneIcon />}
              disabled={loading || disabledEdit}
              onClick={onSubmit}
              text={filterSubmitText}
            />
          </InputFooter>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box maxHeight="85vh" overflow="auto">
              {children}
            </Box>
            <InputFooter>
              <ModalButton
                startIcon={loading ? <Spinner size={16} /> : <DoneIcon />}
                disabled={loading || disabledEdit}
                text={
                  disabledEdit ? disabledEditTitle : object ? updateButtonText : createButtonText
                }
              />
            </InputFooter>
          </form>
        </>
      )}
    </Modal>
  );
};

export default InputModal;
