import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  SvgIcon,
  IconButton,
  TablePagination,
  Box,
  Typography,
} from '@material-ui/core';
import mime from 'mime-types';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
  TableSkeleton,
  Spinner,
  dateFormatterTime,
  dateFormatterDate,
  StatusLabel,
} from '@shared/components';
import { ErrorMessage } from './style';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import axios from '../../../utils/axios';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';

const mainTableColumns = [
  {
    name: 'ID',
    value: 'id',
    align: 'left',
    width: '120',
    sortPlaceholder: 'id-u',
  },
  {
    name: 'Dokument',
    value: 'document',
    align: 'left',
    width: '120',
    sortPlaceholder: 'dokumentu',
  },
  {
    name: 'Tip dokumenta',
    value: 'documentTypeName',
    align: 'left',
    width: '120',
    sortPlaceholder: 'tipu dokumenta',
  },
  {
    name: 'Status dokumenta',
    value: 'documentStatusName',
    align: 'left',
    width: '120',
    sortPlaceholder: 'statusu dokumenta',
  },
  {
    name: 'Atribut (Metapodataka)',
    value: 'attributeName',
    align: 'left',
    width: '120',
    sortPlaceholder: 'atributu',
  },
  {
    name: 'Vrijednost',
    value: 'valueDisplayed',
    align: 'left',
    width: '120',
    sortPlaceholder: 'vrijednosti',
  },
  {
    name: 'Datum kreiranja',
    value: 'dateCreated',
    align: 'left',
    width: '120',
    sortPlaceholder: 'datumu kreiranja',
  },
  {
    name: 'Korisnik kreirao',
    value: 'createdBy',
    align: 'left',
    width: '120',
    sortPlaceholder: 'korisniku',
  },
];

const DocumentAttributeList = ({
  documentAttributes,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  sorters,
  setSorters,
  loading,
  selectedDocumentAttribute,
  setSelectedDocumentAtributte,
  setDocumentAttributeModalVisible,
}) => {
  const getStatusLabel = (status, statusName) => {
    if (status !== 0 && !status) return '';
    const map = {
      0: {
        color: 'info',
      },
      1: {
        color: 'neutral',
      },
      2: {
        color: 'secondary',
      },
      3: {
        color: 'primary',
      },
      4: {
        color: 'success',
      },
      5: {
        color: 'error',
      },
      6: {
        color: 'warning',
      },
      7: {
        color: 'info',
      },
    };

    const { color } = map[status] || 'neutral';

    return <StatusLabel color={color}>{statusName}</StatusLabel>;
  };
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendErrorAlertData } = useGlobalAlertContext();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data?.details?.reason ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocumentAtributte(undefined);
    }
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {mainTableColumns.map((column) => (
            <TableCell key={column.value} align={column.align} width={column.width}>
              <Tooltip enterDelay={300} title={`Sortiraj prema ${column.sortPlaceholder}`}>
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={120} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documentAttributes ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ padding: '0' }} height={rowsPerPage}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documentAttributes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan atribut</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documentAttributes?.map((attribute) => (
            <TableRow key={attribute.id}>
              <TableCell width={120}> {attribute.id}</TableCell>
              <TableCell width={120}> {attribute.document}</TableCell>
              <TableCell width={120}> {attribute.documentTypeName}</TableCell>
              <TableCell width={120}>
                {getStatusLabel(attribute.documentStatus, attribute.documentStatusName)}
              </TableCell>
              <TableCell width={120}> {attribute.attributeName}</TableCell>
              <TableCell width={120}> {attribute.valueDisplayed}</TableCell>
              <TableCell width={120}>
                {dateFormatterDate(attribute.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(attribute.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={120}> {attribute.createdBy}</TableCell>
              <TableCell align="right">
                <>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      downloadLoading && attribute?.id === selectedDocumentAttribute?.id
                        ? ''
                        : 'Prikaži dokument'
                    }
                  >
                    {downloadLoading && attribute?.id === selectedDocumentAttribute?.id ? (
                      <span>
                        <IconButton size="small" disabled>
                          <Box pr={3}>
                            <Spinner color="primary" size={20} />
                          </Box>
                        </IconButton>
                      </span>
                    ) : (
                      <span>
                        <IconButton
                          disabled={downloadLoading}
                          onClick={() => {
                            setSelectedDocumentAtributte(attribute);
                            getDocumentFile(attribute.document, attribute.documentExtension);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <InsertDriveFileOutlinedIcon />
                          </SvgIcon>
                        </IconButton>
                      </span>
                    )}
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Pregled dokumenta">
                    <IconButton
                      onClick={() => {
                        setSelectedDocumentAtributte(attribute);
                        setDocumentAttributeModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <FindInPageOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentAttributeList;
