import React, { createContext, useState } from 'react';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [navbarVisible, setNavbarVisible] = useState(true);

  const navbarContext = {
    setNavbarVisible,
    navbarVisible,
  };

  return <Context.Provider value={navbarContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export default Context;
