import React from 'react';
import { PageHeader as SharedPageHeader } from '@shared/components';
import useNavbarContext from '../../hooks/useNavbarContext';

const PageHeader = ({ ...rest }) => {
  const { navbarVisible } = useNavbarContext();

  return (
    <SharedPageHeader navbarVisibility={navbarVisible} {...rest} />
  );
};

export default PageHeader;
