import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  CardHeader,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { FilterChip, InputModal } from '@shared/components';
import { handleFilterChange, handleChipDelete } from '../../../utils/filterHelpers';
import useTheme from '../../../hooks/useTheme';
import { ContentBox } from './style';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga foldera"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="35%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Box>
          <Card elevation={4}>
            <CardHeader
              titleTypographyProps={{ variant: 'subtitle2' }}
              title="Podaci o folderu"
              action={
                <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                  <IconButton
                    onClick={() => {
                      setFilterValues({});
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <ClearAllIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              }
            />
            <Divider />
            <ContentBox elevation={5}>
              <Grid
                container
                direction="row"
                spacing={5}
                style={{ maxHeight: 500, overflowY: 'auto' }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ID"
                    name="id"
                    type="number"
                    placeholder="Unesite id za pretragu"
                    value={filterValues.id || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        id: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Naziv"
                    name="name"
                    placeholder="Unesite naziv za pretragu"
                    value={filterValues.name || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        name: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Opis"
                    name="description"
                    placeholder="Unesite opis za pretragu"
                    value={filterValues.description || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        description: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Datum kreiranja"
                    name="dateCreated"
                    type="date"
                    fullWidth
                    value={filterValues.dateCreated || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        dateCreated: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Korisnik kreirao"
                    name="createdBy"
                    placeholder="Unesite naziv korisnika za pretragu"
                    value={filterValues.createdBy || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        createdBy: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </ContentBox>
          </Card>
        </Box>
      </Box>
    </InputModal>
  );
};

export default Filter;
