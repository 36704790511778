import React, { useState } from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardHeader,
  Box,
  Divider,
  Grid,
  Button,
  Backdrop,
} from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
  DeleteModal,
  useAPIAsync,
  Spinner,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import useTheme from '../../hooks/useTheme';
import axios from '../../utils/axios';
import { handleChipDelete } from '../../utils/filterHelpers';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import { handleSortChange, getSortDirection } from '../../utils/sortHelpers';

import DocumentModal from '../GroupAccordances/Modal';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';

const fields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Radnik',
    value: 'workerFullName',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
  {
    label: 'Važi',
    value: 'valid',
  },
];

const getLabelText = (value) => {
  const type = {
    id: 'ID',
    workerFullName: 'Radnik',
    valid: 'Važi',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
  };
  return type[value];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  else if (chip === 'valid')
    return filters[chip] === 'D' ? 'DA' : filters[chip] === 'N' ? 'NE' : 'U TOKU';
  return filters[chip];
};

const DocumentDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [triggerRerender, setRerender] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [groupAccModalVisible, setGroupAccModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);
  const {
    data: groupAccordance,
    setData: setGroupAccordance,
    loading: apiLoading,
  } = useAPIAsync(axios, `group-accordances/${id}`);
  const [selectedWorker, setSelectedWorker] = useState();

  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [workers = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`group-accordances-details`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, accordanceId: id },
            orderBy: [...sorters, '"id" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  if (apiLoading)
    return (
      <Backdrop
        open
        style={{
          zIndex: theme.zIndex.drawer - 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Spinner size={50} />
      </Backdrop>
    );
  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
      />
      <DocumentModal
        groupAccordance={groupAccordance}
        setGroupAccordance={setGroupAccordance}
        resetGroupAccordance={() => {}}
        visible={groupAccModalVisible}
        setVisible={setGroupAccModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={() => {}}
      />
      <Modal
        groupAccordance={groupAccordance}
        worker={selectedWorker}
        setSelectedWorker={setSelectedWorker}
        visible={modalVisible}
        setVisible={setModalVisible}
        setRerender={setRerender}
        resetWorker={() => setSelectedWorker(null)}
        parentData={groupAccordance}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <DeleteModal
        axios={axios}
        dataLength={workers.totalCount}
        page={page}
        setPage={setPage}
        path="group-accordance-details"
        visible={deleteModalVisible}
        setRerender={setRerender}
        title={`Obriši radnika grupe ${selectedWorker?.id}`}
        promptText={`Da li ste sigurni da želite obrisati radnika "${selectedWorker?.workerFullName}" iz grupe ${groupAccordance?.id}?`}
        buttonText="Obriši radnika grupe"
        message="Uspješno izbrisan radnik grupe"
        setVisible={setDeleteModalVisible}
        resetObject={() => setSelectedWorker(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        objectId={selectedWorker?.id}
        theme={theme}
      />
      <PageHeader
        title={`Grupa saglasnosti ${groupAccordance?.name || ''}`}
        subtitle={`Detalji grupe saglasnosti ${groupAccordance?.id || ''}`}
        sub
        showBackIcon
        extra={
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditOutlinedIcon />}
            onClick={() => setGroupAccModalVisible(true)}
          >
            <Box fontSize="small">Uredi grupu saglasnosti</Box>
          </Button>
        }
        history={history}
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/group-accordances" component={RouterLink}>
            Šifarnik: Grupa saglasnosti
          </Link>
          <Typography variant="body1" color="textPrimary">
            Detalji grupe saglasnosti
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="id"
                placeholder="Brza pretraga po ID-u radnika grupe"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={workers.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" asc']}
                    filters={{ ...filters, accordanceId: groupAccordance?.id }}
                    routeName="group-accordances-details"
                    variant="outlined"
                    fileName={`radnici-grupa-saglasnosti-${groupAccordance?.id}`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setModalVisible(true)}
                  >
                    <Box fontSize="small">Novi radnik za grupu</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            workers={workers.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={workers.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            setSelectedWorker={setSelectedWorker}
            setEditModalVisible={setModalVisible}
            setDeleteModalVisible={setDeleteModalVisible}
            setRerender={setRerender}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentDetails;
