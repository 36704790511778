import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { ErrorMessage } from './style';
import { getSortDirection, handleSortChange } from '../../../../utils/sortHelpers';

const AttributeList = ({
  loading,
  error,
  attributes,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  sorters,
  setSorters,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={30}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={120}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku">
              <TableSortLabel
                active={!!getSortDirection('user', sorters)}
                direction={getSortDirection('user', sorters)}
                onClick={() => handleSortChange('user', sorters, setSorters)}
              >
                Korisnik
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={20}>
            <Tooltip enterDelay={300} title="Sortiraj prema vrijednosti">
              <TableSortLabel
                active={!!getSortDirection('displayedValue', sorters)}
                direction={getSortDirection('displayedValue', sorters)}
                onClick={() => handleSortChange('displayedValue', sorters, setSorters)}
              >
                Vrijednost
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={120}>
            <Tooltip enterDelay={300} title="Sortiraj prema datumu i vremenu">
              <TableSortLabel
                active={!!getSortDirection('date', sorters)}
                direction={getSortDirection('date', sorters)}
                onClick={() => handleSortChange('date', sorters, setSorters)}
              >
                Datum i vrijeme
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={20}>
            <Tooltip enterDelay={300} title="Sortiraj prema akciji">
              <TableSortLabel
                active={!!getSortDirection('action', sorters)}
                direction={getSortDirection('action', sorters)}
                onClick={() => handleSortChange('action', sorters, setSorters)}
              >
                Akcija
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 37}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !attributes ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ padding: '0' }}>
              <TableSkeleton size={rowsPerPage} cellHeight={30} />
            </TableCell>
          </TableRow>
        ) : attributes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={75.33 * rowsPerPage}>
              <Typography>Nije pronađena niti jedna promjena</Typography>
            </TableCell>
          </TableRow>
        ) : (
          attributes?.map((attribute) => (
            <TableRow key={attribute.id} style={{ height: '3rem' }}>
              <TableCell width={30}>{attribute.name}</TableCell>
              <TableCell width={30}>{attribute.user}</TableCell>
              <TableCell width={120}>{attribute.displayedValue}</TableCell>
              <TableCell width={120}>
                {dateFormatterDate(attribute.date)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(attribute.date)}
                </Typography>
              </TableCell>
              <TableCell width={120}>{attribute.action}</TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AttributeList;
