import React, { useRef } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import { TextField } from './style';

const FormFileUpload = ({
  fullWidth,
  // theme,
  placeholder,
  selectedFileName,
  setSelectedFileName,
  setSelectedFile,
  setIsFilePicked,
  isFilePicked,
}) => {
  const fileInput = useRef();

  const changeHandler = (event) => {
    setSelectedFileName(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const removeFileHandler = () => {
    setSelectedFileName('');
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const handleFileClick = () => {
    if (!isFilePicked) fileInput.current.click();
  };

  return (
    <TextField
      fullWidth={fullWidth}
      placeholder={placeholder}
      value={selectedFileName}
      onClick={handleFileClick}
      onChange={(e) => setSelectedFileName(e.target.value)}
      variant="outlined"
      InputProps={{
        readOnly: !isFilePicked,
        endAdornment: (
          <InputAdornment disablePointerEvents={!isFilePicked} position='end'>
            {isFilePicked ? (
              <IconButton
                onClick={removeFileHandler}
                color="primary"
                aria-label="remove pdf file"
                component="span"
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <label htmlFor="file-upload-btn">
                <input
                  ref={fileInput}
                  id="file-upload-btn"
                  type="file"
                  onChange={changeHandler}
                  hidden
                />
                <IconButton aria-label="upload pdf file" component="span" color="primary">
                  <PublishIcon />
                </IconButton>
              </label>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormFileUpload;
