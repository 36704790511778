import * as yup from 'yup';

export const groupAccordanceSchema = yup.object().shape({
  name: yup
    .string()
    .max(200, 'Polje "Naziv" mora imati manje od 200 karaktera')
    .required('Polje "Naziv" je obavezno'),
  description: yup.string().max(4000, 'Polje "Opis" mora imati manje od 4000 karaktera'),
});

export const groupAccordanceWorkerSchema = yup.object().shape({
  worker: yup.object().typeError('Potrebno je izabrati radnika').required('Potrebno je izabrati radnika'),
  valid: yup.string().oneOf(['D', 'N'], 'Morate odabrati vrijednosti DA, NE ili U TOKU.'),
});

export const groupAccordanceWorkerSchemaUpdate = yup.object().shape({
  valid: yup.string().oneOf(['D', 'N'], 'Morate odabrati vrijednosti DA, NE ili U TOKU.'),
});
