import styled from 'styled-components';
import {
  Popover as MuiPopover,
  Avatar as MuiAvatar,
  ListItem as MuiListItem,
  Badge as MuiBadge,
  alpha,
} from '@material-ui/core';

export const Avatar = styled(MuiAvatar)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Badge = styled(MuiBadge)`
  .MuiBadge-colorSecondary {
    color: white;
  }
`;

export const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    width: 320px;
  }
`;

export const ListItem = styled(MuiListItem)`
  :hover {
    background-color: ${alpha('#003459', 0.05)};
  }
`;

export const ErrorMessage = styled.span`
  font-size: 1.5rem;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;
