import React, { useState } from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardHeader,
  Box,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

import {
  VerticalBoxContainer,
  PageContentWrapper,
  QuickSearch,
  FilterChip,
  useAPI,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import getChipValue from '../../utils/getChipValue';
import ExcelExportButton from '../../components/ExcelExportButton';
import { handleChipDelete } from '../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../utils/sortHelpers';
import axios from '../../utils/axios';
import useTheme from '../../hooks/useTheme';
import List from './Table';
import Filter from './Filter';

const tableColumns = [
  {
    name: 'Username',
    value: 'username',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'korisničkom imenu',
  },
  {
    name: 'Postavka skenera',
    value: 'scanOptionName',
    align: 'left',
    required: true,
    width: 200,
    placeholder: 'postavci skenera',
  },
];

const exportFields = [
  {
    label: 'Username',
    value: 'username',
  },
  {
    label: 'Postavka skenera',
    value: 'scanOptionName',
  },
];

const getLabelText = (value) => {
  const map = {
    username: 'Korisničko ime',
    scanOptionName: 'Postavka skenera',
  };
  return map[value];
};

const ScanUsers = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);

  const [users = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`scan-users`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: filters,
            orderBy: [...sorters, '"username" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  const theme = useTheme();
  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
      />
      <PageHeader title="Korisnici za skeniranje" />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Korisnici za skeniranje
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="username"
                placeholder="Brza pretraga po korisničkom imenu"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={users?.totalCount}
                    sortById={false}
                    fields={exportFields}
                    sorters={[...sorters, '"username" asc']}
                    filters={{ ...filters }}
                    routeName="scan-users"
                    fileName="korisnici-za-skeniranje"
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <List
            loading={loading}
            error={error}
            users={users.nodes}
            tableColumns={tableColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={users.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default ScanUsers;
