import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import { TableSkeleton } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const AttributesTable = ({
  loading,
  error,
  attributes,
  setAttribute,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  sorters,
  setSorters,
  setModalVisible,
  setDeleteModalVisible,
  history,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={100}>
            <Tooltip enterDelay={300} title="Sortiraj prema šifri" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('code', sorters)}
                direction={getSortDirection('code', sorters)}
                onClick={() => handleSortChange('code', sorters, setSorters)}
              >
                Šifra
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip enterDelay={300} title="Sortiraj prema tipu atributa" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('attributeTypeName', sorters)}
                direction={getSortDirection('attributeTypeName', sorters)}
                onClick={() => handleSortChange('attributeTypeName', sorters, setSorters)}
              >
                Tip atributa
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !attributes ? (
          <TableRow>
            <TableCell
              align="center"
              colSpan="100%"
              style={{ paddingTop: '0' }}
              height={rowsPerPage}
            >
              <TableSkeleton size={rowsPerPage} cellHeight={50} />
            </TableCell>
          </TableRow>
        ) : attributes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan atribut</Typography>
            </TableCell>
          </TableRow>
        ) : (
          attributes?.map((attribute) => (
            <TableRow key={attribute.id}>
              <TableCell width={100}> {attribute.code}</TableCell>
              <TableCell width={140}> {attribute.name}</TableCell>
              <TableCell width={100}> {attribute.attributeTypeName}</TableCell>
              <TableCell width={100} align="right">
                <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi atribut">
                  <IconButton
                    onClick={() => {
                      setAttribute(attribute);
                      setModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <EditOutlinedIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip enterDelay={300} placement="top-end" arrow title="Izbriši atribut">
                  <IconButton
                    onClick={() => {
                      setAttribute(attribute);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <DeleteOutlined />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip enterDelay={300} placement="top-end" arrow title="Detalji">
                  <IconButton
                    onClick={() => {
                      history.push({
                        pathname: `/attributes/${attribute.id}`,
                        state: { attribute },
                      });
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <ArrowForwardIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AttributesTable;
