import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, IconButton, SvgIcon } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Container } from './style';

const PageHeader = ({ title, subtitle, extra, showBackIcon, ...rest }) => {
  const history = useHistory();
  return (
    <Container {...rest}>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          {showBackIcon && (
            <IconButton onClick={() => history.goBack()}>
              <SvgIcon>
                <ArrowBackRoundedIcon />
              </SvgIcon>
            </IconButton>
          )}
          <Box ml={showBackIcon && 4}>
            <Typography variant="h3" style={{ marginBottom: '3px' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="h5" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>{extra}</Box>
      </Box>
    </Container>
  );
};

export default PageHeader;
