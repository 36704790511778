import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { InputModal, FormSelect, useAPI } from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const DocumentTypeModal = ({
  documentTypeDetail,
  setDocumentTypeDetail,
  visible,
  setVisible,
  sendSuccessAlertData,
  resetDocumentTypeDetail,
  setRerender,
  sendErrorAlertData,
  parentData,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm();

  const [attributeValues, setAttributeValues] = useState({
    attribute: parentData?.attribute,
    label: parentData?.label,
    attributeCode: parentData?.attributeCode,
  });

  const [attributes = [], attributesError, attributesLoading] = useAPI(
    () =>
      axios
        .get(`attributes`, {
          params: {
            conditions: {},
            orderBy: [],
          },
        })
        .then(({ data }) => data),
    [],
  );

  const [attributeGroups = [], attributeGroupsError, attributeGroupsLoading] = useAPI(
    () =>
      axios
        .get(`group-attributes`, {
          params: {
            conditions: {},
            orderBy: [],
          },
        })
        .then(({ data }) => data),
    [],
  );

  const handleClose = () => {
    setVisible(false);
    resetDocumentTypeDetail();
  };

  const onSubmit = async (values) => {
    const obj = {
      ...values,
      attribute: attributeValues.attribute,
      label: attributeValues.label,
      attributeCode: attributeValues.attributeCode,
      save: values.save && values.save - 1,
    };
    setLoading(true);
    try {
      const { data } = documentTypeDetail
        ? await axios.put(`document-type-details/${documentTypeDetail.id}`, {
            documentTypeDetail: { documentType: parentData.id, ...obj },
          })
        : await axios.post(`document-type-details`, {
            documentTypeDetail: { documentType: parentData.id, ...obj },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${documentTypeDetail ? 'izmijenjen' : 'dodan'} atribut datog tipa dokumenta`,
      );
      if (setDocumentTypeDetail) setDocumentTypeDetail((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${documentTypeDetail ? 'uređivanja' : 'unosa'} `
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={documentTypeDetail}
      createTitle="Kreiraj atribut tipa dokumenta"
      updateTitle={`Uredi atribut ${documentTypeDetail?.orderNumber || ''} - ${
        documentTypeDetail?.label
      } tipa dokumenta ${parentData?.id}`}
      createButtonText="Kreiraj atribut"
      updateButtonText="Uredi atribut"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid container style={{ minHeight: 310 }} spacing={6} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <TextField
                      error={!!errors.orderNumber}
                      fullWidth
                      label="Redni broj*"
                      name="orderNumber"
                      id="orderNumber"
                      defaultValue={documentTypeDetail?.orderNumber}
                      placeholder="Unesite redni broj atributa"
                      inputRef={register}
                    />
                    {errors.orderNumber && (
                      <FormHelperText error>{errors.orderNumber.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      error={!!errors.attribute || attributeGroupsError}
                      id="attributeGroup"
                      name="attributeGroup"
                      label="Grupa atributa"
                      defaultValue={documentTypeDetail?.attributeGroup}
                      control={control}
                      fullWidth
                      loading={attributeGroupsLoading ? 1 : undefined}
                    >
                      {attributeGroups.nodes &&
                        attributeGroups.nodes.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name} ({item.code})
                          </MenuItem>
                        ))}
                    </FormSelect>
                    {errors.attributeGroup && (
                      <FormHelperText error>{errors.attributeGroup.message}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item>
                    <FormSelect
                      error={!!errors.attribute || attributesError}
                      id="attribute"
                      name="attribute"
                      label="Labela atributa"
                      required
                      defaultValue={documentTypeDetail?.attribute}
                      control={control}
                      fullWidth
                      loading={attributesLoading ? 1 : undefined}
                    >
                      {attributes.nodes &&
                        attributes.nodes.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            onClick={() =>
                              setAttributeValues({
                                attribute: item.id,
                                label: item.name,
                                attributeCode: item.code,
                              })
                            }
                          >
                            {item.name} ({item.code})
                          </MenuItem>
                        ))}
                    </FormSelect>
                    {errors.attribute && (
                      <FormHelperText error>{errors.attribute.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.label}
                      fullWidth
                      label="Labela*"
                      name="label"
                      id="label"
                      disabled
                      defaultValue={documentTypeDetail?.label}
                      placeholder="Unesite labelu atributa"
                      inputRef={register}
                    />
                    {errors.label && <FormHelperText error>{errors.label.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.attributeCode}
                      fullWidth
                      disabled
                      label="Šifra atributa"
                      name="attributeCode"
                      id="attributeCode"
                      defaultValue={
                        documentTypeDetail?.attributeCode || attributeValues?.attributeCode
                      }
                      placeholder="Unesite šifru atributa"
                      inputRef={register}
                    />
                    {errors.attributeCode && (
                      <FormHelperText error>{errors.attributeCode.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.initialValue}
                      fullWidth
                      label="Inicijalna vrijednost"
                      name="initialValue"
                      id="initialValue"
                      defaultValue={documentTypeDetail?.initialValue}
                      placeholder="Unesite inicijalnu vrijednost atributa"
                      inputRef={register}
                    />
                    {errors.initialValue && (
                      <FormHelperText error>{errors.initialValue.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.sqlCodeForData}
                      fullWidth
                      multiline
                      label="SQL upit za podatke"
                      name="sqlCodeForData"
                      id="sqlCodeForData"
                      defaultValue={documentTypeDetail?.sqlCodeForData}
                      placeholder="Unesite SQL upit atributa"
                      inputRef={register}
                    />
                    {errors.sqlCodeForData && (
                      <FormHelperText error>{errors.sqlCodeForData.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.instructions}
                      fullWidth
                      label="Uputstvo(hint)"
                      name="instructions"
                      id="instructions"
                      defaultValue={documentTypeDetail?.instructions}
                      placeholder="Unesite uputstvo(hint) atributa"
                      inputRef={register}
                    />
                    {errors.instructions && (
                      <FormHelperText error>{errors.instructions.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <FormSelect
                        id="required"
                        name="required"
                        label="Obavezan"
                        required
                        error={!!errors.required}
                        defaultValue={documentTypeDetail?.required || 'D'}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </FormSelect>
                      {errors.required && (
                        <FormHelperText error>{errors.required.message}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormSelect
                        id="save"
                        name="save"
                        label="Spasi"
                        required
                        error={!!errors.save}
                        defaultValue={documentTypeDetail?.save + 1 || 2}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value={2}>DA</MenuItem>
                        <MenuItem value={1}>NE</MenuItem>
                      </FormSelect>
                      {errors.save && <FormHelperText error>{errors.save.message}</FormHelperText>}
                    </Grid>
                    <Grid item xs={4}>
                      <FormSelect
                        id="valid"
                        name="valid"
                        label="Važi"
                        required
                        error={!!errors.valid}
                        defaultValue={documentTypeDetail?.valid || 'D'}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </FormSelect>
                      {errors.valid && (
                        <FormHelperText error>{errors.valid.message}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <FormSelect
                        id="showOnScan"
                        name="showOnScan"
                        label="Prikaži na skeniranju"
                        required
                        error={!!errors.showOnScan}
                        defaultValue={documentTypeDetail?.showOnScan || 'D'}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </FormSelect>
                      {errors.showOnScan && (
                        <FormHelperText error>{errors.showOnScan.message}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelect
                        id="showInMessages"
                        name="showInMessages"
                        label="Prikaži u porukama"
                        required
                        error={!!errors.showInMessages}
                        defaultValue={documentTypeDetail?.showInMessages || 'N'}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </FormSelect>
                      {errors.showInMessages && (
                        <FormHelperText error>{errors.showInMessages.message}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default DocumentTypeModal;
