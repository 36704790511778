import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  MenuItem,
  CardHeader,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { FilterChip, InputModal } from '@shared/components';
import { handleFilterChange, handleChipDelete } from '../../../utils/filterHelpers';
import useTheme from '../../../hooks/useTheme';
import { ContentBox } from './style';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga atributa datog tipa dokumenta"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="40%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Box>
          <Card elevation={4}>
            <CardHeader
              titleTypographyProps={{ variant: 'subtitle2' }}
              title="Podaci o atributima datog tipa dokumenta"
              action={
                <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                  <IconButton
                    onClick={() => {
                      setFilterValues({});
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <ClearAllIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              }
            />
            <Divider />
            <ContentBox elevation={5}>
              <Grid
                container
                direction="row"
                spacing={5}
                style={{ maxHeight: 500, overflowY: 'auto' }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Redni broj"
                    name="orderNumber"
                    placeholder="Unesite redni broj za pretragu"
                    value={filterValues.orderNumber || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        orderNumber: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Grupa atributa"
                    name="attributeGroupName"
                    placeholder="Unesite naziv grupe atributa za pretragu"
                    value={filterValues.attributeGroupName || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        attributeGroupName: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Labela"
                    name="label"
                    placeholder="Unesite labelu za pretragu"
                    value={filterValues.label || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        label: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Šifra atributa"
                    name="attributeCode"
                    placeholder="Unesite šifru atributa za pretragu"
                    value={filterValues.attributeCode || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        attributeCode: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Inicijalna vrijednost"
                    name="initialValue"
                    placeholder="Unesite inicijalnu vrijednost za pretragu"
                    value={filterValues.initialValue || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        initialValue: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Uputstvo(hint)"
                    name="instructions"
                    placeholder="Unesite uputstvo za pretragu"
                    value={filterValues.instructions || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        instructions: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SQL upit za podatke"
                    name="sqlCodeForData"
                    placeholder="Unesite SQL upit za pretragu"
                    value={filterValues.sqlCodeForData || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        sqlCodeForData: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Obavezan"
                    select
                    name="required"
                    value={filterValues.required || ''}
                    variant="standard"
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        required: value || value?.length > 0 ? value : undefined,
                      }))
                    }
                  >
                    <MenuItem value="D">DA</MenuItem>
                    <MenuItem value="N">NE</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Spasi"
                    select
                    name="save"
                    value={filterValues.save || ''}
                    variant="standard"
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        save: value || value?.length > 0 ? value : undefined,
                      }))
                    }
                  >
                    <MenuItem value={2}>DA</MenuItem>
                    <MenuItem value={1}>NE</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Važi"
                    select
                    name="valid"
                    value={filterValues.valid || ''}
                    variant="standard"
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        valid: value || value?.length > 0 ? value : undefined,
                      }))
                    }
                  >
                    <MenuItem value="D">DA</MenuItem>
                    <MenuItem value="N">NE</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Prikaži na skeniranju"
                    select
                    name="showOnScan"
                    value={filterValues.showOnScan || ''}
                    variant="standard"
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        showOnScan: value || value?.length > 0 ? value : undefined,
                      }))
                    }
                  >
                    <MenuItem value="D">DA</MenuItem>
                    <MenuItem value="N">NE</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Prikaži u porukama"
                    select
                    name="showInMessages"
                    value={filterValues.showInMessages || ''}
                    variant="standard"
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        showInMessages: value || value?.length > 0 ? value : undefined,
                      }))
                    }
                  >
                    <MenuItem value="D">DA</MenuItem>
                    <MenuItem value="N">NE</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </ContentBox>
          </Card>
        </Box>
      </Box>
    </InputModal>
  );
};

export default Filter;
