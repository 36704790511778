import * as yup from 'yup';

export const documentResOrgSchema = yup.object().shape({
  documentNoPages: yup
    .number()
    .min(0, 'Broj stranica mora biti pozitivan broj')
    .integer('Broj stranica mora biti cijeli broj')
    .transform((value, original) => (original === '' ? null : value))
    .typeError('Broj stranica mora biti broj')
    .nullable(),
  responsiblePerson: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
        .object()
        .shape({
          id: yup.number().positive(),
        })
        .nullable()
      : yup
        .number()
        .positive()
        .transform((v, o) => (o === '' ? null : v))
        .nullable()

  }),
  protocolNumber: yup
    .string()
    .max(4000, 'Polje "Broj protokola" mora imati manje od 1000 karaktera.'),

  documentType: yup
    .number()
    .typeError('Tip dokumenta je obavezno polje')
    .required('Tip dokumenta je obavezno polje'),
});
