import * as React from 'react';
// import { useEffect } from 'react';
import { ScrollReset, Unauthorized, Error404, PrivateRoute } from '@shared/components';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import GlobalAlerts from 'components/GlobalAlerts';
import { HOMEPAGE_URL } from 'config';

// Routes
import ScanUsers from './views/ScanUsers';
import Folders from './views/Folders';
import ScanUsersDetails from './views/ScanUsers/Details';
import DocumentTypes from './views/DocumentTypes';
import DocumentTypeDetails from './views/DocumentTypeDetails';
import DocumentAttributesDMS from './views/DocumentAttributesDMS';
import Documents from './views/Documents';
import DocumentsResponsibleOrg from './views/DocumentsResponsibleOrg';
import ResponsibleOrgDetails from './views/DocumentsResponsibleOrg/Details';
import AttributeTypes from './views/AttributeTypes';
import DocumentDetails from './views/DocumentDetails';
import Attributes from './views/Attributes';
import AttributeDocumentTypes from './views/AttributeDocType';
import DocumentsAccordance from './views/DocumentsAccordance';
import DocumentsAccordanceDetails from './views/DocumentsAccordanceDetails';
import DocumentsOverview from './views/DocumentsOverview';
import DocumentsOverviewDetails from './views/DocumentsOverviewDetails';
import GroupAccordances from './views/GroupAccordances';
import GroupAccordancesDetails from './views/GroupAccordancesDetails';
import AttributeGroups from './views/AttributeGroups';
// eslint-disable-next-line import/named
import { DocumentMessages, MessagesInfo, ReadMessages } from './views/Messages';
import { DashboardLayout } from './components/layouts';

const MainView = ({ keycloak }) => {
  const location = useLocation();

  return (
    <DashboardLayout keycloak={keycloak} homepageURL={HOMEPAGE_URL}>
      <ScrollReset location={location} />
      <Switch>
        <Redirect exact from="/" to="/messages-documents" />
        <Route path="/document-types" exact component={DocumentTypes} />
        <Route path="/document-types/:id" exact component={DocumentTypeDetails} />
        <Route exact path="/scan-users" component={ScanUsers} />
        <Route exact path="/scan-users/:id" component={ScanUsersDetails} />
        <Route exact path="/documents" component={Documents} />
        <Route exact path="/folders" component={Folders} />
        <Route exact path="/folders/:id" component={Folders} />
        <Route exact path="/document-attributes" component={DocumentAttributesDMS} />
        <Route exact path="/documents/:id" component={DocumentDetails} />
        <Route exact path="/attributes" component={Attributes} />
        <Route path="/attribute-groups" exact component={AttributeGroups} />
        <Route exact path="/attributes/:id" component={AttributeDocumentTypes} />
        <Route exact path="/group-accordances" component={GroupAccordances} />
        <Route exact path="/group-accordances/:id" component={GroupAccordancesDetails} />
        <Route path="/attribute-types" exact component={AttributeTypes} />
        <Route exact path="/messages-documents" component={DocumentMessages} />
        <Route exact path="/messages-informations" component={MessagesInfo} />
        <Route exact path="/messages-read" component={ReadMessages} />
        <PrivateRoute
          path="/document-types"
          exact
          component={() => <DocumentTypes />}
          roles={['DMS_SIFARNICI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          path="/document-types/:id"
          exact
          component={() => <DocumentTypeDetails />}
          roles={['DMS_SIFARNICI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/scan-users"
          component={ScanUsers}
          roles={['DMS_KORISNICI_SKENIRANJE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/scan-users/:id"
          component={ScanUsersDetails}
          roles={['DMS_KORISNICI_SKENIRANJE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/documents"
          component={Documents}
          roles={['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK', 'DMS_PRIDRUZIVANJE_ODG_OSOBE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/document-attributes"
          component={DocumentAttributesDMS}
          roles={['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK', 'DMS_PRIDRUZIVANJE_ODG_OSOBE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/documents/:id"
          component={DocumentDetails}
          roles={['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK', 'DMS_PRIDRUZIVANJE_ODG_OSOBE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/attributes"
          component={Attributes}
          roles={['DMS_SIFARNICI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/attributes/:id"
          component={AttributeDocumentTypes}
          roles={['DMS_SIFARNICI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          path="/attribute-types"
          exact
          component={AttributeTypes}
          roles={['DMS_SIFARNICI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/group-accordances"
          component={GroupAccordances}
          roles={['DMS_GRUPE_SAGLASNOSTI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/group-accordances/:id"
          component={GroupAccordancesDetails}
          roles={['DMS_GRUPE_SAGLASNOSTI']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/responsible-organization"
          component={DocumentsResponsibleOrg}
          roles={['DMS_PROTOKOL', 'DMS_PRIDRUZIVANJE_ODG_OSOBE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/responsible-organization/:id"
          component={ResponsibleOrgDetails}
          roles={['DMS_PROTOKOL', 'DMS_PRIDRUZIVANJE_ODG_OSOBE']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/accordance"
          component={DocumentsAccordance}
          roles={['DMS_USAGLASAVANJE_DOK']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/accordance/:id"
          component={DocumentsAccordanceDetails}
          roles={['DMS_USAGLASAVANJE_DOK']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/overview"
          component={DocumentsOverview}
          roles={['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK']}
          keycloak={keycloak}
        />
        <PrivateRoute
          exact
          path="/overview/:id"
          component={DocumentsOverviewDetails}
          roles={['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK']}
          keycloak={keycloak}
        />
        {/* Handlers */}
        <Route path="/401" component={() => <Unauthorized homePageURL={HOMEPAGE_URL} />} />
        <Route path="*" component={() => <Error404 homePageURL={HOMEPAGE_URL} />} />
      </Switch>
      <GlobalAlerts />
    </DashboardLayout>
  );
};

export default MainView;
