import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const LayoutWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.topbarHeight}px;
  min-height: 100vh;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding-left: ${({ theme, navbarVisibility }) => (navbarVisibility ? theme.navbarWidth : 0)}px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  min-height: 100%;
`;

export const PageContentWrapper = styled(Box)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(6)}px;
  padding-top: ${({ theme }) => theme.spacing(6) + theme.pageHeaderHeight}px;
  flex: 1 1 auto;
  box-sizing: border-box;
`;

export const Message = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;
