import React, { useState, useEffect } from 'react';
import {
  // Divider,
  Grid,
  Box,
  Typography,
  // FormHelperText,
  Card,
  CardContent,
  // MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import {
  InputModal,
  FolderView,
} from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const FooterInfo = ({ name }) => {
  return (
    <Alert
      severity="warning"
      style={{ fontSize: '.8em', paddingBottom: '0', paddingTop: '0', margin: '0' }}
    >
      Dokument će se prebaciti u folder &quot;{name}&quot; i bit će dostupan izborom tog foldera u
      tabeli
    </Alert>
  );
};

const FolderModal = ({
  document,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  documentLoading = false,
  resetDocument,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const [selectedFolder, setSelected] = useState();
  const [selectedFolderName, setSelectedName] = useState();
  const { handleSubmit, reset, clearErrors } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (selectedFolder === '0') {
        const data = await axios.post(`folders/remove`, {
          folder: { documentId: document.id, folderId: document.folderId },
        });
        if (!data) throw new Error();
        sendSuccessAlertData('Dokument uspješno uklonjen iz foldera');
      } else {
        const data = await axios.post(`folders/add`, {
          folder: { documentId: document.id, folderId: +selectedFolder },
        });
        if (!data) throw new Error();
        sendSuccessAlertData('Dokument uspješno prebačen u folder');
      }
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom prebacivanja dokumenta'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
    setSelected();
    setSelectedName('');
    resetDocument();
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={document}
      updateTitle={`Promjena lokacije dokumenta ${document?.id}`}
      updateButtonText="Potvrdi"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth="35%"
      loading={loading || documentLoading}
      theme={theme}
      disabledEdit={
        !selectedFolder ||
        document?.folderId?.toString() === selectedFolder ||
        (!document?.folderId && selectedFolder === '0')
      }
      disabledEditTooltip="Za promjenu lokacije potrebno izabrati drugačiji folder od trenutnog"
      disabledEditTitle="Potvrdi"
      footerInfo={
        selectedFolder &&
        document?.folderId?.toString() !== selectedFolder &&
        !(!document?.folderId && selectedFolder === '0') && (
          <FooterInfo name={selectedFolderName || document?.folderName} id={document.id} />
        )
      }
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Card elevation={4} style={{ height: 'auto' }}>
          <CardContent>
            <Grid
              container
              spacing={6}
              direction="column"
              justifyContent="center"
              style={{
                padding: `${theme.spacing(8)}px ${theme.spacing(10)}px ${theme.spacing(
                  8,
                )}px ${theme.spacing(10)}px`,
                minHeight: 200,
              }}
              // alignItems="stretch"
            >
              <Typography variant="h4" style={{ paddingBottom: theme.spacing(6) }}>
                {selectedFolderName
                  ? `Da li ste sigurni da želite prebaciti dokument u folder ${selectedFolderName}?`
                  : 'Izaberite željeni folder'}
              </Typography>
              <Box ml={theme.spacing(2)}>
                <FolderView
                  setSelected={setSelected}
                  setSelectedName={setSelectedName}
                  defaultSelected={document?.folderId?.toString() || '0'}
                  expanded
                  theme={theme}
                  axios={axios}
                />
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </InputModal>
  );
};

export default FolderModal;
