import * as yup from 'yup';

export const attributeGroupSchema = yup.object().shape({
  code: yup
    .string()
    .max(12, 'Polje "Šifra" mora imati manje od 12 karaktera')
    .typeError('Polje "Šifra" je obavezno')
    .required('Polje "Šifra" je obavezno'),
  name: yup
    .string()
    .max(200, 'Polje "Naziv" mora imati manje od 200 karaktera')
    .typeError('Polje "Naziv" je obavezno')
    .required('Polje "Naziv" je obavezno'),
  ordNumber: yup
    .number()
    .typeError('Polje "Redni broj" je numerička vrijednost')
    .required('Polje "Redni broj" je obavezno'),
});
