/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router';
import { Box, Divider, Hidden, List, ListSubheader, SvgIcon } from '@material-ui/core';
import NotificationsActiveSharpIcon from '@material-ui/icons/NotificationsActiveSharp';
import useTheme from '../../hooks/useTheme';
import useAuthContext from '../../hooks/useAuthContext';
import Logo from '../Logo';
import NavItem from './NavItem';
import { MobileDrawer, DesktopDrawer, HomepageLink } from './style';
import navConfig from './navConfig';


const renderNavItems = ({ items, user, onClick, ...rest }) => {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, user, onClick, ...rest }), [])}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth = 0, user, onClick }) => {
  if (item.roles && item.roles.filter((role) => user.roles.includes(role)).length === 0) return acc;

  const key = item.title + depth;

  if (item.items) {
    const open =
      matchPath(pathname, {
        path: item.href,
        exact: false,
      }) ||
      matchPath(pathname, {
        path: item.basePath,
        exact: false,
      });

    const navItems = renderNavItems({
      depth: depth + 1,
      pathname,
      items: item.items,
      user,
      onClick,
    });

    if (navItems.props?.children.length)
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
        >
          {navItems}
        </NavItem>,
      );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        pathname={pathname}
        onClick={onClick}
      />,
    );
  }
  return acc;
};

const Navbar = ({ openMobile, onMobileClose, homepageURL, navbarVisibility, onClick }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    for (let i = 0; i < navConfig.length; i += 1) {
      const navItem = navConfig[i].items?.find((item) => {
        if (item.items?.length) {
          return item.items.find(
            (it) =>
              it.href === location.pathname ||
              (Boolean(it.basePath) && location.pathname.includes(it.basePath)),
          );
        }
        return (
          item.href === location.pathname ||
          (Boolean(item.basePath) && location.pathname.includes(item.basePath))
        );
      });
      if (navItem) {
        const title = navItem?.title || '';
        document.title = `${navConfig[i].subheader}${title && ` | ${title}`} `;
        break;
      }
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="center">
          <Logo onClick={() => history.push('/')} />
        </Box>
      </Hidden>
      <Box p={1}>
        <Box
          display="flex"
          justifyContent="center"
          style={{ cursor: 'pointer' }}
          // onClick={() => history.push('/')}
        >
          <HomepageLink to={homepageURL} title="Povratak na početnu">
            <img
              src={`${process.env.PUBLIC_URL}/logo.svg`}
              alt="logo"
              height={`${theme.pageHeaderHeight - 11}px`}
              width="85%"
            />
          </HomepageLink>
        </Box>
      </Box>
      <Divider />
      <Box p={2} height="100%" overflow="auto">
        {navConfig.map((config, i) => (
          <React.Fragment key={config.subheader}>
            <List
              subheader={
                <ListSubheader disableGutters disableSticky>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box ml={1}>{config.subheader}</Box>
                    {config.icon && (
                      <SvgIcon fontSize="small" style={{ color: theme.palette.info.secondary }}>
                        <NotificationsActiveSharpIcon size="15" />
                      </SvgIcon>
                    )}
                  </Box>
                </ListSubheader>
              }
            >
              <Box
                style={{
                  backgroundColor: config.icon && '#E6F4FF8E',
                }}
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname,
                  user,
                  onClick,
                })}
              </Box>
            </List>
            {i !== navConfig.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <MobileDrawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </MobileDrawer>
      </Hidden>
      <Hidden mdDown>
        <DesktopDrawer anchor="left" open={navbarVisibility} variant="persistent">
          {content}
        </DesktopDrawer>
      </Hidden>
    </>
  );
};

export default Navbar;
