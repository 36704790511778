import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import Navbar from '../Navbar';
import Topbar from '../Topbar';
import Spinner from '../Spinner';
import { LayoutWrapper, ContentWrapper, ContentContainer, Content, Message } from './style';

export const DashboardLayout = ({
  children,
  keycloak,
  setUser,
  user,
  loading,
  error,
  setKeycloak,
  location,
  history,
  logo,
  pageHeaderHeight,
  navConfig,
  signOut,
  homepageURL,
  navbarVisibility,
  setNavbarVisibility,
}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarVisibility((prev) => !prev);
  };

  useEffect(() => {
    if (keycloak) {
      setKeycloak(keycloak);
      setUser((prev) => ({
        ...prev,
        name: keycloak.tokenParsed.name || '',
        username: keycloak.tokenParsed.preferred_username || '',
        roles: keycloak.tokenParsed.roles || [],
      }));
    }
  }, []);

  return (
    <LayoutWrapper>
      {error ? (
        <Box display="flex" height="100vh" width="100%" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <ErrorOutlineRoundedIcon fontSize="large" />
            <Typography>
              <Message>Došlo je do greške. Molimo da ponovo učitate stranicu.</Message>
            </Typography>
          </Box>
        </Box>
      ) : loading ? (
        <Box display="flex" height="100vh" width="100%" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Spinner size={60} />
            <Typography>
              <Message>Učitavanje aplikacije. Molimo pričekajte.</Message>
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Topbar
            navbarVisibility={navbarVisibility}
            toggleNavbar={toggleNavbar}
            logo={logo}
            history={history}
            user={user}
            signOut={signOut}
            onMobileNavOpen={() => setMobileNavOpen(true)}
          />
          <Navbar
            navbarVisibility={navbarVisibility}
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            location={location}
            history={history}
            logo={logo}
            user={user}
            navConfig={navConfig}
            pageHeaderHeight={pageHeaderHeight}
            homepageURL={homepageURL}
          />
          <ContentWrapper navbarVisibility={navbarVisibility}>
            <ContentContainer>
              <Content>{children}</Content>
            </ContentContainer>
          </ContentWrapper>
        </>
      )}
    </LayoutWrapper>
  );
};

export const VerticalBoxContainer = ({ children, ...props }) => {
  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      {...props}
    >
      {children}
    </Box>
  );
};

export * from './style';
