import styled from 'styled-components';
import { TextField as MuiTextField } from '@material-ui/core';

export const TextField = styled(MuiTextField)`
  & .MuiOutlinedInput-root:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  .MuiOutlinedInput-root {
    border: 1px dashed ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-root:hover {
    cursor: pointer;
  }
  .MuiOutlinedInput-root:focus {
    cursor: auto;
  }

  .MuiOutlinedInput-input:hover {
    cursor: pointer;
  }
  .MuiOutlinedInput-input:focus {
    cursor: auto;
  }
`;
