import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  CardHeader,
  Card,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import CirclePicker from '@uiw/react-color-circle';
import { InputModal, FormTextArea } from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox } from './style';

const FoldersModal = ({
  parent,
  folder,
  setSelectedFolder,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetSelectedFolder,
  setRerender,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [folderColor, setFolderColor] = useState();
  const [requestError, setRequestError] = useState();
  const theme = useTheme();

  const { register, handleSubmit, clearErrors, errors, reset } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(folderSchema),
  });
  useEffect(() => {
    if (folder && folder.color) setFolderColor(folder.color);
  }, [folder]);

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetSelectedFolder();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async (values) => {
    const folderValues = {
      ...values,
      color: folderColor,
      parent,
    };
    try {
      setLoading(true);
      const { data } = folder
        ? await axios.put(`folders/${folder.id}`, {
            folder: folderValues,
          })
        : await axios.post(`folders`, { folder: folderValues });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${folder ? 'izmijenjen' : 'dodan'} folder`);
      if (setSelectedFolder) setSelectedFolder((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${folder ? 'uređivanja' : 'unosa'} foldera`
        }`,
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <InputModal
      object={folder}
      createTitle="Kreiraj novi folder"
      updateTitle={`Uredi folder ${folder?.id}`}
      createButtonText="Kreiraj folder"
      updateButtonText="Uredi folder"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="35%"
      loading={loading}
      showHeader
      theme={theme}
      disabled={disabled}
    >
      <ContentBox>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Card style={{ height: '100%' }} elevation={4}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Box mt={5}>
                  <TextField
                    error={!!errors.name}
                    fullWidth
                    name="name"
                    id="name"
                    label="Naziv*"
                    inputRef={register({ required: 'Naziv je obavezno polje' })}
                    defaultValue={folder?.name}
                    placeholder="Unesite naziv foldera"
                  />
                  {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                </Box>
                <Box mt={5}>
                  <FormTextArea
                    label="Opis"
                    register={register}
                    object={folder}
                    name="description"
                    placeholder="Unesite opis foldera"
                    errors={errors}
                    maxLength={4000}
                    rows={7}
                  />
                </Box>
                <Box mt={5}>
                  <Typography paragraph variant="caption" color="textSecondary">
                    Boja foldera
                  </Typography>
                  <CirclePicker
                    colors={[
                      '#F44E3B',
                      '#FF8080',
                      '#FE9200',
                      '#FFD59E',
                      '#FCDC00',
                      '#DBDF00',
                      '#57b594',
                      '#92B4EC',
                      '#947994',
                      '#e3879e',
                      '#81c3d7',
                      '#7d6f8f',
                      '#9772FB',
                      '#7FB5FF',
                      '#40DFEF',
                    ]}
                    color={folderColor}
                    onChange={(color) => setFolderColor(color.hex)}
                    style={{ margin: 'auto' }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContentBox>
    </InputModal>
  );
};

export default FoldersModal;
