import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import mime from 'mime-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
  TableSkeleton,
  Spinner,
  dateFormatterTime,
  dateFormatterDate,
  Avatar,
  getInitials,
} from '@shared/components';
import axios from '../../../utils/axios';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';
import useTheme from '../../../hooks/useTheme';
import useNotificationContext from '../../../hooks/useNotificationContext';
import { ErrorMessage } from './style';

const List = ({
  messages,
  tableColumns,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
  screen,
}) => {
  const history = useHistory();
  const theme = useTheme();

  const { setRerender, markAllDocumentMessagesAsRead } = useNotificationContext();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const { sendErrorAlertData } = useGlobalAlertContext();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const markAsRead = async (module, documentId) => {
    if (screen === 'EKRAN1') markAllDocumentMessagesAsRead(documentId);
    setRerender((prevState) => !prevState);
    history.push({
      pathname:
        screen === 'EKRAN3' || screen === 'EKRAN2'
          ? `/overview/${documentId}`
          : module === 'SAG' || module === 'DOK'
          ? `/accordance/${documentId}`
          : `/overview/${documentId}`,
    });
  };

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data?.details?.reason ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(undefined);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableColumns.map((column) => (
            <TableCell width={column.width} key={column.value} align={column.align}>
              <Tooltip
                placement="bottom-start"
                enterDelay={300}
                title={`Sortiraj prema ${column.placeholder}`}
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={180} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !messages ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : messages?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti jedna poruka</Typography>
            </TableCell>
          </TableRow>
        ) : (
          messages?.map((message) => (
            <TableRow
              style={{
                borderBottom: 0,
                backgroundColor: message.read === 'D' && theme.palette.background.grey,
              }}
              key={message.id}
            >
              <TableCell width={100}>
                {message.createdBy && (
                  <Box display="flex">
                    <Avatar width="37px" height="37px" font="11pt" name={message.createdBy || ''}>
                      {getInitials(message.createdBy || '')}
                    </Avatar>
                    <Box display="flex" justifyContent="center" flexDirection="column">
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        style={{
                          fontWeight: message.read === 'N' && theme.typography.fontWeightMedium,
                        }}
                      >
                        {message.createdBy}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </TableCell>
              <TableCell width={150}>
                <Box
                  style={{ fontWeight: message.read === 'N' && theme.typography.fontWeightMedium }}
                >
                  {message.subject}
                </Box>
              </TableCell>
              <TableCell width={150}>{message.message}</TableCell>
              <TableCell width={150}>
                {dateFormatterDate(message.createdAt)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(message.createdAt)}
                </Typography>
              </TableCell>
              <TableCell width={50} align="right">
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title={
                    downloadLoading && message?.id === selectedDocument?.id
                      ? ''
                      : 'Prikaži dokument'
                  }
                >
                  {downloadLoading && message?.id === selectedDocument?.id ? (
                    <span>
                      <IconButton size="small" disabled>
                        <Box pr={3}>
                          <Spinner color="primary" size={20} />
                        </Box>
                      </IconButton>
                    </span>
                  ) : (
                    <span>
                      <IconButton
                        disabled={downloadLoading}
                        onClick={() => {
                          setSelectedDocument(message);
                          getDocumentFile(message.documentId, message.documentExtension);
                        }}
                      >
                        <SvgIcon fontSize="small">
                          <InsertDriveFileOutlinedIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  )}
                </Tooltip>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title="Detaljni pregled dokumenta"
                >
                  <span>
                    <IconButton
                      disabled={!message.id}
                      onClick={() => {
                        markAsRead(message.module, message.documentId);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <ArrowForwardIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default List;
