import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  CardHeader,
  MenuItem,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { InputModal, FilterChip } from '@shared/components';
import { handleChipDelete } from '../../../utils/filterHelpers';
import useTheme from '../../../hooks/useTheme';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  handleFilterChange,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga tipova dokumenta sa datim atributom"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="35%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Box>
          <Card elevation={4}>
            <CardHeader
              titleTypographyProps={{ variant: 'subtitle2' }}
              title="Podaci o tipu dokumenta"
              action={
                <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                  <IconButton
                    onClick={() => {
                      setFilterValues({});
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <ClearAllIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              }
            />
            <Divider />
            <Box elevation={5} style={{ padding: `${theme.spacing(5)}px` }}>
              <Grid container direction="column" spacing={5} style={{ maxHeight: 500 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tip dokumenta"
                    name="documentTypeName"
                    placeholder="Unesite tip dokumenta za pretragu"
                    value={filterValues.documentTypeName || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentTypeName: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Redni broj"
                    name="sequenceNumber"
                    placeholder="Unesite redni broj za pretragu"
                    value={filterValues.sequenceNumber || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        sequenceNumber: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Inicijalna vrijednost"
                    name="initialValue"
                    placeholder="Unesite inicijalnu vrijednost za pretragu"
                    value={filterValues.initialValue || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        initialValue: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SQL upit za podatke"
                    name="sqlDataCode"
                    placeholder="Unesite dio SQL upita za pretragu"
                    value={filterValues.sqlDataCode || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        sqlDataCode: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Uputstvo"
                    name="instructions"
                    placeholder="Unesite uputstvo za pretragu"
                    value={filterValues.instructions || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        instructions: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Obavezan"
                    select
                    name="requiredField"
                    value={filterValues.requiredField || ''}
                    style={{ paddingBottom: '8px' }}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        requiredField: value?.length === 0 ? undefined : value,
                      }))
                    }
                  >
                    <MenuItem key="D" value="D">
                      DA
                    </MenuItem>
                    <MenuItem key="N" value="N">
                      NE
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Spasi"
                    select
                    name="saveField"
                    value={filterValues.saveField || ''}
                    style={{ paddingBottom: '8px' }}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        saveField: value?.length === 0 ? undefined : value,
                      }))
                    }
                  >
                    <MenuItem key="D" value={2}>
                      DA
                    </MenuItem>
                    <MenuItem key="N" value={1}>
                      NE
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Važi"
                    select
                    name="valid"
                    value={filterValues.valid || ''}
                    style={{ paddingBottom: '8px' }}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        valid: value?.length === 0 ? undefined : value,
                      }))
                    }
                  >
                    <MenuItem key="D" value="D">
                      DA
                    </MenuItem>
                    <MenuItem key="N" value="N">
                      NE
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Box>
    </InputModal>
  );
};

export default Filter;
