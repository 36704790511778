import React from 'react';
import { Provider as AuthContextProvider } from './AuthContext';
import { Provider as NavbarContextProvider } from './NavbarContext';
import { Provider as GlobalAlertProvider } from './GlobalAlertContext';
import { Provider as NotificationContext } from './NotificationContext';

const Provider = ({ children }) => {
  return (
    <AuthContextProvider>
      <NavbarContextProvider>
        <NotificationContext>
          <GlobalAlertProvider>{children}</GlobalAlertProvider>
        </NotificationContext>
      </NavbarContextProvider>
    </AuthContextProvider>
  );
};

export default Provider;
