import { useEffect, useState } from 'react';

const useAPI = (f, deps) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    let subscribed = true;
    setLoading(true);
    setError();
    f()?.then(
      (r) => {
        if (subscribed) {
          setLoading(false);
          setData(r);
        }
      },
      (e) => {
        if (subscribed) {
          setLoading(false);
          setError(e?.response ? e.response : e);
        }
      },
    );

    return function cleanup() {
      subscribed = false;
    };
  }, [...deps] || []);

  return [data, error, loading];
};

export default useAPI;
