import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';

export const ContentBox = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`;
