import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import mime from 'mime-types';
// import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import {
  TableSkeleton,
  Spinner,
  StatusLabel,
  dateFormatterTime,
  dateFormatterDate,
} from '@shared/components';
import axios from '../../../utils/axios';
import useAuthContext from '../../../hooks/useAuthContext';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';
import { ErrorMessage } from './style';

const DocumentsTable = ({
  documents,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedDocument,
  selectedDocument,
  // setDeleteModalVisible,
  setStatusModalVisible,
  setDocumentModalVisible,
  setFolderModalVisible,
}) => {
  const getStatusLabel = (status, statusName) => {
    if (status !== 0 && !status) return '';
    const map = {
      0: {
        color: 'info',
      },
      1: {
        color: 'neutral',
      },
      2: {
        color: 'secondary',
      },
      3: {
        color: 'primary',
      },
      4: {
        color: 'success',
      },
      5: {
        color: 'error',
      },
      6: {
        color: 'warning',
      },
      7: {
        color: 'info',
      },
    };

    const { color } = map[status] || 'neutral';

    return <StatusLabel color={color}>{statusName}</StatusLabel>;
  };
  const history = useHistory();
  const { user } = useAuthContext();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendErrorAlertData } = useGlobalAlertContext();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data ||
          documentError?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(undefined);
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage);

  return (
    <Table style={{ minHeight: 250 }}>
      <TableHead>
        <TableRow height="auto" style={{ maxHeight: '20%' }}>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema tipu dokumenta"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentTypeName', sorters)}
                direction={getSortDirection('documentTypeName', sorters)}
                onClick={() => handleSortChange('documentTypeName', sorters, setSorters)}
              >
                Tip dokumenta
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip enterDelay={300} title="Sortiraj prema statusu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('statusName', sorters)}
                direction={getSortDirection('StatusName', sorters)}
                onClick={() => handleSortChange('statusName', sorters, setSorters)}
              >
                Status
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documents ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documents?.length === 0 ? (
          <TableRow style={{ height: 5 * 70.33, padding: 0 }}>
            <TableCell align="center" colSpan="100%">
              <Typography>Nije pronađen niti jedan dokument</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documents?.map((document) => (
            <TableRow key={document.id} height="auto" style={{ maxHeight: '20%' }}>
              <TableCell width={50}> {document.id}</TableCell>
              <TableCell width={150}> {document.documentTypeName}</TableCell>
              {/* <TableCell width={50}> {document.documentNoPages}</TableCell>
              <TableCell width={50}> {document.documentExtension}</TableCell>
              <TableCell width={100}> {document.documentFilesize}</TableCell> */}
              <TableCell width={100}>
                {getStatusLabel(document.status, document.statusName)}
              </TableCell>
              <TableCell width={100}>
                {dateFormatterDate(document.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(document.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={150}> {document.createdBy}</TableCell>
              <TableCell width={180} align="right">
                <>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      document?.status === 0
                        ? 'Promjena statusa'
                        : 'Nije dozvoljena promjena statusa'
                    }
                  >
                    <span>
                      <IconButton
                        disabled={document?.status !== 0 || document?.createdBy !== user?.username}
                        // style={{ color: document?.status === 3 && document.currentPercentage >= document.accordancePercentage && theme.palette.success.main }}
                        onClick={() => {
                          setSelectedDocument(document);
                          setStatusModalVisible(true);
                        }}
                      >
                        <SvgIcon fontSize="small">
                          <SendOutlinedIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={`Promjena foldera dokumenta (trenutno: ${
                      document.folderName || 'nedodijeljen'
                    })`}
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document);
                        setFolderModalVisible(true);
                      }}
                    >
                      <SvgIcon style={{ color: document?.folderColor }} fontSize="small">
                        <CreateNewFolderIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      downloadLoading && document?.id === selectedDocument?.id
                        ? ''
                        : 'Prikaži dokument'
                    }
                  >
                    {downloadLoading && document?.id === selectedDocument?.id ? (
                      <span>
                        <IconButton size="small" disabled>
                          <Box pr={3}>
                            <Spinner color="primary" size={20} />
                          </Box>
                        </IconButton>
                      </span>
                    ) : (
                      <span>
                        <IconButton
                          disabled={downloadLoading}
                          onClick={() => {
                            setSelectedDocument(document);
                            getDocumentFile(document.id, document.documentExtension);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <InsertDriveFileOutlinedIcon />
                          </SvgIcon>
                        </IconButton>
                      </span>
                    )}
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      document?.status !== 0 || document?.createdBy !== user?.username
                        ? 'Pregled dokumenta'
                        : 'Uredi dokument'
                    }
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document);
                        setDocumentModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        {document?.status !== 0 || document?.createdBy !== user?.username ? (
                          <FindInPageOutlinedIcon />
                        ) : (
                          <EditOutlinedIcon />
                        )}
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Detaljni pregled dokumenta"
                  >
                    <span>
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `/documents/${document.id}`,
                          })
                        }
                      >
                        <SvgIcon fontSize="small">
                          <ArrowForwardIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        {emptyRows > 0 && totalCount > 0 && (
          <TableRow
            style={{
              height: Math.min(emptyRows, 5) * 70.33,
              padding: '0px',
            }}
          >
            <TableCell colSpan="100%" style={{ padding: 0 }} />
          </TableRow>
        )}
        <TableRow>
          <TablePagination
            style={{ height: 70.33 }}
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentsTable;
