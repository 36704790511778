import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, FormControl, InputLabel, Select } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Spinner from '../Spinner';

const FormSelect = ({
  name,
  label,
  defaultValue,
  placeholder,
  children,
  error,
  loading,
  required = false,
  disabled,
  menuProps = { style: { height: '500px' } },
  control, // required
  menuStyleProps,
  classes,
  setSelectedValue,
  ...props
}) => {
  const labelId = `${name}-label`;
  const [focused, setFocused] = useState(false);

  return (
    <FormControl
      error={error}
      {...props}
      disabled={disabled}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={{ required }}
        render={({ onChange, value }) => (
          <Select
            value={value || ''}
            onChange={(e) => {
              onChange(e);
              if (setSelectedValue) setSelectedValue(e.target.value);
            }}
            style={menuStyleProps}
            MenuProps={menuProps}
            placeholder={placeholder}
            labelId={labelId}
            label={label}
            className={classes}
            endAdornment={
              (loading && <Spinner style={{ marginRight: '56px' }} size={15} />) ||
              (!required && !disabled && control.getValues(name) !== '' && focused && (
                <InputAdornment style={{ marginRight: '28px' }} position='end'>
                  <IconButton size="small" edge="end" onClick={() => control.setValue(name, '')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ))
            }

          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};
export default FormSelect;
