import { parse } from 'json2csv';

const exportJSONToCSVFile = (
  data = [],
  name = '',
  opts = {},
  backendConversion = false,
) => {
  const element = document.createElement('a');
  element.style.display = 'none';
  const file = new Blob([backendConversion ? data : parse(data, { ...opts, withBOM: true })], {
    type: 'text/csv',
  });
  element.href = URL.createObjectURL(file);
  element.download = name ? `${name}.csv` : 'tabela.csv';
  document.body.appendChild(element);
  element.click();
  element.remove();
};

export default exportJSONToCSVFile;
