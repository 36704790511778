import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import axios from '../../../../utils/axios';
import getChipValue from '../../../../utils/getChipValue';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import List from './Table';
import Filter from './Filter';

const tableColumns = [
  {
    name: 'Naziv',
    value: 'name',
    align: 'left',
    width: 150,
    required: true,
    placeholder: 'nazivu',
  },
  {
    name: 'Datum kreiranja',
    value: 'timeCreated',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'datumu kreiranja',
  },
  {
    name: 'Ekstenzija',
    value: 'extension',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'ekstenziji',
  },
  {
    name: 'Veličina fajla',
    value: 'fileSize',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'velićini fajla',
  },
  {
    name: 'Opis',
    value: 'description',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'opisu',
  },
];

const exportFields = [
  {
    label: 'Naziv',
    value: 'name',
  },
  {
    label: 'Prilog',
    value: 'attachment',
  },
  {
    label: 'Datum kreiranja',
    value: 'timeCreated',
    type: 'dateTime',
  },
  {
    label: 'Ekstenzija',
    value: 'extension',
  },
  {
    label: 'Veličina fajla',
    value: 'fileSize',
  },
  {
    label: 'Opis',
    value: 'description',
  },
];

const getLabelText = (value) => {
  const map = {
    userCreated: 'Kreirao',
    description: 'Opis',
    timeCreated: 'Datum kreiranja',
  };
  return map[value];
};

const DocumentAttachments = ({ documentId }) => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);
  const [selectedDocumentAttachment, setSelectedDocumentAttachment] = useState(undefined);

  const [documentAttachments = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`document-attachments`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, documentId },
            orderBy: [...sorters, '"id" desc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
      />
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="description"
                placeholder="Brza pretraga po opisu"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={documentAttachments.totalCount}
                    sortById={false}
                    variant="outlined"
                    fields={exportFields}
                    sorters={[...sorters, '"id" desc']}
                    filters={{ ...filters, documentId }}
                    routeName="document-attachments"
                    fileName={`${documentId}-prilozi-dokumenta`}
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <List
            loading={loading}
            error={error}
            documentAttachments={documentAttachments.nodes}
            tableColumns={tableColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documentAttachments.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            setSelectedDocumentAttachment={setSelectedDocumentAttachment}
            selectedDocumentAttachment={selectedDocumentAttachment}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentAttachments;
