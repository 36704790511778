import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.topbarHeight}px;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  min-height: ${({ theme }) => theme.pageHeaderHeight}px;
  padding: ${({ theme }) => theme.spacing(4)}px
    ${({ theme }) => theme.spacing(6)}px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-conent: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding-left: ${({ theme, navbarVisibility }) => navbarVisibility ? theme.navbarWidth + theme.spacing(6) : theme.spacing(6)}px;
  }
`;
