import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
  Chip,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const FoldersTable = ({
  folders,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedFolder,
  setDeleteModalVisible,
  setFolderModalVisible,
}) => {
  const history = useHistory();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema opisu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('description', sorters)}
                direction={getSortDirection('description', sorters)}
                onClick={() => handleSortChange('description', sorters, setSorters)}
              >
                Opis
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell width={140} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !folders ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : folders?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan folder</Typography>
            </TableCell>
          </TableRow>
        ) : (
          folders?.map((folder) => (
            <TableRow key={folder.id}>
              <TableCell width={50}> {folder.id}</TableCell>
              <TableCell width={100}>
                <Chip
                  label={folder.name}
                  style={{ backgroundColor: folder?.color, minWidth: '150px' }}
                />
              </TableCell>
              <TableCell width={150}> {folder.description}</TableCell>
              <TableCell width={100}>
                {dateFormatterDate(folder.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(folder.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={140} align="right">
                <>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi folder">
                    <IconButton
                      onClick={() => {
                        setSelectedFolder(folder);
                        setFolderModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Izbriši folder">
                    <IconButton
                      onClick={() => {
                        setSelectedFolder(folder);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteOutlined />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Pregled Podfoldera">
                    <span>
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `/folders/${folder.id}`,
                          })
                        }
                      >
                        <SvgIcon fontSize="small">
                          <ArrowForwardIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default FoldersTable;
