import React from 'react';
import { Box } from '@material-ui/core';

const TabPanel = (props) => {
  const { theme, children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={
            style || {
              paddingLeft: `${theme.spacing(6)}px`,
              paddingRight: `${theme.spacing(6)}px`,
            }
          }
        >
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
