import styled from 'styled-components';
import { TableCell as MuiTableCell, Box as MuiBox } from '@material-ui/core';

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const ExpandableRowCell = styled(MuiTableCell)`
  border-bottom: 0;
`;

export const ExpandedRow = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px 0;
  margin: ${({ theme }) => theme.spacing(5)}px 0;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const Wrapper = styled.div`
  & span {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  & span ul li:hover {
    font-size: 11px;
  }

  .red {
    color: ${({ theme }) => theme.palette.error.main}
  }
  .green {
    color: ${({ theme }) => theme.palette.success.main}
  }
  .orange {
    color: ${({ theme }) => theme.palette.warning.main}
  }
`;
