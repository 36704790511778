export const handleChipDelete = (chip, setFilters, setPage) => {
  if(setPage) setPage(0);
  setFilters((prevState) => ({
    ...prevState,
    [chip]: undefined,
  }));
};

export const handleFilterChange = (filterValues, setFilters, setPage) => {
  if(setPage) setPage(0);
  setFilters({ ...filterValues });
};
