import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';

import { Wrapper, LinearProgress } from './style';

const LinearWithValueLabel = ({ theme, value, buffer = 100, small = false }) => {
  return (
    <Wrapper>
      <Box display="flex" alignItems="center" flexDirection={small ? 'column' : 'row'}>
        <Box width={small ? '100%' : '67%'} mr={1}>
          <LinearProgress variant="buffer" value={value} valueBuffer={buffer} />
        </Box>
        <Box width={small ? '100%' : '33%'}>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <Typography
                variant="body2"
                align="right"
                style={{ color: value >= buffer ? theme.palette.success.main : 'textSecondary' }}
              >
                {`${Math.round(value)}/${Math.round(buffer)}%`}
              </Typography>
            </Grid>
            <Grid item>
              {value >= buffer && <DoneIcon style={{ color: theme.palette.success.main }} />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default LinearWithValueLabel;
