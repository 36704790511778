import GroupIcon from '@material-ui/icons/Group';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import AdjustIcon from '@material-ui/icons/Adjust';

const navConfig = [
  {
    subheader: 'PORUKE',
    icon: true,
    items: [
      {
        title: 'Poruke o dokumentu',
        icon: MailIcon,
        href: '/messages-documents',
      },
      {
        title: 'Informativne poruke',
        icon: ContactMailIcon,
        href: '/messages-informations',
      },
      {
        title: 'Pročitane poruke',
        icon: MailOutlineIcon,
        href: '/messages-read',
      },
    ],
  },
  {
    subheader: 'DMS',
    items: [
      {
        title: 'Pregled i pretraga dokumenata',
        href: '/',
        items: [
          {
            title: 'Pretraga dokumenata po metapodacima',
            icon: FindInPageIcon,
            href: '/document-attributes',
            roles: ['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK', 'DMS_PRIDRUZIVANJE_ODG_OSOBE'],
          },
          {
            title: 'Dokumenti',
            icon: DescriptionIcon,
            href: '/documents',
            roles: ['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK', 'DMS_PRIDRUZIVANJE_ODG_OSOBE'],
          },
        ],
      },
      {
        title: 'Usaglašavanje dokumenata',
        href: '/',
        items: [
          {
            title: 'Pridruživanje odgovorne osobe',
            icon: AccountBoxIcon,
            href: '/responsible-organization',
            roles: ['DMS_PROTOKOL', 'DMS_PRIDRUZIVANJE_ODG_OSOBE'],
          },
          {
            title: 'Usaglašavanje dokumenata',
            icon: AssignmentIndIcon,
            href: '/accordance',
            roles: ['DMS_USAGLASAVANJE_DOK'],
          },
          {
            title: 'Pregled dokumenata',
            icon: SearchIcon,
            href: '/overview',
            roles: ['DMS_PROTOKOL', 'DMS_USAGLASAVANJE_DOK'],
          },
        ],
      },
      {
        title: 'Šifarnici',
        href: '/',
        items: [
          {
            title: 'Folderi',
            icon: FolderIcon,
            href: '/folders',
          },
          {
            title: 'Grupe saglasnosti',
            icon: GroupWorkIcon,
            href: '/group-accordances',
            roles: ['DMS_GRUPE_SAGLASNOSTI'],
          },
          {
            title: 'Tipovi dokumenata',
            icon: InsertDriveFileIcon,
            href: '/document-types',
            roles: ['DMS_SIFARNICI'],
          },
          {
            title: 'Grupe atributa',
            icon: AdjustIcon,
            href: '/attribute-groups',
            roles: ['DMS_SIFARNICI'],
          },

          {
            title: 'Atributi',
            icon: ListIcon,
            href: '/attributes',
            roles: ['DMS_SIFARNICI'],
          },
          {
            title: 'Tipovi atributa',
            icon: CalendarViewDayIcon,
            href: '/attribute-types',
            roles: ['DMS_SIFARNICI'],
          },
          {
            title: 'Korisnici za skeniranje',
            icon: GroupIcon,
            href: '/scan-users',
            roles: ['DMS_KORISNICI_SKENIRANJE'],
          },
        ],
      },
    ],
  },
];

export default navConfig;
