import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatter } from '@shared/components';
import { ErrorMessage } from './style';

const LogTable = ({
  logs,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={300} align="left">
            <Tooltip
              enterDelay={300}
              placement="bottom-start"
              title='Sortiraj prema datumu i vremenu prijave'
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum i vrijeme prijave na aplikaciju
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="7" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !logs ? (
          <TableRow>
            <TableCell align="center" colSpan="7">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : logs?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="7" height={80}>
              <Typography>
                Nije pronađen niti jedan log o prijavi korisnika na aplikaciju
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          logs?.map((log) => (
            <TableRow
              style={{
                borderBottom: 0,
              }}
              key={log.id}
            >
              <TableCell width={300}>{dateFormatter(log.dateCreated, true)}</TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LogTable;
