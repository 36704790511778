import React from 'react';
import Box from '@material-ui/core/Box';
import {
  Main,
  Wrapper,
  Content,
  Title,
  Subtitle,
  Paragraph,
  RotateImage,
  Button,
  LinkWrapper,
} from './style';

const Error404 = ({ homePageURL }) => (
  <Main>
    <Wrapper>
      <Content>
        <Title>
          4
          <span>
            <RotateImage src={`${process.env.PUBLIC_URL}/logo.png`} alt="Not Found" />
          </span>
          4
        </Title>
        <Subtitle>Error: 404 Page Not Found</Subtitle>
        <Paragraph>Tražena stranica ne postoji</Paragraph>
        <Box m={10}>
          <LinkWrapper href={homePageURL}>
            <Button variant="contained" color="primary">
              Nazad na početnu
            </Button>
          </LinkWrapper>
        </Box>
      </Content>
    </Wrapper>
  </Main>
);

export default Error404;
