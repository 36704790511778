import * as yup from 'yup';

export const userDocumentTypeSchema = yup.object().shape({
    ordNum: yup
        .number()
        .min(0, 'Redni broj mora biti pozitivan broj')
        .integer('Redni broj mora biti cijeli broj')
        .transform((value, original) => (original === '' ? null : value))
        .typeError('Redni broj mora biti broj')
        .nullable(),
    documentType: yup
        .number()
        .typeError('Tip dokumenta je obavezno polje')
        .required('Tip dokumenta je obavezno polje'),
});