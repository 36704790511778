import styled from 'styled-components';
import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from '@material-ui/core';

export const AppBar = styled(MuiAppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 100};
  box-shadow: none;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Toolbar = styled(MuiToolbar)`
  min-height: ${({ theme }) => theme.topbarHeight}px;
`;