import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TextField, InputAdornment, IconButton, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
  formatKeyboardDatePicker,
  reverseFormatKeyboardDatePicker,
} from '../utils/dateFormatter';

let searchTimeout;

const QuickSearch = ({
  name,
  setFilters,
  mainFilter,
  placeholder,
  setPage,
  type = 'text',
  fullWidth,
  ...rest
}) => {
  if (type !== 'text' && type !== 'date' && type !== 'textFieldDate') type = 'text';

  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleVisible = () => {
    setVisible(false);
    setInputValue(null);
    setFilters((prev) => ({
      ...prev,
      [name]: undefined,
    }));
  };

  const handleChange = (event) => {
    let value = null;

    if (type !== 'textFieldDate') {
      value = event?.target?.value;
    } else {
      value = event;
    }
    clearTimeout(searchTimeout);
    setVisible(true);
    setInputValue(value);
    if (value?.length === 0) {
      setVisible(false);
    }
    if (setPage) setPage(0);
    searchTimeout = setTimeout(
      () =>
        setFilters((prev) => ({
          ...prev,
          [name]:
            type !== 'textFieldDate'
              ? value.length === 0
                ? undefined
                : value
              : !value?.$y
                ? undefined
                : formatKeyboardDatePicker(value),
        })),
      500,
    );
  };

  useEffect(() => {
    if (Object.keys(mainFilter).length === 0 || !mainFilter[name]) {
      setInputValue(null);
      setVisible(false);
    } else if (mainFilter[name]) {
      if (type !== 'textFieldDate') setInputValue(mainFilter[name]);
      else setInputValue(reverseFormatKeyboardDatePicker(mainFilter[name]));
      setVisible(true);
    }
  }, [mainFilter]);

  return (
    <>
      {type === 'text' ? (
        <TextField
          style={{ width: fullWidth ? '100%' : '40%' }}
          placeholder={placeholder}
          size="small"
          variant="outlined"
          value={inputValue || ''}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ paddingRight: '5px', marginLeft: '-6px' }} position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment style={{ paddingLeft: '5px', marginRight: '-6px' }} position='end'>
                <IconButton
                  size="small"
                  onClick={handleVisible}
                  style={{ visibility: visible ? 'visible' : 'hidden' }}
                >
                  <CloseIcon size="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      ) : type === 'textFieldDate' ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            clearable
            style={{ width: '40%' }}
            autoOk
            size="small"
            format="DD/MM/YY"
            placeholder={placeholder}
            name={name}
            value={!mainFilter?.[name] ? null : inputValue}
            InputLabelProps={{
              shrink: true,
            }}
            inputVariant="outlined"
            onChange={handleChange}
            cancelLabel="Odustani"
            clearLabel="Poništi"
            okLabel="Primijeni"
            invalidDateMessage="Datum nije unesen u ispravnom obliku"
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ paddingRight: '5px', marginLeft: '-6px' }} position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment
              //     style={{
              //       marginRight: inputValue && inputValue !== '' ? '-6px' : '-40px',
              //     }}
              //   >
              //     <IconButton
              //       size="small"
              //       onClick={handleVisible}
              //       style={{ visibility: visible ? 'visible' : 'hidden' }}
              //     >
              //       <CloseIcon size="small" />
              //     </IconButton>
              //   </InputAdornment>
              // ),
            }}
            {...rest}
          />
          <IconButton
            size="small"
            onClick={handleVisible}
            style={{ position: 'relative', visibility: visible ? 'visible' : 'hidden' }}
          >
            <CloseIcon
              style={{
                position: 'absolute',
                left: -27.2,
                top: -2.4,
              }}
              size="small"
            />
          </IconButton>
        </MuiPickersUtilsProvider>
      ) : (
        <TextField
          style={{ width: '40%' }}
          type="date"
          fullWidth
          placeholder={placeholder}
          size="small"
          format="DD/MM/YYY"
          value={inputValue || ''}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ paddingRight: '5px', marginLeft: '-6px' }} position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                style={{
                  marginRight: inputValue && inputValue !== '' ? '-6px' : '-40px',
                }}
              >
                <IconButton
                  size="small"
                  onClick={handleVisible}
                  style={{ visibility: visible ? 'visible' : 'hidden' }}
                >
                  <CloseIcon size="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      )}
    </>
  );
};

export default QuickSearch;
