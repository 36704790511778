import React, { createContext, useState } from 'react';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [successAlertData, setSuccessAlertData] = useState({});
  const [errorAlertData, setErrorAlertData] = useState({});

  const sendSuccessAlertData = (message) => {
    setSuccessAlertData({
      visible: true,
      message,
    });
  };

  const sendErrorAlertData = (message) => {
    setErrorAlertData({
      visible: true,
      message,
    });
  };

  const handleSuccessAlertClose = () =>
    setSuccessAlertData((prevState) => ({ ...prevState, visible: false }));

  const handleErrorAlertClose = () =>
    setErrorAlertData((prevState) => ({ ...prevState, visible: false }));

  const alertContext = {
    successAlertData,
    errorAlertData,
    setSuccessAlertData,
    setErrorAlertData,
    sendSuccessAlertData,
    sendErrorAlertData,
    handleSuccessAlertClose,
    handleErrorAlertClose,
  };

  return <Context.Provider value={alertContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export default Context;
