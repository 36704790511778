import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import UpdateIcon from '@material-ui/icons/Update';
import { TableSkeleton } from '@shared/components';
import { ErrorMessage } from './style';
import { getSortDirection, handleSortChange } from '../../../../utils/sortHelpers';
import useTheme from '../../../../hooks/useTheme';

const WorkerOrganizationList = ({
  loading,
  error,
  workersOrganizations,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  sorters,
  setSorters,
}) => {
  const theme = useTheme();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={30}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={120}>
            <Tooltip enterDelay={300} title="Sortiraj prema organizaciji">
              <TableSortLabel
                active={!!getSortDirection('organizationName', sorters)}
                direction={getSortDirection('organizationName', sorters)}
                onClick={() => handleSortChange('organizationName', sorters, setSorters)}
              >
                Organizacija
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={120}>
            <Tooltip enterDelay={300} title="Sortiraj prema radniku">
              <TableSortLabel
                active={!!getSortDirection('workerName', sorters)}
                direction={getSortDirection('workerName', sorters)}
                onClick={() => handleSortChange('workerName', sorters, setSorters)}
              >
                Radnik
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={20}>
            <Tooltip enterDelay={300} title="Sortiraj prema statusu saglasnosti">
              <TableSortLabel
                active={!!getSortDirection('agreed', sorters)}
                direction={getSortDirection('agreed', sorters)}
                onClick={() => handleSortChange('agreed', sorters, setSorters)}
              >
                Saglasan
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={20}>
            <Tooltip enterDelay={300} title="Sortiraj prema broju podsjetnika">
              <TableSortLabel
                active={!!getSortDirection('numberOfReminders', sorters)}
                direction={getSortDirection('numberOfReminders', sorters)}
                onClick={() => handleSortChange('numberOfReminders', sorters, setSorters)}
              >
                Broj podsjetnika
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={rowsPerPage * 37}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !workersOrganizations ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ padding: '0' }} height={rowsPerPage}>
              <TableSkeleton size={rowsPerPage} cellHeight={30} />
            </TableCell>
          </TableRow>
        ) : workersOrganizations?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={75.33 * rowsPerPage}>
              <Typography>Nije pronađen niti jedan radnik/organizacija</Typography>
            </TableCell>
          </TableRow>
        ) : (
          workersOrganizations?.map((workerOrganization) => (
            <TableRow key={workerOrganization.id}>
              <TableCell width={30}>{workerOrganization.id}</TableCell>
              <TableCell width={120}>{workerOrganization.organizationName}</TableCell>
              <TableCell width={120}>{workerOrganization.workerName}</TableCell>
              <TableCell width={30}>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title={
                    workerOrganization.agreed === 'D'
                      ? 'Da'
                      : workerOrganization.agreed === 'N'
                      ? 'Ne'
                      : 'U toku'
                  }
                >
                  {workerOrganization.agreed === 'D' ? (
                    <DoneIcon style={{ color: theme.palette.success.main }} />
                  ) : workerOrganization.agreed === 'N' ? (
                    <CloseIcon style={{ color: theme.palette.error.main }} />
                  ) : (
                    <UpdateIcon style={{ color: theme.palette.warning.main }} />
                  )}
                </Tooltip>
              </TableCell>
              <TableCell width={120}>{workerOrganization.numberOfReminders}</TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default WorkerOrganizationList;
