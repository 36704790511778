import React, { useState } from 'react';
import { alpha } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { Alert, TreeView } from '@material-ui/lab';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import useAPI from '../hooks/useAPI';
import StyledTreeItem from '../StyledTreeItem';
import { Box } from './style';
import Spinner from '../Spinner';

const FolderView = ({
  setSelected,
  expanded,
  setSelectedName = () => {},
  defaultSelected,
  theme,
  axios,
  fullHeight,
}) => {
  const [data, setData] = useState([]);
  const [dataIds, setDataIds] = useState(['0']);

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      color={nodes.color || theme.palette.text.secondary}
      bgColor={nodes.color ? alpha(nodes.color, 0.06) : alpha(theme.palette.text.secondary, 0.04)}
      onIconClick={(event) => {
        event.preventDefault();
        if (dataIds.includes(nodes.id))
          setDataIds((prev) => {
            return prev.filter((id) => id.toString() !== nodes.id);
          });
        else setDataIds((prev) => [...prev, nodes.id.toString()]);
      }}
      onClick={() => {
        setSelected(nodes.id);
        setSelectedName(nodes.name);
      }}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </StyledTreeItem>
  );

  const traverseFolders = (node, id) => {
    if (node.id === id) return node;
    let parent = null;
    for (let i = 0; i < node.children.length; i += 1) {
      parent = traverseFolders(node.children[i], id);
      if (parent != null) return parent;
    }
    return parent;
  };

  const processFolders = (folderData) => {
    const dataTemp = [];
    for (let i = 0; i < folderData.length; i += 1) {
      const folder = folderData[i];
      if (!folder.parent) {
        dataTemp.push({ ...folder, id: folder.id?.toString(), children: [] });
      } else {
        for (let j = 0; j < dataTemp.length; j += 1) {
          const node = traverseFolders(dataTemp[j], folder.parent?.toString());
          if (node) {
            j = dataTemp.length;
            node.children.push({ ...folder, id: folder.id?.toString(), children: [] });
          }
        }
      }
    }
    setData({ id: '0', name: 'Svi', children: dataTemp });
  };

  const [, error, loading] = useAPI(
    () =>
      axios.get(`folders-all`).then(({ data: dataNodes }) => {
        if (expanded)
          setDataIds((prev) => [...prev, ...dataNodes.nodes.map((node) => node.id?.toString())]);
        processFolders(dataNodes.nodes);
        return dataNodes;
      }),
    [],
  );

  return error ? (
    <Box display="flex" justifyContent="center">
      <Alert severity="error" color="info">
        Došlo je do greške prilikom učitavanja hijerarhije foldera!
      </Alert>
    </Box>
  ) : loading || !data ? (
    <Box display="flex" justifyContent="center">
      <Spinner />
    </Box>
  ) : (
    <Box
      mx={2}
      style={{
        borderRight: `1px solid ${theme.palette.primary.main}`,
        minHeight: fullHeight ? '-webkit-fill-available' : 'auto',
      }}
    >
      <TreeView
        defaultCollapseIcon={<FolderOpenIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<FolderIcon />}
        expanded={dataIds}
        defaultSelected={defaultSelected}
        // {...(expanded && { expanded: dataIds })}
        // onNodeSelect={(event, value) => {
        //   if (dataIds.includes(value))
        //     setDataIds((prev) => {
        //       return prev.filter((id) => id.toString() !== value);
        //     });
        //   else setDataIds((prev) => [...prev, value]);
        // }}
      >
        {/* {data?.map((node) => renderTree(node))} */}
        {renderTree(data)}
      </TreeView>
    </Box>
  );
};

export default FolderView;
