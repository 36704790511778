import styled from 'styled-components';
import { Box, LinearProgress as MuiLinearProgress } from '@material-ui/core';


export const Wrapper = styled(Box)`
  width: 100%;
`;


export const LinearProgress = styled(MuiLinearProgress)`
  .MuiLinearProgress-dashed {
    animation: none;
  }
`;
