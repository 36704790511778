import React, { createContext, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default-member
import { useAPISearch } from '@shared/components';
import axios from '../utils/axios';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [triggerRerender, setRerender] = useState(false);
  const [
    count = {
      documentMessagesCount: 0,
      documentMessagesCountinfoMessagesCount: 0,
    },
    error,
    loading,
  ] = useAPISearch(() => axios.get(`messages/count`).then(({ data }) => data), [triggerRerender]);

  const markMessageAsRead = async (id) => {
    try {
      await axios.put(`/messages/${id}/mark-as-read`);
      return {
        type: 'success',
        message: 'Poruke uspješno označene kao pročitane',
      };
    } catch (errors) {
      return {
        type: 'error',
        message:
          errors?.response?.data?.details?.reason ||
          errors?.response?.data?.error?.message ||
          `Došlo je do greške prilikom učitavanja`,
      };
    }
  };

  const markAllDocumentMessagesAsRead = async (documentId) => {
    try {
      await axios.put(`/messages/${documentId}/mark-all-as-read`);
      return {
        type: 'success',
        message: 'Poruke uspješno označene kao pročitane',
      };
    } catch (errors) {
      return {
        type: 'error',
        message:
          errors?.response?.data?.details?.reason ||
          errors?.response?.data?.error?.message ||
          `Došlo je do greške prilikom učitavanja`,
      };
    }
  };

  const markAllInfoMessagesAsRead = async () => {
    try {
      await axios.put(`/messages/mark-all-info-as-read`);
      return {
        type: 'success',
        message: 'Poruke uspješno označene kao pročitane',
      };
    } catch (errors) {
      return {
        type: 'error',
        message:
          errors?.response?.data?.details?.reason ||
          errors?.response?.data?.error?.message ||
          `Došlo je do greške prilikom učitavanja`,
      };
    }
  };

  const notificationContext = {
    error,
    loading,
    triggerRerender,
    setRerender,
    numberOfDocumentMessages: count?.documentMessagesCount || 0,
    numberOfInfoMessages: count?.infoMessagesCount || 0,
    markMessageAsRead,
    markAllDocumentMessagesAsRead,
    markAllInfoMessagesAsRead,
  };

  return <Context.Provider value={notificationContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export default Context;
