const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .filter((val) => !val.includes(')'))
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

export default getInitials;
