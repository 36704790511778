import styled from 'styled-components';
import {
  ListItem as MuiListItem,
  Button as MuiButton,
  makeStyles,
} from '@material-ui/core';

export const ListItem = styled(MuiListItem)`
  display: block;
  padding-top: 0;
  padding-bottom: 0;
`;

export const Button = styled(MuiButton)`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(3)}px`};
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  align-items: center;

  & svg {
    display: flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const ItemLeaf = styled(MuiListItem)`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
`;

export const Title = styled.span`
  margin-right: auto;
`;

export const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& span': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));
