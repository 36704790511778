import styled from 'styled-components';

export const StyledLogo = styled.h1`
  color: ${({ theme, light }) => (light ? '#fff' : theme.palette.primary.main)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  span {
    color: ${({ theme, light }) =>
      light ? theme.palette.secondary.light : theme.palette.secondary.main};
  }
`;
