import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import mime from 'mime-types';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, Spinner, dateFormatterTime, dateFormatterDate  } from '@shared/components';
import { ErrorMessage } from './style';
import axios from '../../../../../utils/axios';
import useGlobalAlertContext from '../../../../../hooks/useGlobalAlertContext';

const List = ({
  documentAttachments,
  tableColumns,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
  setSelectedDocumentAttachment,
  selectedDocumentAttachment,
  // setModalVisible,
  // setDeleteModalVisible,
}) => {
  const { sendErrorAlertData } = useGlobalAlertContext();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const getAttachmentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-attachment/${id}`);
      const { attachmentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(attachmentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (attachmentError) {
      sendErrorAlertData(
        `${
          attachmentError?.response?.data?.details?.reason ||
          attachmentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja priloga`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocumentAttachment(undefined);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableColumns.map((column) => (
            <TableCell width={column.width} key={column.value} align={column.align}>
              <Tooltip
                enterDelay={300}
                placement="bottom-start"
                title={`Sortiraj prema ${column.placeholder}`}
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={100} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documentAttachments ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documentAttachments?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan prilog dokumenta</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documentAttachments?.map((documentAttachment) => (
            <TableRow
              style={{
                borderBottom: 0,
              }}
              key={documentAttachment.id}
            >
              <TableCell width={150}>{documentAttachment.name}</TableCell>
              <TableCell width={150}>
                {dateFormatterDate(documentAttachment.timeCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(documentAttachment.timeCreated)}
                </Typography>
              </TableCell>
              <TableCell width={150}>{documentAttachment.extension}</TableCell>
              <TableCell width={150}>{documentAttachment.fileSize}</TableCell>
              <TableCell width={150}>{documentAttachment.description}</TableCell>
              <TableCell width={100} align="right">
                <>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      downloadLoading && documentAttachment?.id === selectedDocumentAttachment?.id
                        ? ''
                        : downloadLoading
                        ? 'Nije moguće prikazati prilog'
                        : 'Prikaži prilog'
                    }
                  >
                    {downloadLoading &&
                    documentAttachment?.id === selectedDocumentAttachment?.id ? (
                      <span>
                        <IconButton size="small" disabled>
                          <Box pr={3}>
                            <Spinner color="primary" size={20} />
                          </Box>
                        </IconButton>
                      </span>
                    ) : (
                      <span>
                        <IconButton
                          disabled={downloadLoading}
                          onClick={() => {
                            setSelectedDocumentAttachment(documentAttachment);
                            getAttachmentFile(documentAttachment.id, documentAttachment.extension);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <InsertDriveFileOutlinedIcon />
                          </SvgIcon>
                        </IconButton>
                      </span>
                    )}
                  </Tooltip>
                  {/* <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title="Uredi prilog dokumenta"
                >
                  <Box display="inline">
                    <IconButton
                      onClick={() => {
                        setSelectedDocumentAttachment(documentAttachment);
                        setModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title="Obriši prilog dokumenta"
                >
                  <IconButton
                    onClick={() => {
                      setSelectedDocumentAttachment(documentAttachment);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <DeleteOutlined />
                    </SvgIcon>
                  </IconButton>
                </Tooltip> */}
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default List;
