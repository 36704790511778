import styled from 'styled-components';
import { alpha } from '@material-ui/core';

export const Label = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  align-items: center;
  border-radius: 6px;
  display: flex;
  cursor: default;
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  height: 30;
  justify-content: center;
  letter-spacing: 0.5;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  text-transform: uppercase;
  text-align: center;

  &.neutral {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${alpha('#263238', 0.05)};
  }

  &.primary {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${alpha('#003459', 0.05)};
  }
  &.secondary {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: ${alpha('#00a8e8', 0.05)};
  }

  &.error {
    color: ${({ theme }) => theme.palette.error.main};
    background-color: ${alpha('#c02428', 0.05)};
  }
  &.success {
    color: ${({ theme }) => theme.palette.success.main};
    background-color: ${alpha('#439b73', 0.05)};
  }
  &.warning {
    color: ${({ theme }) => theme.palette.warning.main};
    background-color: ${alpha('#ff8f2a', 0.05)};
  }
  &.info {
    color: ${({ theme }) => theme.palette.info.main};
    background-color: ${alpha('#4b6cb7', 0.05)};
  }
`;
