import React from 'react';
import Chip from '@material-ui/core/Chip';
import { chipStyle } from './style';

const TagChip = ({ label, ...rest }) => {
  const classes = chipStyle();
  return (
    <Chip className={classes.root} color="primary" variant="outlined" label={label} {...rest} />
  );
};

export default TagChip;
