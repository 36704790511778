import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

const Spinner = ({ ...rest }) => {
  return (
    <Box padding="0.5px">
      <CircularProgress {...rest} />
    </Box>
  );
};

export default Spinner;
