import React, { useState, useEffect } from 'react';
import {
  IconButton,
  SvgIcon,
  Grid,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Modal from '../Modal';
import Spinner from '../Spinner';

const DeleteModal = ({
  objectId,
  setObject = null,
  title,
  path,
  message,
  buttonText,
  promptText,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setPage,
  page,
  dataLength,
  resetObject,
  setRerender,
  theme = {},
  axios,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const onClose = () => {
    resetObject();
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { data } = axios && (await axios.delete(`${path}/${objectId}`));
      if (setObject) setObject({ ...data });
      if (!data) throw new Error();
      sendSuccessAlertData(message);
      if (dataLength === 1 && page > 0) setPage((prev) => prev - 1);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${error?.response?.data?.details?.reason
          ? error.response.data.details.reason
          : `Došlo je do greške prilikom brisanja`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Modal
      open={visible}
      // onBackdropClick={handleClose}
      // onEscapeKeyDown={handleClose}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          handleClose();
        }
      }}
      handleExit={onClose}
      showHeader
      cardHeaderProps={{
        title: <Typography variant="h5">{title} ?</Typography>,
        action: (
          <IconButton onClick={handleClose} size="small">
            <SvgIcon fontSize="small" style={{ color: '#fff' }}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        ),
      }}
      footer={
        <Box padding={`${theme.spacing(5)}px`}>
          <Grid container spacing={5} justifyContent="flex-end">
            <Grid item>
              <Button onClick={handleClose}>
                <Typography variant="body2">Odustani</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                variant="contained"
                style={{
                  backgroundColor: loading || theme.palette.error.main,
                }}
                color="primary"
                onClick={onSubmit}
                startIcon={
                  loading ? (
                    <Spinner
                      size={16}
                      style={{ color: loading ? '#c02428' : '#fff' }}
                    />
                  ) : (
                    <DeleteOutlineIcon />
                  )
                }
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box
        minHeight={80}
        padding={`${theme.spacing(5)}px`}
        paddingBottom={`${theme.spacing(7)}px`}
      >
        <Alert severity="error">
          <AlertTitle>Upozorenje</AlertTitle>
          Nakon brisanja podatke nije moguće vratiti.
        </Alert>
        <br />
        <Typography style={{ padding: `${theme.spacing(4)}px 0 0` }}>
          {promptText}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
