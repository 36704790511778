import React from 'react';
import { Fade, Card, Divider, Typography } from '@material-ui/core';
import { ModalWrapper, CardHeader } from './style';

const Modal = ({
  children,
  open,
  footer: Footer,
  cardProps,
  showHeader,
  cardHeaderProps,
  handleExit,
  ...rest
}) => {
  const title = cardHeaderProps?.title;
  if (title) delete cardHeaderProps.title;

  return (
    <ModalWrapper open={open} {...rest}>
      <Fade in={open} onExited={handleExit}>
        <Card {...cardProps}>
          {showHeader && (
            <>
              <CardHeader
                title={
                  typeof title === 'string' ? (
                    <Typography variant="subtitle1">{title}</Typography>
                  ) : (
                    title
                  )
                }
                {...cardHeaderProps}
              />
              <Divider />
            </>
          )}
          {children}
          {Footer && (
            <>
              <Divider />
              {Footer}
            </>
          )}
        </Card>
      </Fade>
    </ModalWrapper>
  );
};

export default Modal;
