import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';

export const Box = styled(MuiBox)`
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50vh;
    padding: 5px;
`;
