import React, { useState, useEffect } from 'react';
import { Box, TextField, FormHelperText } from '@material-ui/core';

const FormTextArea = ({
  object = {},
  errors = {},
  label,
  name,
  placeholder,
  maxLength = 2000,
  register,
  disabled = false,
  rows = 5,
  variant = 'outlined',
  boxStyles,
  textFieldStyles,
  onKeyDown,
  onKeyPress,
  inputProps,
}) => {
  const [textAreaLength, setTextAreaLength] = useState(0);

  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    textAreaLength === 0 && setTextAreaLength(object && object[name] ? object[name].length : 0);
  }, [object]);

  return (
    <Box mt={5} {...boxStyles}>
      <TextField
        {...textFieldStyles}
        multiline
        disabled={disabled}
        minRows={rows}
        error={!!errors[name]}
        fullWidth
        variant={variant}
        label={label}
        name={name}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        id={`ta_${name}-id`}
        defaultValue={object && object[name] ? object[name] : ''}
        onChange={({ target: { value } }) => setTextAreaLength(value.length)}
        helperText={`${textAreaLength}/${maxLength}`}
        inputProps={{ maxLength, ...inputProps }}
        placeholder={placeholder}
        inputRef={register}
      />
      {errors[name] && <FormHelperText error>{errors[name].message}</FormHelperText>}
    </Box>
  );
};

export default FormTextArea;
