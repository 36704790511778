import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
const localTimezone = 'Europe/Sarajevo';
dayjs.tz.setDefault(localTimezone);

export const dateFormatter = (date, getTime = false) => {
  if (!date) return '';
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  return dayjs
    .utc(date)
    .tz(localTimezone, true)
    .format(getTime ? 'DD.MM.YYYY. HH:mm' : 'DD.MM.YYYY.');
};

export const formatDateInput = (date, time = false) => {
  if (!date) return null;
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  return dayjs(date).format(time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
};

export const formatDateAttributeField = (date, time = false) => {
  if (!date) return null;
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  return dayjs(date).format(time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY');
};

export const manualFormatDateInput = (month, day) => {
  const year = dayjs().year();
  const date = dayjs(`${month}-1-${year}`);
  return day
    ? date.format('YYYY-MM-DD')
    : dayjs(`${month}-${date?.daysInMonth()}-${year}`).format('YYYY-MM-DD');
};

export const formatKeyboardDatePicker = (date) => {
  if (!date) return null;

  return dayjs(date).format('DD/MM/YY');
};

export const formatKeyboardTimePicker = (date) => {
  if (!date) return null;
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  return dayjs(date).format('HH:MM');
};

export const reverseFormatKeyboardDatePicker = (dateString) => {
  if (!dateString) return null;
  const dateArray = dateString.split('/');
  return new Date(dateArray[2], parseInt(dateArray[1], 10) - 1, dateArray[0]);
};

export const reverseFormatKeyboardTimePicker = (dateString) => {
  return dateString;
};

export const monthNames = [
  { id: 1, name: 'Januar' },
  { id: 2, name: 'Februar' },
  { id: 3, name: 'Mart' },
  { id: 4, name: 'April' },
  { id: 5, name: 'Maj' },
  { id: 6, name: 'Juni' },
  { id: 7, name: 'Juli' },
  { id: 8, name: 'August' },
  { id: 9, name: 'Septembar' },
  { id: 10, name: 'Oktobar' },
  { id: 11, name: 'Novembar' },
  { id: 12, name: 'Decembar' },
];

export const getMonthName = (month) => {
  if (month > 0 && month < 13) return monthNames[month - 1].name;

  return null;
};

export const dateShort = (date) => {
  if (!date) return '';
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  // return date with Month Day format using monthNames array
  return `${getMonthName(dayjs(date).month() + 1)} ${dayjs(date).date()}`;
};

export const dateFormatterDate = (date) => {
  if (!date) return '';
  return dayjs.utc(date).tz(localTimezone, true).format('DD.MM.YYYY');
};

export const dateFormatterTime = (date) => {
  if (!date) return '';
  return dayjs.utc(date).tz(localTimezone, true).format('HH:mm:ss');
};

export const daysUntil = (date) => {
  if (!date) return '';
  // date = typeof date === 'string' ? date.replace('Z', '').replace('T', ' ') : date;

  return dayjs(date).diff(dayjs(), 'days');
};

export const dateNow = () => {
  return dayjs().tz(localTimezone).utc(true);
};
