const { sessionStorage } = window;

export const getStorageData = (keyName, locationState) => {
  if (locationState) {
    const prevState = JSON.parse(sessionStorage.getItem('sberbank-session'));
    sessionStorage.setItem(
      'sberbank-session',
      JSON.stringify({
        ...prevState,
        [keyName]: JSON.stringify(locationState),
        creation: Date.now(),
      }),
    );
  } else {
    const sessionStorageJSON = sessionStorage.getItem('sberbank-session');
    if (sessionStorageJSON) {
      const routeState = JSON.parse(sessionStorageJSON);
      if (routeState[keyName]) return JSON.parse(routeState[keyName]);
    }
  }
  return locationState;
};

export const clearStorageItem = (storageItem) => {
  const sessionStorageJSON = sessionStorage.getItem('sberbank-session');
  if (sessionStorageJSON) {
    const storageObject = JSON.parse(sessionStorageJSON);
    if (storageObject[storageItem]) {
      delete storageObject[storageItem];
      sessionStorage.setItem('sberbank-session', JSON.stringify(storageObject));
    }
  }
};

export const clearStorageData = () => {
  sessionStorage.removeItem('serbank-session');
};
