export default (str, s = 38, l = 55) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = Math.abs(hash % 360);
  h = roundToNearest(scaleValueToRange(h), 20); // Updated to round to nearest 20
  s = clampValue(s, 0, 100); // Added clamp to ensure saturation is within valid range
  l = clampValue(l, 0, 100); // Added clamp to ensure lightness is within valid range

  return `hsl(${h}, ${s}%, ${l}%)`;
};

// Helper function to round a value to the nearest multiple of a given number
const roundToNearest = (value, multiple) => {
  return Math.round(value / multiple) * multiple;
};

// Helper function to scale a value to a desired range of 0 to 360
const scaleValueToRange = (value) => {
  return (value * 360) / 10000;
};

// Helper function to clamp a value within a specified range
const clampValue = (value, min, max) => {
  return Math.max(min, Math.min(max, value));
};
