export default (chip, filters) => {
  let value;
  if (chip === 'status') {
    value = filters[chip] === 'A' ? 'Aktivan' : 'Pasivan';
  } else if (
    chip === 'isValid' ||
    chip === 'valid' ||
    chip === 'ongoing' ||
    chip === 'isOrganization' ||
    chip === 'isWorker' ||
    chip === 'adjustmentTemplate' ||
    chip === 'budget' ||
    chip === 'officeCode' ||
    chip === 'mass'
  ) {
    value = filters[chip] === 'D' ? 'DA' : 'NE';
  } else {
    value = filters[chip];
  }
  return value;
};
