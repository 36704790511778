import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Hidden, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from '../Logo';
import Account from './Account';
import Notifications from './Notifications';
import { Toolbar, AppBar } from './style';

const Topbar = ({ onMobileNavOpen, navbarVisibility, toggleNavbar, ...rest }) => {
  const history = useHistory();

  return (
    <AppBar {...rest}>
      <Toolbar>
        <Hidden mdDown>
          <Tooltip
            title={navbarVisibility ? 'Sakrijte navigaciju' : 'Prikažite navigaciju'}
            placement="right-start"
            enterDelay={200}
          >
            <Box>
              <IconButton aria-label="hide/show navbar" component="span" onClick={toggleNavbar}>
                <MenuIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Tooltip>
          <Logo onClick={() => history.push('/')} light />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small" style={{ marginLeft: 4 }}>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2} display="flex">
          <Notifications />
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
