import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider } from '@material-ui/core';
import {
  FilterChip,
  QuickSearch,
  VerticalBoxContainer,
  PageContentWrapper,
  useAPI,
} from '@shared/components';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import getChipValue from '../../../../utils/getChipValue';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';

import axios from '../../../../utils/axios';
import Table from './Table';

const exportFields = [
  {
    label: 'Datum i vrijeme prijave na aplikaciju',
    value: 'dateCreated',
    type: 'dateTime',
  },
];

const getLabelText = (value) => {
  const map = {
    dateCreated: 'Datum prijave na aplikaciju',
  };
  return map[value];
};

const Logs = ({ username }) => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);

  const [logs = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`scan-users/logs`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, username },
            orderBy: [...sorters, '"id" desc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  return (
    <VerticalBoxContainer>
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                type="textFieldDate"
                name="dateCreated"
                placeholder="Brza pretraga po datumu"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <ExcelExportButton
                totalCount={logs.totalCount}
                variant="outlined"
                fields={exportFields}
                sorters={[...sorters, '"id" desc']}
                filters={{ ...filters, username }}
                routeName="scan-users/logs"
                fileName={`${username}-logovi-prijava-na-aplikaciju`}
              />
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}

          <Table
            loading={loading}
            error={error}
            logs={logs.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={logs.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Logs;
