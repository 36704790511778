import * as yup from 'yup';

export const attributeSchema = yup.object().shape({
  code: yup
    .string()
    .max(100, 'Polje "Šifra" mora imati manje od 100 karaktera')
    .required('Polje "Šifra" je obavezno'),
  name: yup
    .string()
    .max(1000, 'Polje "Naziv" mora imati manje od 1000 karaktera')
    .required('Polje "Naziv" je obavezno'),
  attributeType: yup
    .number()
    .typeError('Tip atributa je obavezno polje')
    .required('Tip atributa je obavezno polje'),
});
