import styled from 'styled-components';

export const Wrapper = styled.div`
  :not(:hover) {
    white-space: nowrap;
  }
  :hover {
    white-space: normal;
    max-height: 300px;
  }
  max-width: 500px;
`;
