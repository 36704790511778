import React from 'react';
import { Snackbar, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import useGlobalAlertContext from 'hooks/useGlobalAlertContext';
import { Wrapper } from './style';

const GlobalAlerts = () => {
  const {
    errorAlertData,
    successAlertData,
    setSuccessAlertData,
    setErrorAlertData,
  } = useGlobalAlertContext();
  const handleSuccessAlertClose = () =>
    setSuccessAlertData((prevState) => ({ ...prevState, visible: false }));
  const handleErrorAlertClose = () =>
    setErrorAlertData((prevState) => ({ ...prevState, visible: false }));

  return (
    <>
      <Snackbar
        open={successAlertData.visible}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleSuccessAlertClose}
      >
        <Alert
          variant="filled"
          onClose={handleSuccessAlertClose}
          severity="success"
        >
          {successAlertData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorAlertData.visible}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleErrorAlertClose}
      >
        <Alert
          variant="filled"
          onClose={handleErrorAlertClose}
          severity="error"
        >
          <AlertTitle>Došlo je do greške</AlertTitle>
          <Wrapper>
            <Box
              component="div"
              textOverflow="ellipsis"
              overflow="hidden"
              typography="body1"
            >
              {errorAlertData.message}
            </Box>
          </Wrapper>
        </Alert>
      </Snackbar>
    </>
  );
};

export default GlobalAlerts;
