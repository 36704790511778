import React from 'react';
import {
  IconButton,
  SvgIcon,
  Grid,
  Box,
  Button,
  Snackbar,
  Divider,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '../Modal';

const ViewModal = ({
  object,
  visible,
  handleClose,
  handleAlertClose,
  requestError,
  errorAlertOpen,
  modalWidth,
  children,
  viewTitle,
  theme = {},
}) => {
  const InputFooter = () => (
    <Box
      style={{
        backgroundColor: theme.palette.background.dark,
      }}
    >
      <Divider />
      <Box padding={`${theme.spacing(5)}px`}>
        <Grid container spacing={5} justifyContent="flex-end">
          <Grid item>
            <Button color="primary" onClick={handleClose} size="large">
              Završi pregled
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={visible}
      // onBackdropClick={handleClose}
      // onEscapeKeyDown={handleClose}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          handleClose();
        }
      }}
      showHeader
      cardProps={modalWidth && { style: { width: modalWidth } }}
      cardHeaderProps={{
        title: viewTitle,
        action: (
          <IconButton onClick={handleClose}>
            <SvgIcon fontSize="small" style={{ color: '#fff' }}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        ),
      }}
    >
      <>
        <Snackbar
          open={errorAlertOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={handleAlertClose}
        >
          <Alert variant="filled" onClose={handleAlertClose} severity="error">
            {requestError?.data?.details?.reason ||
              `Došlo je do greške prilikom ${object ? 'uređivanja' : 'unosa'}`}
          </Alert>
        </Snackbar>
        {children}
        <InputFooter />
      </>
    </Modal>
  );
};

export default ViewModal;
