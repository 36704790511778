import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  IconButton,
  SvgIcon,
  TextField,
  CardHeader,
  Divider,
  Tooltip,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { InputModal, FilterChip } from '@shared/components';
import useTheme from '../../../hooks/useTheme';
import {
  handleFilterChange,
  handleChipDelete,
} from '../../../utils/filterHelpers';
import getChipValue from '../../../utils/getChipValue';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  setFilters,
  setPage,
  getLabelText,
}) => {
  const [filterValues, setFilterValues] = useState({});
  const theme = useTheme();

  useEffect(() => setFilterValues(currentValues), [currentValues]);

  const handleClose = () => setVisible(false);
  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  return (
    <InputModal
      theme={theme}
      filterTitle="Pretraga korisnika za skeniranje"
      visible={visible}
      onSubmit={onSubmit}
      handleClose={handleClose}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box pb={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(
                    chip,
                    filterValues,
                  )}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Card style={{ minWidth: '700px' }}>
          <CardHeader
            title="Podaci o korisniku za skeniranje"
            titleTypographyProps={{ variant: 'subtitle2' }}
            action={
              <Tooltip
                enterDelay={300}
                placement="top-end"
                arrow
                title="Ukloni filtere"
              >
                <IconButton
                  onClick={() => {
                    setFilterValues({});
                  }}
                >
                  <SvgIcon fontSize="small">
                    <ClearAllIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            }
          />
          <Divider />
          <Box
            padding={`${theme.spacing(5)}px`}
            width="100%"
            boxSizing="border-box"
          >
            <Grid
              spacing={5}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.username || ''}
                  size="small"
                  placeholder="Unesite korisničko ime za pretragu"
                  label="Korisničko ime"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      username: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.scanOptionName || ''}
                  size="small"
                  placeholder="Unesite naziv postavke skenera za pretragu"
                  label="Naziv postavke skenera"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      scanOptionName: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </InputModal>
  );
};

export default Filter;
