import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, keycloak, ...rest }) => {
  const isAuthorized = (rolesArray) => {
    if (keycloak && rolesArray) {
      return rolesArray.some((r) => {
        const realm = keycloak.tokenParsed.roles.indexOf(r) >= 0;
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized(roles) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
