import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton } from '@shared/components';
import { ErrorMessage } from './style';

const List = ({
  users,
  tableColumns,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount)
      setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const history = useHistory();

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableColumns.map((column) => (
            <TableCell
              width={column.width}
              key={column.value}
              align={column.align}
            >
              <Tooltip
                placement="bottom-start"
                enterDelay={300}
                title={`Sortiraj prema ${column.placeholder}`}
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() =>
                    handleSortChange(column.value, sorters, setSorters)
                  }
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={180} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>
                  {error?.data?.details?.reason || 'Došlo je do greške'}
                </ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !users ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : users?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan korisnik za skeniranje</Typography>
            </TableCell>
          </TableRow>
        ) : (
          users?.map((user) => (
            <TableRow
              style={{
                borderBottom: 0,
              }}
              key={user.username}
            >
              <TableCell width={100}>{user.username}</TableCell>
              <TableCell width={150}>{user.scanOptionName}</TableCell>
              <TableCell width={50} align="right">
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title="Detaljni pregled vrste dokumenta"
                >
                  <IconButton
                    onClick={() =>
                      history.push({
                        pathname: `/scan-users/${user.username}`,
                      })
                    }
                  >
                    <SvgIcon fontSize="small">
                      <ArrowForwardIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default List;
