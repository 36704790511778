import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';
import axios from '../../../../utils/axios';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import Table from './Table';
import Filter from './Filter';

const fields = [
  {
    label: 'Datum',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik',
    value: 'createdBy',
  },
  {
    label: 'Radnik',
    value: 'workerFullName',
  },
  {
    label: 'Organizacija',
    value: 'organisationName',
  },
  {
    label: 'Saglasan',
    value: 'consent',
  },
  {
    label: 'Komentar',
    value: 'comment',
  },
  {
    label: 'Komentar saglasnost',
    value: 'consentComment',
  },
];

const getLabelText = (value) => {
  const type = {
    dateCreated: 'Datum',
    organisation: 'Organizacija',
    createdBy: 'Korisnik',
    organisationName: 'Organizacija',
    workerFullName: 'Radnik',
    consent: 'Saglasan',
    comment: 'Komentar',
    consentComment: 'Komentar saglasnost',
  };
  return type[value];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  if (chip === 'consent')
    return filters[chip] === 'D' ? 'DA' : filters[chip] === 'N' ? 'NE' : 'U TOKU';
  return filters[chip];
};

const DocumentConsents = ({ documentId }) => {
  // const history = useHistory();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);

  const [consents = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`document-consents`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, documentId },
            orderBy: [...sorters, '"id" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
      />
      {/* <PageHeader
        title={`Dokument ${document?.id || ''}`}
        subtitle="Detalji dokumenta"
        sub
        showBackIcon
        history={history}
      /> */}
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="workerFullName"
                placeholder="Brza pretraga po radniku"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={consents.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" asc']}
                    filters={{ ...filters, documentId }}
                    routeName="document-consents"
                    variant="outlined"
                    fileName={`saglasnosti-na-dokumentu-${documentId}`}
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            consents={consents.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={consents.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentConsents;
