import styled from 'styled-components';
import { Drawer as MuiDrawer, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const MobileDrawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    width: ${({ theme }) => theme.navbarWidth}px;
    top: 0;
  }
`;

export const DesktopDrawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    width: ${({ theme }) => theme.navbarWidth}px;
    top: ${({ theme }) => theme.topbarHeight}px !important;
    height: calc(100% - 64px) !important;
  }
`;

export const HomepageLink = styled(Link)`
  text-align: center;
`;

export const IconButtonPositioned = styled(IconButton)`
  z-index: 51;
`;
