import * as yup from 'yup';

export const documentAttributeSchema = yup.object().shape({
  documentType: yup
    .number()
    .typeError('Tip dokumenta je obavezno polje')
    .required('Tip dokumenta je obavezno polje'),
  attribute: yup
    .number()
    .typeError('Atribut je obavezno polje')
    .required('Atribut je obavezno polje'),
  documentID: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
        .object()
        .shape({
          id: yup.number().positive(),
        })
        .required('Dokument je obavezno polje.')
        .typeError('Dokument je obavezno polje.')
      : yup
        .number()
        .positive()
        .transform((v, o) => (o === '' ? null : v))
        .required('Dokument je obavezno polje.');

  }),
  valueDisplayed: yup
    .string()
    .max(
      1000,
      'Polje "Vrijednost" mora imati manje od 1000 karaktera.',
    ),

});