import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
  DeleteModal,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import axios from '../../utils/axios';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';

const fields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Naziv',
    value: 'name',
  },
  {
    label: 'Opis',
    value: 'description',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    name: 'Naziv',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
    description: 'Opis',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  return filters[chip];
};

const Documents = () => {
  const theme = useTheme();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [triggerRerender, setRerender] = useState(false);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [groupAccModalVisible, setGroupAccModalVisible] = useState(false);
  const [selectedGroupAccordance, setSelectedGroupAcc] = useState();
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [groupAccordances = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`group-accordances`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters },
            orderBy: [...sorters, '"id" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <Modal
        groupAccordance={selectedGroupAccordance}
        setGroupAccordance={setSelectedGroupAcc}
        resetGroupAccordance={() => setSelectedGroupAcc(undefined)}
        visible={groupAccModalVisible}
        setVisible={setGroupAccModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <DeleteModal
        axios={axios}
        dataLength={groupAccordances.totalCount}
        page={page}
        setPage={setPage}
        path="group-accordance"
        theme={theme}
        visible={deleteModalVisible}
        setRerender={setRerender}
        title={`Obriši grupu saglasnosti ${selectedGroupAccordance?.id}`}
        promptText={`Da li ste sigurni da želite obrisati grupu saglasnosti "${selectedGroupAccordance?.name}"?`}
        buttonText="Obriši grupu saglasnosti"
        message="Uspješno izbrisana grupa saglasnosti"
        setVisible={setDeleteModalVisible}
        resetObject={() => setSelectedGroupAcc(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        objectId={selectedGroupAccordance?.id}
      />
      <PageHeader
        title="Grupe saglasnosti"
        extra={
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setGroupAccModalVisible(true)}
              >
                <Box fontSize="small">Nova grupa</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Šifarnik: Grupe saglasnosti
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="name"
                placeholder="Brza pretraga po nazivu"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={groupAccordances.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" DESC']}
                    filters={{ ...filters }}
                    variant="contained"
                    routeName="group-accordances"
                    fileName="grupe-saglasnosti"
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            groupAccordances={groupAccordances.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={groupAccordances.totalCount}
            sorters={sorters}
            setSorters={setSorters}
            history={history}
            setGroupAccModalVisible={setGroupAccModalVisible}
            selectedGroupAccordance={selectedGroupAccordance}
            setDeleteModalVisible={setDeleteModalVisible}
            setSelectedGroupAcc={setSelectedGroupAcc}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Documents;
