import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@material-ui/core';
import stringToHslColor from '../utils/stringToHslColor';

export const StyledAvatar = styled(MuiAvatar)`
  height: ${(props) => props?.height || '32px'};
  width: ${(props) => props?.width || '32px'};
  font-size: ${(props) => props?.font || '9pt'};
  margin: 0 ${({ theme }) => theme.spacing(3)}px 0 ${({ theme }) => theme.spacing(3)}px;
  background-color: ${(props) => stringToHslColor(props.name)};
`;
