import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  SvgIcon,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import UpdateIcon from '@material-ui/icons/Update';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import useTheme from '../../../../../hooks/useTheme';
import { getSortDirection, handleSortChange } from '../../../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const DocumentConsentsTable = ({
  consents,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  const theme = useTheme();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema datumu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema radniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('worker', sorters)}
                direction={getSortDirection('worker', sorters)}
                onClick={() => handleSortChange('worker', sorters, setSorters)}
              >
                Radnik
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema organizaciji" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('organisationName', sorters)}
                direction={getSortDirection('organisationName', sorters)}
                onClick={() => handleSortChange('organisationName', sorters, setSorters)}
              >
                Organizacija
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema saglasan" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('consent', sorters)}
                direction={getSortDirection('consent', sorters)}
                onClick={() => handleSortChange('consent', sorters, setSorters)}
              >
                Saglasan
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema komentaru" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('comment', sorters)}
                direction={getSortDirection('comment', sorters)}
                onClick={() => handleSortChange('comment', sorters, setSorters)}
              >
                Komentar
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema komentaru saglasnost"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('consentComment', sorters)}
                direction={getSortDirection('consentComment', sorters)}
                onClick={() => handleSortChange('consentComment', sorters, setSorters)}
              >
                Komentar saglasnost
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !consents ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : consents?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti jedna saglasnost na dokumentu</Typography>
            </TableCell>
          </TableRow>
        ) : (
          consents?.map((consent) => (
            <TableRow key={consent.id}>
              <TableCell width={50}>
                {dateFormatterDate(consent.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(consent.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={50}>{consent.createdBy}</TableCell>
              <TableCell width={50}> {consent.workerFullName}</TableCell>
              <TableCell width={50}> {consent.organisationName}</TableCell>
              <TableCell width={50}>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title={consent.consent === 'D' ? 'Da' : consent.consent === 'N' ? 'Ne' : 'U toku'}
                >
                  <SvgIcon style={{ verticalAlign: 'middle' }}>
                    {consent.consent === 'D' ? (
                      <DoneIcon style={{ color: theme.palette.success.main }} />
                    ) : consent.consent === 'N' ? (
                      <CloseIcon style={{ color: theme.palette.error.main }} />
                    ) : (
                      <UpdateIcon style={{ color: theme.palette.warning.main }} />
                    )}
                  </SvgIcon>
                </Tooltip>
              </TableCell>
              <TableCell width={150}> {consent.comment}</TableCell>
              <TableCell width={150}> {consent.consentComment}</TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentConsentsTable;
