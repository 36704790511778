import React, { useState } from 'react';
import { Box, IconButton, SvgIcon, Typography, Card } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, useAPI } from '@shared/components';
import axios from '../../../utils/axios';
import List from './List';
import useTheme from '../../../hooks/useTheme';

const ListModal = ({ documentId, visible, setVisible, title }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const [sorters, setSorters] = useState([]);

  const handleClose = () => {
    setVisible(false);
  };
  const [attributes = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`attribute-journal`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { documentId },
            orderBy: [...sorters, '"id" desc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, sorters, visible],
  );
  return (
    <Modal
      open={visible}
      // onBackdropClick={handleClose}
      // onEscapeKeyDown={handleClose}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          handleClose();
        }
      }}
      showHeader
      cardHeaderProps={{
        title: <Typography variant="h4">{title}</Typography>,
        action: (
          <IconButton onClick={handleClose} size="small">
            <SvgIcon fontSize="small" style={{ color: '#fff' }}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        ),
      }}
      footer={null}
    >
      <Box
        minHeight={100}
        minWidth="60vw"
        padding={`${theme.spacing(5)}px`}
        paddingBottom={`${theme.spacing(5)}px`}
      >
        <Card>
          <List
            loading={loading}
            error={error}
            attributes={attributes.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={attributes.totalCount}
            sorters={sorters}
            setSorters={setSorters}
          />
        </Card>
      </Box>
    </Modal>
  );
};

export default ListModal;
