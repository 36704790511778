import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Collapse, Button as Btn } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, ListItem, ItemLeaf, Title, useStyles } from './style';

const NavItem = ({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  pathname,
  onClick,
  ...rest
}) => {
  const [open, setOpen] = useState(openProp);
  const classes = useStyles();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem disableGutters key={title} {...rest}>
        <Button onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <Title>{title}</Title>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ItemLeaf disableGutters key={title} {...rest}>
      <Btn
        activeClassName={classes.active}
        className={`${classes.buttonLeaf} ${pathname?.startsWith(href) ? classes.active : ''}`}
        component={RouterLink}
        exact
        style={style}
        to={href}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <Title>{title}</Title>
      </Btn>
    </ItemLeaf>
  );
};

export default NavItem;
