import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';
import PageHeader from '../../../../components/PageHeader';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import axios from '../../../../utils/axios';
import List from '../../Table';
import Filter from '../../Filter';

const tableColumns = [
  {
    name: 'Pošiljatelj',
    value: 'createdBy',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'po korisniku',
  },
  {
    name: 'Naslov',
    value: 'subject',
    align: 'left',
    required: true,
    width: 300,
    placeholder: 'poruki',
  },
  {
    name: 'Poruka',
    value: 'message',
    align: 'left',
    required: true,
    width: 400,
    placeholder: 'poruki',
  },
  {
    name: 'Datum slanja',
    value: 'timeCreated',
    align: 'left',
    required: true,
    width: 150,
    placeholder: 'datumu sanja',
  },
];

const getLabelText = (value) => {
  const map = {
    createdBy: 'Pošiljatelj',
    subject: 'Naslov',
    message: 'Poruka',
    createdAt: 'Datum slanja',
  };
  return map[value];
};

const getChipValue = (chip, filters) => {
  if (chip === 'createdAt') {
    return filters[chip].replace('T', ' ');
  }
  return filters[chip];
};

const ReadMessages = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);

  const [messages = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`messages`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, screen: 'EKRAN3' },
            orderBy: [...sorters, '"read" desc, "createdAt" desc, "timeCreated" desc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
      />
      <PageHeader title="Pročitane poruke" />
      <PageContentWrapper>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="message"
                placeholder="Brza pretraga po poruci"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <List
            loading={loading}
            error={error}
            messages={messages.nodes}
            tableColumns={tableColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={messages.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            screen="EKRAN3"
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default ReadMessages;
