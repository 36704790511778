import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const TableSkeleton = ({
  size = 1,
  variant = 'rect',
  skeletonStyle,
  animation = 'wave',
  cellHeight = 70.33,
}) => {
  return [...Array(5).keys()].map((i) => {
    const lastElement = i === 4;
    return (
      <Box key={i}>
        <Skeleton
          variant={variant}
          style={skeletonStyle || { margin: '1vmin', padding: '0' }}
          animation={animation}
          height={cellHeight}
        />
        {!lastElement && <Divider variant="fullWidth" />}
      </Box>
    );
  });
};

export default TableSkeleton;
