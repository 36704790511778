import styled from 'styled-components';
import { Avatar as MuiAvatar, Menu as MuiMenu } from '@material-ui/core';

export const Avatar = styled(MuiAvatar)`
  height: 32px;
  width: 32px;
  margin-left: ${({ theme }) => theme.spacing(3)}px;
  margin-right: ${({ theme }) => theme.spacing(5)}px;
`;

export const Menu = styled(MuiMenu)`
  .MuiPopover-paper {
    width: 200px;
    left: unset !important;
    right: 24px !important;
  }
`;
