import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import { ErrorMessage } from './style';

const GroupAccordancesTable = ({
  groupAccordances,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedGroupAcc,
  // selectedGroupAccordance,
  setDeleteModalVisible,
  setGroupAccModalVisible,
}) => {
  const history = useHistory();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema opisu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('description', sorters)}
                direction={getSortDirection('description', sorters)}
                onClick={() => handleSortChange('description', sorters, setSorters)}
              >
                Opis
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !groupAccordances ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : groupAccordances?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti jedna grupa saglasnosti</Typography>
            </TableCell>
          </TableRow>
        ) : (
          groupAccordances?.map((groupAccordance) => (
            <TableRow key={groupAccordance.id}>
              <TableCell width={50}> {groupAccordance.id}</TableCell>
              <TableCell width={100}> {groupAccordance.name}</TableCell>
              <TableCell width={150}> {groupAccordance.description}</TableCell>
              <TableCell width={100}>
                {dateFormatterDate(groupAccordance.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(groupAccordance.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={150}> {groupAccordance.createdBy}</TableCell>
              <TableCell width={140} align="right">
                <>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Uredi grupu saglasnosti"
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedGroupAcc(groupAccordance);
                        setGroupAccModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Izbriši grupu saglasnosti"
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedGroupAcc(groupAccordance);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteOutlined />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Detaljni pregled grupe saglasnosti"
                  >
                    <span>
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `/group-accordances/${groupAccordance.id}`,
                            state: {
                              groupAccordance,
                            },
                          })
                        }
                      >
                        <SvgIcon fontSize="small">
                          <ArrowForwardIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default GroupAccordancesTable;
