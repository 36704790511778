import styled from 'styled-components';
import { Modal as MuiModal, CardHeader as MuiCardHeader } from '@material-ui/core';

export const ModalWrapper = styled(MuiModal)`
  display: flex;
  justify-content: center;
  align-items: center;

  & :focus {
    outline: none;
  }
`;


export const CardHeader = styled(MuiCardHeader)`
  background-color: ${({theme}) => theme.palette.primary.main};
  padding: ${({theme}) => theme.spacing(4)};
  color: ${({theme}) => theme.palette.background.default};
`;
