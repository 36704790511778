import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  SvgIcon,
  IconButton,
  TablePagination,
  Box,
  Typography,
  Collapse,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TableSkeleton } from '@shared/components';
import { ErrorMessage, ExpandableRowCell, ExpandedRow } from './style';
import useTheme from '../../../hooks/useTheme';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';

const mainTableColumns = [
  {
    name: 'Tip dokumenta',
    value: 'documentTypeName',
    align: 'left',
    width: '200',
    sortPlaceholder: 'tipu dokumenta',
  },
  {
    name: 'Redni broj',
    value: 'sequenceNumber',
    align: 'left',
    width: '120',
    sortPlaceholder: 'rednom broju',
  },
  {
    name: 'Inicijalna vrijednost',
    value: 'initialValue',
    align: 'left',
    width: '120',
    sortPlaceholder: 'inicijalnoj vrijednosti',
  },
  {
    name: 'Obavezan',
    value: 'requiredField',
    align: 'left',
    width: '60',
    sortPlaceholder: 'Obavezan',
  },
  {
    name: 'Spasi',
    value: 'saveField',
    align: 'left',
    width: '60',
    sortPlaceholder: 'Spasi',
  },
  {
    name: 'Važi',
    value: 'valid',
    align: 'left',
    width: '60',
    sortPlaceholder: 'Važi',
  },
];
const secondaryTableColumns = [
  // možda dodati copy dugme za sql upit
  { name: 'SQL upit za podatke', value: 'sqlDataCode', align: 'left', width: '355' },
  { name: 'Uputstvo (hint)', value: 'instructions', align: 'left', width: '106' },
];

const ExpandableRow = ({ docType }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <>
      <TableRow key={docType.id} style={{ borderBottom: 0 }}>
        <ExpandableRowCell width={45}>
          <IconButton size="small" onClick={() => setOpen((prevState) => !prevState)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ExpandableRowCell>
        <ExpandableRowCell width={200}>{docType.documentTypeName}</ExpandableRowCell>
        <ExpandableRowCell width={120}>{docType.sequenceNumber}</ExpandableRowCell>
        <ExpandableRowCell width={120}>{docType.initialValue}</ExpandableRowCell>
        <ExpandableRowCell width={60} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.requiredField === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={60} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.saveField === 1 ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
        <ExpandableRowCell width={60} align="left">
          <SvgIcon style={{ verticalAlign: 'middle' }}>
            {docType.valid === 'D' ? (
              <DoneIcon style={{ color: theme.palette.success.main }} />
            ) : (
              <CloseIcon style={{ color: theme.palette.error.main }} />
            )}
          </SvgIcon>
        </ExpandableRowCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <ExpandedRow>
              <Box display="flex">
                <Box flex={55} />
                {secondaryTableColumns.map((column) => (
                  <Box key={docType.code + column.value} flex={column.width}>
                    <Typography variant="h6" style={{ fontWeight: 600 }} display="inline">
                      {column.name}: <br />
                    </Typography>
                    <Typography variant="h6" display="inline">
                      {docType[column.value] || ''}
                    </Typography>
                  </Box>
                ))}
                <Box flex={120} />
              </Box>
            </ExpandedRow>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AttributeDocTypesList = ({
  attributeDocumentTypes,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  sorters,
  setSorters,
  loading,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          {mainTableColumns.map((column) => (
            <TableCell key={column.value} align={column.align} width={column.width}>
              <Tooltip
                enterDelay={300}
                title={`Sortiraj prema ${column.sortPlaceholder}`}
                placement="bottom-start"
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={20} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !attributeDocumentTypes ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ padding: '0' }} height={rowsPerPage}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : attributeDocumentTypes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan tip dokumenta sa datim atributom</Typography>
            </TableCell>
          </TableRow>
        ) : (
          attributeDocumentTypes?.map((docType) => (
            <ExpandableRow key={docType.id} docType={docType} />
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AttributeDocTypesList;
