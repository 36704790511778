import { useEffect, useState, useCallback } from 'react';

export default function useAPIAsync(api, path, conditions = {}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (path.includes('undefined')) return;
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const { data: responseData } = await api(path, {
        params: {
          ...conditions,
        },
      });
      setData(responseData);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [api, path]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // if (error) throw error;

  return { data, error, loading, fetchData, setData };
}
