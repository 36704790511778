import styled from 'styled-components';
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Box as MuiBox,
  Chip as MuiChip,
} from '@material-ui/core';

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardHeader = styled(MuiCardHeader)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Card = styled(MuiCard)`
  border-radius: 2px;
  height: 200px;
  padding: 0 ${({ theme }) => theme.spacing(4)}px;
`;

export const ContentBox = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px;
`;

export const Chip = styled(MuiChip)`
  margin: ${({ theme }) => theme.spacing(1)}px;
`;
