import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Spinner } from '@shared/components';
import Navbar from '../Navbar';
import Topbar from '../Topbar';
import useAuthContext from '../../hooks/useAuthContext';
import useNavbarContext from '../../hooks/useNavbarContext';
import useNotificationContext from '../../hooks/useNotificationContext';
import { LayoutWrapper, ContentWrapper, ContentContainer, Content, Message } from './style';

export const DashboardLayout = ({ homepageURL, keycloak, children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { navbarVisible, setNavbarVisible } = useNavbarContext();
  const { setUser, loading, error, setKeycloak } = useAuthContext();
  const { setRerender } = useNotificationContext();

  useEffect(() => {
    setKeycloak(keycloak);
    setUser({
      name: keycloak.tokenParsed.name || '',
      username: keycloak.tokenParsed.preferred_username || '',
      roles: keycloak.tokenParsed.roles || [],
    });
  }, []);

  const toggleNavbar = () => {
    setNavbarVisible((prev) => !prev);
  };

  return (
    <LayoutWrapper>
      {error ? (
        <Box display="flex" height="100vh" width="100%" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <ErrorOutlineRoundedIcon fontSize="large" />
            <Typography>
              <Message>Došlo je do greške. Molimo da ponovo učitate stranicu.</Message>
            </Typography>
          </Box>
        </Box>
      ) : loading ? (
        <Box display="flex" height="100vh" width="100%" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Spinner size={60} />
            <Typography>
              <Message>Učitavanje aplikacije. Molimo pričekajte.</Message>
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Topbar
            onMobileNavOpen={() => setMobileNavOpen(true)}
            navbarVisibility={navbarVisible}
            toggleNavbar={toggleNavbar}
          />
          <Navbar
            navbarVisibility={navbarVisible}
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            homepageURL={homepageURL}
            onClick={() => setRerender((prevState) => !prevState)}
          />
          <ContentWrapper navbarVisibility={navbarVisible}>
            <ContentContainer>
              <Content>{children}</Content>
            </ContentContainer>
          </ContentWrapper>
        </>
      )}
    </LayoutWrapper>
  );
};

export const VerticalBoxContainer = ({ children, ...props }) => {
  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      {...props}
    >
      {children}
    </Box>
  );
};

export * from './style';
