import React, { useState } from 'react';
import { Breadcrumbs, Typography, Link, AppBar, Button, Box } from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import mime from 'mime-types';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { VerticalBoxContainer, TabPanel, Spinner, useAPI } from '@shared/components';
import PageHeader from '../../components/PageHeader';
import { a11yProps } from '../../utils/tabFormatter';
import axios from '../../utils/axios';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import useTheme from '../../hooks/useTheme';
import DocumentHistory from './Tabs/History';
import Attributes from './Tabs/Attributes';
import Comments from './Tabs/Comments';
import DocumentAttachments from './Tabs/DocumentAttachments';
import DocumentConsents from './Tabs/DocumentConsents';
import { AntTabs, AntTab, PageContentWrapper } from './style';

const DocumentsOverviewDetails = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const [documentOverview, setDocumentOverview] = useState({});
  const history = useHistory();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendErrorAlertData } = useGlobalAlertContext();
  const { id } = useParams();

  const [, , loading] = useAPI(
    () =>
      axios
        .get(`documents/${id}`, {
          params: {
            screen: 'EKRAN3',
          },
        })
        .then(({ data }) => {
          setDocumentOverview(data);
        })
        .catch((error) => {
          sendErrorAlertData(
            `${
              error?.response?.data?.details?.reason ||
              error?.response?.data?.error?.message ||
              `Došlo je do greške prilikom preuzimanja informacija o dokumentu`
            }`,
          );
          history.replace('/overview');
        }),
    [id],
  );

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const getDocumentFile = async (fileId, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${fileId}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data?.details?.reason ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
    }
  };

  const tabs = [
    {
      name: 'Atributi',
      component: <Attributes documentId={id} documentLoading={loading} />,
    },
    {
      name: 'Saglasnosti',
      component: <DocumentConsents documentId={id} />,
    },
    {
      name: 'Prilozi',
      component: <DocumentAttachments documentId={id} />,
    },
    {
      name: 'Komentari',
      component: <Comments documentId={id} />,
    },
    {
      name: 'Historija',
      component: <DocumentHistory documentId={id} />,
    },
  ];

  return (
    <VerticalBoxContainer>
      <PageHeader
        showBackIcon
        title={`Detalji: ${tabs[currentTab].name} dokumenta ${id}`}
        extra={
          <Button
            variant="contained"
            color="primary"
            startIcon={
              downloadLoading || loading ? <Spinner size={15} /> : <InsertDriveFileOutlinedIcon />
            }
            disabled={downloadLoading || loading}
            onClick={() => {
              getDocumentFile(documentOverview.id, documentOverview.documentExtension);
            }}
          >
            <Box fontSize="small">Prikaži dokument</Box>
          </Button>
        }
        history={history}
      />
      <PageContentWrapper style={{ padding: `80px 0 0 0` }}>
        <AppBar
          position="static"
          style={{ background: `${theme.palette.background.default}` }}
          elevation={0}
        >
          <AntTabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <AntTab key={tab.name} label={tab.name} {...a11yProps(index)} />
            ))}
          </AntTabs>
        </AppBar>
        <Breadcrumbs
          style={{
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
          }}
        >
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/overview" component={RouterLink}>
            Pregled dokumenata
          </Link>
          <Typography variant="body1" color="textPrimary">
            {tabs[currentTab].extendedName ? tabs[currentTab].extendedName : tabs[currentTab].name}
          </Typography>
        </Breadcrumbs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.name}
            value={currentTab}
            index={index}
            dir={theme.direction}
            theme={theme}
          >
            {tab.component}
          </TabPanel>
        ))}
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentsOverviewDetails;
