import * as yup from 'yup';

export const accordanceDocumentSchema = yup.object().shape({
  documentName: yup.string().max(2000, 'Naziv dokumenta ima maksimalno 2000 karaktera').nullable(),
  documentType: yup
    .number()
    .typeError('Tip dokumenta je obavezno polje')
    .required('Tip dokumenta je obavezno polje'),
  accordancePercentage: yup
    .number()
    .typeError('Procenat saglasnosti mora biti broj')
    .min(1, 'Procenat saglasnosti mora biti veći od 1%')
    .max(100, 'Procenat saglasnosti mora biti manji od 100%'),
});
