import React from 'react';
import Box from '@material-ui/core/Box';
import {
  Main,
  Wrapper,
  Content,
  Title,
  Subtitle,
  Paragraph,
  RotateImage,
  Button,
  LinkWrapper,
} from './style';

const Unauthorized = ({ homePageURL }) => (
  <Main>
    <Wrapper>
      <Content>
        <Title>
          4
          <Box component="span">
            <RotateImage src={`${process.env.PUBLIC_URL}/logo.png`} alt="Potrebna autorizacija" />
          </Box>
          1
        </Title>
        <Subtitle>Error: 401 Unauthorized</Subtitle>
        <Paragraph>Ne možete pristupiti traženoj stranici</Paragraph>
        <Box m={10}>
          <LinkWrapper href={homePageURL}>
            <Button variant="contained" color="primary">
              Nazad na početnu
            </Button>
          </LinkWrapper>
        </Box>
      </Content>
    </Wrapper>
  </Main>
);

export default Unauthorized;
