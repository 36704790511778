import styled from 'styled-components';
import { DialogTitle as MuiDialogTitle } from '@material-ui/core';

export const DialogTitle = styled(MuiDialogTitle)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.default};

  & .MuiSvgIcon-root {
    fill: #fff;
  }
`;
