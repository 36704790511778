import { colors, createTheme, alpha } from '@material-ui/core';
import 'fontsource-roboto';
import { softShadows } from './shadows';

const defaultTheme = createTheme();

const theme = createTheme({
  overrides: {
    MuiCardHeader: {
      action: {
        marginTop: '0px',
        marginRight: '0px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        lineHeight: '1',
        textTransform: 'none',
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
        transition: defaultTheme.transitions.create(
          ['background-color', 'box-shadow', 'border', 'color'],
          {
            duration: defaultTheme.transitions.duration.short,
          },
        ),
      },
      text: {
        padding: '6px 14px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      startIcon: {
        marginRight: '6px',
      },
    },
    MuiTableSortLabel: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.8rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.45em',
      },
      // icon: { position: 'relative', },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '0.95rem',
        marginBottom: '0.2em',
        fontWeight: 500,
        color: colors.blueGrey[500],
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(136, 140, 142)',
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
      grey: alpha('#f4f6f8', 0.5),
      cardHeader: alpha('#003459', 0.1),
    },
    primary: {
      main: '#003459',
    },
    secondary: {
      main: '#00a8e8',
      contrastText: colors.common.white,
    },
    success: {
      main: '#439b73',
    },
    error: {
      main: '#c02428',
    },
    warning: {
      main: '#ff8f2a',
    },
    info: {
      main: '#4b6cb7',
    },
    chart_colors: [
      '#7BC75B',
      '#14b3f2',
      '#FA5F69',
      '#D961D9',
      '#CC60B5',
      '#FF6C40',
      '#F5BFE8',
      '#37963D',
      '#2378C7',
      '#E95D68',
      '#A94DA6',
      '#94408B',
      '#D16036',
      '#C798CC',
      '#3D954F',
      '#2C80D3',
      '#E6757E',
      '#9C478E',
      '#843B77',
      '#B65332',
      '#AE6EAB',
      '#5E5E5E',
    ],
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    notification: {
      main: '#f2bc3f',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px',
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px',
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px',
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px',
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px',
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px',
    },
    subtitle1: {
      color: '#FFFFFF',
      fontWeight: 50,
      fontSize: 22,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: 17,
    },
    overline: {
      fontWeight: 500,
    },
  },
  shadows: softShadows,
  spacing: [2, 4, 6, 8, 12, 16, 24, 36, 48, 64, 72],
  navbarWidth: 275,
  topbarHeight: 64,
  pageHeaderHeight: 80,
});

export default theme;
