import styled from 'styled-components';
import { TreeItem as MuiTreeItem } from '@material-ui/lab';


export const TreeItem = styled(MuiTreeItem)(({theme}) => ({
      ".MuiTreeItem-iconContainer svg":  {
        fontSize: "23px",
        marginRight: theme.spacing(1),
        color: 'var(--tree-view-color)',
      },
      // backgroundColor: 'var(--tree-view-bg-color)',
      ".MuiTreeItem-group": {
        marginLeft: 7,
        paddingLeft: 18,
        // backgroundColor: 'var(--tree-view-bg-color)',
        borderLeft: `1px dashed ${theme.palette.text.primary}`,
      },
  }));
