import * as yup from 'yup';

export const groupAccordanceDocumentSchema = yup.object().shape({
  groupAccordance: yup
    .number()
    .typeError('Potrebno je izabrati grupu saglasnosti')
    .positive('Potrebno je izabrati grupu saglasnosti')
    .required('Potrebno je izabrati grupu saglasnosti'),
    groupComment: yup.string().max(4000, 'Polje "Opis" mora imati manje od 4000 karaktera'),
});
