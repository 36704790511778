import React from 'react';
import { StyledLogo } from './style';

const Logo = ({ onClick, light }) => {
  return (
    <StyledLogo onClick={onClick} light={light}>
      DMS
    </StyledLogo>
  );
};

export default Logo;
